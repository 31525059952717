import { useEffect } from "react";
import { useMainLayoutStore } from "../../Stores/mainLayout.store";

const InitMainLayoutLogic = () => {
    const { footerHeight, navigationHeight, contentHeight, setContentHeight } = useMainLayoutStore();

    useEffect(() => {
        onResize()
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [footerHeight, navigationHeight])

        // + 15 because navigation overlay contain -15 margin top ... (navigation shadow)
    const onResize = () => {
        let ch = window.innerHeight - footerHeight - navigationHeight + 15
        setContentHeight(ch)
    }

    return {
        contentHeight
    }
}

export default InitMainLayoutLogic;
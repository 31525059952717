import IProduct from "../../../../interfaces/product";
import styles from './ProductOverview.module.css'
import noImage from '../../../../assets/images/wave3/noimage.svg'
import UtilsHelper from "../../../../helpers/utils.helper";


const ProductOverviewComponent: React.FC<IProduct> = (params: IProduct) => {
    return (
        <div className={styles.container}>
            <div className={styles.product}>
                {params.fileInfos !== undefined && <img alt="" className={styles.image} src={params.fileInfos[0].url ? UtilsHelper.createFileUrl(params.fileInfos[0].url) : noImage} />}
                {params.fileInfos === undefined && <img alt="" className={styles.image} src={noImage} />}
                <div className={styles.name}>{params.name}</div>
            </div>
        </div>
    )
}

export default ProductOverviewComponent;
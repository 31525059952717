import { t } from "i18next";
import { useEffect, useState } from "react";
import { DeflectionLimit } from "../../../../enums/deflectionLimit";
import roomHelper from "../../../helpers/roomHelper";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IRoomOverview from "../../../Interfaces/roomOverview";
import CRStep4VisualizationComponent from "../../RoomForm/Step4/Visualization/CRStep4Visualization.component";
import styles from './DeflectionLimitOverview.module.css'

const DeflectionLimitOverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    const [room, setRoom] = useState<IRoomFormData>(null!);
    useEffect(() => {
        let r = { ...params as unknown as IRoomFormData };
        r.deflectionLimitString = roomHelper.getDeflectionLimiitString(r);
        setRoom(r);

        // eslint-disable-next-line 
    }, [])
    
    return (
        <>
            {room !== null &&
                <div>
                    <input onChange={() => { }} name={`deflectionLimitOverviewComponent${params.id}`} checked type="radio" />
                    <label htmlFor="DeflectionLimitOverviewComponent">
                        {roomHelper.getDeflectionLimiitString(room) === DeflectionLimit.l300 &&
                            <span>{t("l/300")}</span>}
                        {roomHelper.getDeflectionLimiitString(room) === DeflectionLimit.l500 &&
                            <span>{t("l/500")}</span>}
                        {roomHelper.getDeflectionLimiitString(room) === DeflectionLimit.OTHER &&
                            <span>{t("Other")}</span>}
                    </label>


                    <div className={styles.overview__container}>
                        <CRStep4VisualizationComponent  {...room} />
                    </div>
                </div>}
        </>
    )
}

export default DeflectionLimitOverviewComponent;
import InitRegisteredUserComponentLogic from "./RegisteredUsers.logic";
import { t } from "i18next";
import styles from "./RegisteredUsers.module.css";
import LoadingComponent from "../Loading/Loading.component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RegisteredUsersComponent: React.FC = () => {
  const {
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    fetchUsers,
    isLoading,
    users,
  } = InitRegisteredUserComponentLogic();
  return (
    <div>
      <div>
        <h2 className={styles.title}>{t("Registered Users")}</h2>
      </div>
      <div>
        <h3 className={styles.sec_title}>
          {t("Please select a start and end date for the search!")}
        </h3>
      </div>
      <div className={styles.datesContainer}>
        <div className={styles.dates}>
          <label className={styles.label}>{t("Start Date:")}</label>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            dateFormat="dd/MM/yyyy"
            className={styles.input}
            placeholderText="dd/mm/yyyy"
            maxDate={endDate || new Date()}
          />
        </div>
        <div className={styles.dates}>
          <label className={styles.label}>{t("End Date:")}</label>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            dateFormat="dd/MM/yyyy"
            className={styles.input}
            placeholderText="dd/mm/yyyy"
            maxDate={new Date()}
          />
        </div>
      </div>
      <div>
        <button
          disabled={!startDate || !endDate}
          className={styles.button}
          onClick={fetchUsers}
        >
          {t("Search users")}
        </button>
      </div>
      <div>
        {isLoading && (
          <div>
            <LoadingComponent />
          </div>
        )}

        {!isLoading && users && users.length > 0 && (
          <div className={styles.table_div}>
            <h3 className={styles.searchResultsHeader}>
              {t("Search Results")}
            </h3>
            <h4 className={styles.subtotal}>
              {t("Total number of registered users within time period:")}{" "}
              {users.length}
            </h4>
            <table className={styles.user_table}>
              <thead>
                <tr>
                  <th>{t("Index")}</th>
                  <th>{t("First name")}</th>
                  <th>{t("Last name")}</th>
                  <th>{t("Gender Title")}</th>
                  <th>{t("Email address")}</th>
                  <th>{t("Phone number")}</th>
                  <th>{t("Address")}</th>
                  <th>{t("Date Created")}</th>
                  <th>{t("Company")}</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => {
                  const createdDate = new Date(user.created);
                  const europeanDate = createdDate.toLocaleDateString("en-GB"); // dd/mm/yyyy
                  const timestamp = createdDate.toTimeString().split(" ")[0]; // HH:mm:ss

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.genderTitle}</td>
                      <td>{user.username}</td>
                      <td>{user.phone}</td>
                      <td>{user.address}</td>
                      <td>{`${europeanDate} ${timestamp}`}</td>
                      <td>{user.company}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegisteredUsersComponent;

import { t } from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import UtilsHelper from '../../../helpers/utils.helper';
import ITable from '../../../interfaces/table';
import styles from './Table.module.css';
const Table: React.FunctionComponent<ITable> = props => {

    return (
        <>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {props.headers.map(column => {
                            return <th className={styles.th} key={column}>{column}</th>
                        })}
                        {props.new && <th className={`${styles.actions} ${styles.th}`}><Link to={props.new}>
                            <button className={`button-secondary`}>{t("Add")}</button>
                        </Link></th>}
                        {props.new === undefined && <th className={`${styles.actions} ${styles.th}`}></th>}
                    </tr>
                </thead>
                <tbody>
                    {props.rows.map(row => {
                        return (
                            <tr key={row.id}>
                                {props.columns.map(column => {
                                    if (column.includes(":")) {
                                        let data = column.split(":");
                                        if (data[1] === "img") {
                                            return <td className={styles.td} key={data[0]}>{row[data[0]] && <img className={styles.image} src={UtilsHelper.createFileUrl(row[data[0]])} alt=""></img>}</td>
                                        }
                                        else if (data[1] === "a") {
                                            return <td className={styles.td} key={data[0]}>{row[data[0]] && <a target="_blank" rel="noopener noreferrer" href={row[data[0]]}>{t("Link")}</a>}</td>
                                        }
                                    }
                                    return <td className={styles.td} key={column}>{column.includes(".") ? (row[column.split(".")[0]] ? (typeof (row[column.split(".")[0]][column.split(".")[1]]) == "boolean" ? (row[column.split(".")[0]][column.split(".")[1]] ? t("Yes") : t("No")) : (row[column.split(".")[0]][column.split(".")[1]])) : "") : (typeof (row[column]) == "boolean") ? (row[column] ? t("Yes") : t("No")) : row[column]}</td>
                                })}
                                {(props.edit || props.delete || props.resolve || props.overview) && <td className={`${styles.actions} ${styles.td}`}>
                                    &nbsp;
                                    {props.edit && <Link to={`${props.edit}${props.editColumn ? row[props.editColumn] : row.id}`}>
                                        <button className={`button-secondary`}>{t("Edit")}</button>
                                    </Link>}
                                    &nbsp;
                                    {props.delete && <button onClick={() => { if (props.delete !== undefined) { props.delete(row.id) } }} className={`button-secondary`}>{t("Delete")}</button>}
                                    &nbsp;
                                    {props.overview && <button onClick={() => { if (props.overview !== undefined) { props.overview(row.id, row.token) } }} className={`button-secondary`}>{t("Overview")}</button>}
                                    &nbsp;
                                    {props.resolve && <button onClick={() => { if (props.resolve !== undefined) { props.resolve(row.id) } }} className={styles.button__resolve}>{t("Resolve")}</button>}
                                </td>}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

export default Table;
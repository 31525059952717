import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./PasswordChecker.module.css";
import i18next, { t } from "i18next";

const InitPasswordCheckerLogic = (password: string) => {
  const uppercaseRegex = new RegExp("(?=.*[A-Z])");
  const lowercaseRegex = new RegExp("(?=.*[a-z])");
  const digitRegex = new RegExp("(?=.*[0-9])");
  const specialRegex = new RegExp("(?=.*[@$!%*#?&])");

  const [className, setClassName] = useState(styles.strength__bar);
  const [passed, setPassed] = useState(0);
  const [passwordStatus, setPasswordStatus] = useState("");
  const [passwordClass, setPasswordClass] = useState(
    styles.password__status__grey
  );
  const [errorArray, setErrorArray] = React.useState<string[]>([]);
  const [showInfo, setShowInfo] = useState(true);

  useEffect(() => {
    if (password.length < 1) {
      setClassName(styles.strength__bar);
      setErrorArray([
        t("Your password is missing:"),
        t("at least 8 characters,"),
        t("at least 1 upper case letter,"),
        t("at least 1 lower case letter,"),
        t("at least 1 digit,"),
        t("at least 1 special character,").replace(/.$/, "."),
      ]);
      setPasswordStatus(t("Very weak"));
      setPasswordClass(styles.password__status__grey);
      setShowInfo(true);
      return;
    }

    if (password.length < 8) {
      setClassName(styles.strength__bar__red);
      setErrorArray([t("Password must have at least 8 characters.")]);
      setPassed(1);
      setPasswordStatus(t("Too short"));
      setPasswordClass(styles.password__status__red);
      setShowInfo(true);
      return;
    }

    let cnt = 0;
    let array = [t("Your password is missing:")];
    if (uppercaseRegex.test(password)) {
      cnt++;
    } else {
      array.push(t("at least 1 upper case letter,"));
    }
    if (lowercaseRegex.test(password)) {
      cnt++;
    } else {
      array.push(t("at least 1 lower case letter,"));
    }
    if (digitRegex.test(password)) {
      cnt++;
    } else {
      array.push(t("at least 1 digit,"));
    }
    if (specialRegex.test(password)) {
      cnt++;
    } else {
      array.push(t("at least 1 special character,"));
    }
    array[array.length-1] = array[array.length-1].replace(/.$/, ".")
    setErrorArray(array);

    switch (cnt) {
      case 1:
        setClassName(styles.strength__bar__red);
        setPasswordStatus(t("Not good"));
        setPasswordClass(styles.password__status__red);
        setShowInfo(true);
        break;
      case 2:
        setClassName(styles.strength__bar__yellow);
        setPasswordStatus(t("So-so"));
        setPasswordClass(styles.password__status__yellow);
        setShowInfo(true);
        break;
      case 3:
        setClassName(styles.strength__bar__orange);
        setPasswordStatus(t("So-so"));
        setPasswordClass(styles.password__status__orange);
        setShowInfo(true);
        break;
      case 4:
        setShowInfo(false);
        if (password.length >= 10) {
          setClassName(styles.strength__bar__green);
          setPasswordStatus(t("Great!"));
          setPasswordClass(styles.password__status__green);
          break;
        } else {
          setClassName(styles.strength__bar__light__green);
          setPasswordStatus(t("Good"));
          setPasswordClass(styles.password__status__light__green);
          break;
        }
    }

    setPassed(cnt);
    // eslint-disable-next-line 
  }, [password, i18next.language]);

  const getErrorMessage = () => {
    let sentence = ""
    for(var i=0; i<errorArray.length; i++){
      sentence += errorArray[i]
      sentence += " " 
    }

    return sentence
  }

  return {
    className,
    passed,
    passwordStatus,
    passwordClass,
    errorArray,
    showInfo,
    getErrorMessage
  };
  
};

export default InitPasswordCheckerLogic;

import {
  faTrashRestore,
  faChevronRight,
  faEdit,
  faEllipsis,
  faEye,
  faTrashAlt,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { Link } from "react-router-dom";
import IProjectItemComponent from "../../../Interfaces/projectItemComponent";
import ModalComponent from "../../Modal/Modal.component";

import InitProjectItemLogic from "./ProjectItem.logic";
import styles from "./ProjectItem.module.css";
import { RoomStatus } from "../../../../enums/roomStatus";

const ProjectItemComponent: React.FC<IProjectItemComponent> = (
  props: IProjectItemComponent
) => {
  const {
    getStatusClass,
    onClickRecoverProject,
    removeFromCart,
    getItemUrl,
    setShowDeleteModal,
    showDeleteModal,
    deleteModal,
    goToCheckout,
  } = InitProjectItemLogic(props);


  return (
    <>
      <div className={styles.card__container}>
        <div className={styles.card__dropdown}>
          <button className={styles.card__dropdown__button}>
            <FontAwesomeIcon icon={faEllipsis} />
          </button>
          <div className={styles.card__dropdown__content}>
            {props.item.status !== "DRAFT" && (
              <Link to={`/room/${props.item.id}`}>
                <button className={styles.card__dropdown__content__option}>
                  <FontAwesomeIcon
                    className={styles.card__dropdown__content__option__icon}
                    icon={faEye}
                  />{" "}
                  {t("Overview")}
                </button>
              </Link>
            )}
            <Link
              to={
                props.item.nextStep < 8
                  ? `/room?step=${props.item.nextStep}&roomId=${props.item.id}`
                  : `/room?step=1&roomId=${props.item.id}`
              }
            >
              <button
                disabled={props.item.status === "ORDERED"}
                className={styles.card__dropdown__content__option}
              >
                <FontAwesomeIcon
                  className={styles.card__dropdown__content__option__icon}
                  icon={faEdit}
                />{" "}
                {t("Edit")}
              </button>
            </Link>
            {props.item.status !== RoomStatus.INTRASH && (
              <button
                disabled={props.item.status === "ORDERED"}
                onClick={() => {
                  setShowDeleteModal(true);
                }}
                className={styles.card__dropdown__content__option}
              >
                <FontAwesomeIcon
                  className={styles.card__dropdown__content__option__icon}
                  icon={faTrashAlt}
                />{" "}
                {t("Delete")}
              </button>
            )}
          </div>
        </div>

        <Link className={styles.card__link} to={getItemUrl()}>
          <div className={styles.card}>
            <div className={styles.card__header}>
              <span className={styles.card__title}> {props.item.name}</span>
              <div className={styles.card__header__dropdown}>
                <div className={`${styles.card__status} ${getStatusClass()}`}>
                  {props.item.status === RoomStatus.DRAFT && <>{t("DRAFT")}</>}
                  {props.item.status === RoomStatus.INTRASH && <>{t("IN TRASH")}</>}
                  {(props.item.status === RoomStatus.INCART ||
                    props.item.status === RoomStatus.READY) && (
                    <>{t("READY")}</>
                  )}
                  {props.item.status === RoomStatus.ORDERED && (
                    <>{t("SUBMITTED")}</>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.card__code}>{props.item.code}</div>
            {props.item.selectedProducts && (
            <div className={styles.card__products}>
              <div>
                {props.item.selectedProducts.profile?.fileInfos?.[0]?.url && (
                  <img
                    src={props.item.selectedProducts.profile.fileInfos[0].url}
                    alt=""
                  />
                )}
                {props.item.selectedProducts.profile?.name}
              </div>
              {props.item.selectedProducts.wallProfile && (
                <div>
                  {props.item.selectedProducts.wallProfile?.fileInfos?.[0]?.url && (
                    <img
                      src={props.item.selectedProducts.wallProfile.fileInfos[0].url}
                      alt=""
                    />
                  )}
                  {props.item.selectedProducts.wallProfile?.name}
                </div>
              )}
              {props.item.selectedProducts.connectionAngle && (
                <div>
                  {props.item.selectedProducts.connectionAngle?.fileInfos?.[0]?.url && (
                    <img
                      src={
                        props.item.selectedProducts.connectionAngle.fileInfos[0].url
                      }
                      alt=""
                    />
                  )}
                  {props.item.selectedProducts.connectionAngle?.name}
                </div>
              )}
            </div>
          )}

            {props.item.status === RoomStatus.DRAFT && (
              <Link to={getItemUrl()}>
                <button
                  className={`${styles.card__button} ${styles.card__button__red}`}
                >
                  {t("Continue with project")}
                  <FontAwesomeIcon
                    className={styles.card__button__icon}
                    icon={faChevronRight}
                  />
                </button>
              </Link>
            )}

            {/* {(props.item.status === "DRAFT" && props.item.nextStep >= 8) &&
                <Link to={`/room/${props.item.id}`}>
                    <button className={`${styles.card__button} ${styles.card__button__red}`}>
                        {t("Open overview")}<FontAwesomeIcon className={styles.card__button__icon} icon={faChevronRight} />
                    </button>
                </Link>
            } */}

            {/* {props.item.status === RoomStatus.READY && (
              <button
                className={`${styles.card__button} ${styles.card__button__green}`}
                onClick={recoverProject}
              >
                {t("Add to cart")}
                <FontAwesomeIcon
                  className={styles.card__button__icon}
                  icon={faTrashRestore}
                />
              </button>
            )} */}

            {props.item.status === RoomStatus.INTRASH && (
              <div className={styles.card__cart__trash__button__container}>
                <button
                  className={`${styles.card__cart__trash__button} ${styles.card__button__green}`}
                  onClick={onClickRecoverProject}
                >
                  {t("Recover project")}
                  <FontAwesomeIcon
                    className={styles.card__button__icon}
                    icon={faTrashRestore}
                  />
                </button>
                <button
                  className={`${styles.card__cart__trash__button} ${styles.card__button__red}`}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    setShowDeleteModal(true);
                  }}
                >
                  {t("Delete permanently")}
                  <FontAwesomeIcon
                    className={styles.card__button__icon}
                    icon={faTrash}
                  />
                </button>
              </div>
            )}

            {props.item.status === RoomStatus.INCART && (
              <div className={styles.card__cart__trash__button__container}>
                <button
                  className={`${styles.card__cart__trash__button} ${styles.card__button__green}`}
                  onClick={goToCheckout}
                >
                  {t("Project proposals")}
                  <FontAwesomeIcon
                    className={styles.card__button__icon}
                    icon={faCheck}
                  />
                </button>

                <button
                  className={`${styles.card__cart__trash__button} ${styles.card__button__red}`}
                  onClick={removeFromCart}
                >
                  {t("Remove from cart")}{" "}
                  <FontAwesomeIcon
                    className={styles.card__button__icon}
                    icon={faTrash}
                  />
                </button>
              </div>
            )}

            {props.item.status === RoomStatus.ORDERED && (
              <Link to={`/room/${props.item.id}`}>
                <button
                  className={`${styles.card__button} ${styles.card__button__red}`}
                >
                  {t("Open overview")}
                  <FontAwesomeIcon
                    className={styles.card__button__icon}
                    icon={faChevronRight}
                  />
                </button>
              </Link>
            )}
          </div>
        </Link>
      </div>

      {showDeleteModal && <ModalComponent {...deleteModal}></ModalComponent>}
    </>
  );
};

export default ProjectItemComponent;

import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";
import leftIcon from "../../../../assets/images/wave3/arrow-left.svg";
import rightIcon from "../../../../assets/images/wave3/arrow-right.svg";
import submitIcon from "../../../../assets/images/wave3/submit-icon.svg";
import IStepControls from "../../../Interfaces/StepControls";
import IStepIndicator from "../../../Interfaces/StepIndicator";
import styles from "./StepControls.module.css";
import ButtonShared from "../../../SharedComponents/ButtonShared/ButtonShared";
import InitStepControlsLogic from "./StepControls.logic";

const StepControlsComponent: React.FunctionComponent<any> = (
  params: IStepControls
) => {
  const {
    goTo,
    getIndicatorClass,
    backTranslationKey,
    backUrl,
    backButtonClick,
    navigate,
    isInEditMode,
  } = InitStepControlsLogic(params);

  return (
    <>
      <div className={styles.controls__container}>
        <div className={styles.controls}>
          <div className={styles.controls__item}>
            {!params.hideBack && params.hidePrevious && (
              <Link
                className={styles.controls__left__link}
                to={backUrl ?? "/dashboard"}
              >
                <ButtonShared
                  onClick={backButtonClick}
                  img_src={leftIcon}
                  img_side="left"
                  type="button"
                  text={t(backTranslationKey ?? "Back to dashboard")}
                  noText={params.currentStep === 1}
                />
              </Link>
            )}

            {!params.hidePrevious && (
              <ButtonShared
                disabled={params.disablePrevious}
                type="button"
                onClick={() => {
                  params.previous();
                }}
                img_side="left"
                img_src={leftIcon}
                text={t("Previous")}
                noText={true}
              />
            )}
          </div>
          <div
            className={styles.step__indicators__container}
            style={{ position: "sticky" }}
          >
            <div className={styles.step__indicators}>
              {params.externalSteps?.map(
                (item: IStepIndicator, index: number) => {
                  return item.before ? (
                    <div
                      onClick={() => {
                        item.function();
                      }}
                      key={index}
                      className={`${styles.step__indicator}`}
                    ></div>
                  ) : (
                    ""
                  );
                }
              )}

              {params.components.map((item, index) => (
                <div
                  onClick={() => {
                    goTo(index);
                  }}
                  key={index}
                  className={getIndicatorClass(index)}
                ></div>
              ))}

              {params.externalSteps?.map(
                (item: IStepIndicator, index: number) => {
                  return (
                    !item.before && (
                      <div
                        onClick={() => {
                          item.function();
                        }}
                        key={index}
                        className={
                          params.allowSkipTo > params.components.length ||
                          (params.currentStep === params.components.length &&
                            !params.disableNext &&
                            index === 0)
                            ? styles.step__indicator
                            : styles.step__indicator__disabled
                        }
                      ></div>
                    )
                  );
                }
              )}
            </div>
            <div>
              {params.externalSteps
                ? params.externalSteps.filter((e) => e.before).length +
                  params.currentStep
                : params.currentStep}
              /
              {params.components.length +
                (params.externalSteps ? params.externalSteps.length : 0)}
            </div>
          </div>
          <div>
            {!params.hideNext && params.hideSubmit && (
              <ButtonShared
                type="button"
                disabled={params.disableNext}
                onClick={() => {
                  params.next();
                }}
                text={t("Next")}
                img_src={rightIcon}
                img_side="right"
                noText={true}
              />
            )}
            {(!params.hideSubmit || params.forceSubmit) && (
              <ButtonShared
                disabled={params.disableNext}
                onClick={() => {
                  params.submit(
                    params.previous,
                    params.currentStep,
                    params.next
                  );
                }}
                type="button"
                text={
                  params.submitText !== undefined
                    ? params.submitText
                    : t("Submit")
                }
                img_src={submitIcon}
                img_size="large"
                noText={true}
              />
            )}
          </div>
        </div>
        {!params.hideBack && !params.hidePrevious && 
          <div className={styles.controls__back__link__container}>
            <div
              className={styles.controls__left__link}
              style={{ display: "inline-block" }}
            >
              <ButtonShared
                type="button"
                text={t(backTranslationKey ?? "Back to dashboard")}
                img_side={"left"}
                img_src={leftIcon}
                onClick={backButtonClick}
              />
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default StepControlsComponent;

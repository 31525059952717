import { t } from "i18next";
import { Walkability } from "../../../../enums/walkability";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IRoomOverview from "../../../Interfaces/roomOverview";
import CRStep5Visualization from "../../RoomForm/Step5/Visualization/CRStep5Visualization.component";
import styles from './TraficLoadOverview.module.css'

const TraficLoadOverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <div>
            <input onChange={() => { }} name={`walkability${params.id}`} checked type="radio" /> <label htmlFor="walkability">
                {params.walkability === Walkability.OTHER && <span>{/*{t("Other traffic loads")}*/} {`${params.traficLoadsDescription} (${params.traficLoads} kg/m2)`}</span>}
                {params.walkability === Walkability.LESS_100 && <span>{t('Walkable (< 100 kg/m2)')}</span>}
                {params.walkability === Walkability.LESS_200 && <span>{t('Walkable (< 200 kg/m2)')}</span>}
                {params.walkability === Walkability.NOT_WALKABLE && <span>{t("Not walkable")}</span>}
            </label>
        
            <div className={styles.overview__container}>
                <CRStep5Visualization  {...{ ...params as unknown as IRoomFormData }} />
            </div>
        </div>
    )
}

export default TraficLoadOverviewComponent;
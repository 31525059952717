import INotificationModal from '../../Interfaces/notificationModal';
import styles from './NotificationModal.module.css'
import InitNotificationModalLogic from './NotificationModal.logic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const NotificationModalComponent: React.FC<INotificationModal> = (props: INotificationModal) => {
    const { action, close } = InitNotificationModalLogic(props);

    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <div className={styles.close__button__container}>
                    <button onClick={close} className={styles.close__button}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></button>
                </div>
                <img className={styles.icon} src={props.icon} alt={props.title} />
                <h2 className={styles.title}>{props.title}</h2>
                <p dangerouslySetInnerHTML={{__html:props.description}} className={styles.description}></p>
                <button onClick={action} className={styles.button}>{props.actionText}</button>
            </div>
        </div>
    )
}

export default NotificationModalComponent;
import UtilsHelper from "../../../../helpers/utils.helper";
import IGridProduct from "../../../Interfaces/gridProduct";
import defaultImage from '../../../../assets/images/wave3/noimage.svg'

const InitProductComponentLogic = (params: IGridProduct) => {
        const getImage = () => {
        if (params?.fileInfos !== undefined && params.fileInfos.length > 0) {
            return UtilsHelper.createFileUrl(params.fileInfos[0].url);
        }
        return defaultImage;
    }


    return {getImage}
}

export default InitProductComponentLogic
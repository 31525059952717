import { create } from "zustand";

export const useMainLayoutStore = create<State & Action>((set) => ({
    navigationHeight: 0,
    footerHeight: 0,
    contentHeight: 0,
    setNavigationHeight: (n: number) => set({ navigationHeight: n }),
    setFooterHeight: (n: number) => set({ footerHeight: n }),
    setContentHeight: (n: number) => set({ contentHeight: n })
}))

type State = {
    navigationHeight: number,
    footerHeight:number,
    contentHeight:number,
}

type Action = {
    setNavigationHeight: (n: number) => void,
    setFooterHeight: (n: number) => void,
    setContentHeight: (n: number) => void
}
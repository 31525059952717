import { useNavigate, useSearchParams } from "react-router-dom";
import IProjectGrid from "../../../Interfaces/projectGrid";

const InitProjectsGridComponentLogic = (props: IProjectGrid) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  return { searchParams, navigate };
};

export default InitProjectsGridComponentLogic;

import { useEffect, useState } from "react";
import { TiltProtection } from "../../../../enums/tiltProtection";
import { Walkability } from "../../../../enums/walkability";
import useRoomApiService from "../../../ApiService/roomApiService";
import useBackButtonHelper from "../../../helpers/backButtonHelper";
import dataHandlerHelper from "../../../helpers/dataHandler.helper";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IStep from "../../../Interfaces/Step";

const InitCRStep7Logic = (params: IStep<IRoomFormData>) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const {updateRoom} = useRoomApiService()
    const {resetBackButtonToDashboardState} = useBackButtonHelper()
    const antiTiltDisabled = () => {
       return (params.data.walkability===Walkability.LESS_200 || params.data.walkability===Walkability.OTHER)
    }

    useEffect(() => {
        params.setDisableNext(false, 6);
        let newData = {...params.data, nextStep: (params.data.nextStep > 7 ? params.data.nextStep : 7)}
        if (params.data.walkability === Walkability.LESS_200 || params.data.walkability === Walkability.OTHER) {
            newData = { ...newData, tiltProtection: TiltProtection.STIFFENING_PLANKING };
        }else{
            newData = { ...newData, tiltProtection: TiltProtection.ANTITILT_ANGLE };
        }

        // if (searchParams.get('mode') === 'edit') {
        //     params.options?.setBackText(t("Or back to project overview"))
        // }
        // else if (searchParams.get('mode') === 'redirection') {
        //     params.options?.setBackText(t("Or back to projects"))
        // }
        // else {
        //     params.options?.setBackText(t("Or back to project"))
        // }
        updateRoom(newData, setIsLoading, params.options.setAllowSkipTo, params.setData);
        if(params.setShouldSetBackButton)params.setShouldSetBackButton(false)
        // eslint-disable-next-line 
    }, []);

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        let newData = dataHandlerHelper.handleInputChange(event, { ...params.data });
        params.options.setAllowSkipTo(7)
        params.setData({...newData, nextStep: 7});
        if (params.setShouldSetBackButton) resetBackButtonToDashboardState(params.setShouldSetBackButton)
    }
    return { onChange, antiTiltDisabled, isLoading };
}


export default InitCRStep7Logic;
import { useState, useEffect } from 'react';
import useUserHelper from "../../../helpers/userHelper";
import IOverviewItem from "../../../Interfaces/overviewItem";

interface RoomOverviewItemLogicReturn {
  canEdit: boolean;
  editUrl: string | null;
}

const RoomOverviewItemLogic = (params: IOverviewItem): RoomOverviewItemLogicReturn => {
  const { isAdmin } = useUserHelper();
  
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [editUrl, setEditUrl] = useState<string | null>(null);
  

  useEffect(() => {
    if (params.editUrl && (params.data.status === 'DRAFT' || isAdmin())) {
      setCanEdit(true);
      setEditUrl(params.editUrl);
    } else {
      setCanEdit(false);
      setEditUrl(null);
    }

  }, []);

  return {
    canEdit,
    editUrl
  };
};

export default RoomOverviewItemLogic;

import { faWarning, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { t } from "i18next";
import { CartNotificationType } from "../../Stores/cart.store";
import styles from "./CartNotificationModal.module.css";
import InitCartNotificationModalLogic from "./NotificationModalLogic";
const CartNotificationModalComponent = () => {
  const { notificationType, hideModal, shouldShowNotificationModal } =
    InitCartNotificationModalLogic();

  return (
    <>
      {shouldShowNotificationModal && (
        <div
          className={`${styles.notification} ${
            notificationType === CartNotificationType.ADDED
              ? styles.notification__green
              : styles.notification__orange
          }`}
        >
          <FontAwesomeIcon icon={faWarning} />
          <div className={styles.notification__text}>
            {notificationType === CartNotificationType.REMOVED
              ? t(
                  'Your project is removed from Cart and moved to "Ready Projects" section.'
                )
              : t("The project is added to the cart")}
          </div>
          <button className={styles.notification__button}>
            <FontAwesomeIcon icon={faXmark} onClick={hideModal} />
          </button>
        </div>
      )}
    </>
  );
};

export default CartNotificationModalComponent;

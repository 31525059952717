import IInputShared from "../../Interfaces/inputShared";
import styles from "./InputShared.module.css";
import stepStyles from "./StepInput.module.css";
import React, { useEffect, useState } from "react";
import { t } from "i18next";

const InitInputLogic = (props: IInputShared) => {
  const [showPass, setShowPass] = useState<boolean>(false);
  const [password, setPassword] = useState<string>(props.value?.toString());
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>(null!);
  const [value, setValue] = useState<string>(
    props.value ? props.value.toString() : null!
  );
  const getInputClass = () => {
    let className = "";
    if (props.mode === "step") {
      if (props.type === "password") return styles.show__pass__input;

      className = stepStyles.input;
    }

    className = props.units
      ? `${styles.commonInput} ${styles.step__input} ${styles.units__input}`
      : props.object
      ? `${styles.commonInput} ${styles.step__input}`
      : `${styles.commonInput} ${styles.input}`;

    if (props.mode === "settings") className = styles.settings__input;
    if (isError) className += ` ${styles.error}`;

    return className;
  };

  const getLabelClass = () => {
    if (props.mode === "settings" || props.type === "password")
      return styles.settings__label;

    return props.mode === "step"
      ? `${stepStyles.label} ${stepStyles.ml10}`
      : styles.label;
  };

  const evaluatePassword = (event: React.FormEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value.toString());
  };

  const toggleShowPassword = () => {
    setShowPass(!showPass);
  };

  const getHideOrShow = () => {
    if (props.showPasswordGroup !== undefined) {
      if (props.showPasswordGroup) return t("Hide");
      return t("Show");
    }

    if (showPass) return t("Hide");
    return t("Show");
  };

  const checkForErrors = (e?: string) => {
    if (props.error) {
      setIsError(true);
      return;
    }
    if (value === null || value === "") {
      setIsError(false);
      return;
    }
    setErrorMsg(t("Invalid input"));

    if (props.errorMsg) {
      if (Number(value) > Number(props.max)) {
        setIsError(true);
        setErrorMsg(props.errorMsg.maxMsg);
      } else if (Number(value) < Number(props.min)) {
        setIsError(true);
        setErrorMsg(props.errorMsg.minMsg);
      } else setIsError(false);
    }
  };

  useEffect(() => {
    checkForErrors();
    //eslint-disable-next-line
  }, [props.max, props.min, value]);

  const shouldShowPass = () => {
    if (props.showPasswordGroup !== undefined) {
      if (props.showPasswordGroup) return true;
      return false;
    }

    if (showPass) return true;
    return false;
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const regex = /^[0-9]*$/g;
    const copiedValue = e.clipboardData?.getData("text");
    if (copiedValue && !regex.test(copiedValue)) e.preventDefault();
  };

  return {
    getInputClass,
    getLabelClass,
    password,
    evaluatePassword,
    getHideOrShow,
    shouldShowPass,
    toggleShowPassword,
    isError,
    errorMsg,
    checkForErrors,
    setValue,
    handlePaste,
  };
};
export default InitInputLogic;

import IProfileAll from "../../interfaces/profileAll"
import { clientInstance } from "./clientInstance"


const useProfileApiService = () => {
    const client = clientInstance();
    const getProfileById = (id: number) => 
        client.get(`/profiles/get/${id}`)

    const updateProfile = (formValue: any) =>
        client.post(`/profiles/update`, formValue)

    const createProfile = (formValue: IProfileAll) =>
        client.post(`/profiles/create`, formValue)

    const getProfilesByPage = (page: number, rowsPerPage: number) =>
        client.get<any>(`/profiles/get?page=${page}&size=${rowsPerPage}&sort=name,asc`)

    const deleteProfileRequest = (id: number) =>
        client.delete<any>(`/profiles/delete/${id}`)

    return {getProfileById, updateProfile, createProfile, getProfilesByPage, deleteProfileRequest}
}

export default useProfileApiService
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { SpanDirection } from "../../../enums/spanDirection";
import IOrder from "../../Interfaces/order";
import IRoomOverview from "../../Interfaces/roomOverview";
import watermark from "../../../assets/images/watermark.png";
import imageHelper from "../../helpers/imageHelper";
import ExcelJS from 'exceljs';
import { saveAs } from "file-saver";
import IRoomFormData from "../../Interfaces/roomFormData";
import roomHelper from "../../helpers/roomHelper";
import { TiltProtection } from "../../../enums/tiltProtection";

const InitPrintOrderLogic = (order: IOrder) => {
  const [articles, setArticles] = useState<any[]>(null!);

  const [isLoading, setLoading] = useState<boolean>(true);
  const currentDate = () => {
    let date = new Date();
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}.`;
  };
  const printRef = useRef<any>(null!);
  const handlePrint = useReactToPrint({
    onBeforeGetContent: () => imageHelper.preloadImage(watermark),
    content: () => printRef.current,
    copyStyles: true,
  });
  const styleSheetHeaders = (worksheet: ExcelJS.Worksheet) => {
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' } // White text
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4472C4' } // Dark blue background
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center'
      };
      
    });
  };
  
  const handleExcelSingleSheet = async () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(t('Order Data'));
  
    const room = order.rooms[0];
  
    const articlesData =
      articles[0]?.map((article: any, index: number) => [
        index + 1,
        article.sku,
        article.name,
        article.quantity,
        article.singleLength
          ? (article.singleLength / 1000).toLocaleString()
          : "",
        article.totalLength
          ? (article.totalLength / 1000).toLocaleString()
          : "",
        t(article.unit),
      ]) || [];
  
    const combinedData = [
      [t("First name"), t("Last name"), t("Address"), t("Phone"), t("Email address")],
      [
        order.user.firstName,
        order.user.lastName,
        order.user.address,
        order.user.phone,
        order.user.username,
      ],
      [], // Blank row
      [t("Project name"), t("Project code"), t("Date")],
      [order.name, order.rooms[0].code, currentDate()],
      [],
      [
        t("Room name"),
        t("Length of room") + " (mm)",
        t("Room width") +" (mm)",
        t("Span direction"),
        t("Center distance") +" (mm)",
        t("Deflection limit"),
        t("Walkability"),
        t("Tilt protection"),
      ],
      [
        room.name,
        room.length,
        room.width,
        room.spanDirection === SpanDirection.ROOM_LENGTH
          ? t("Room length")
          : t("Room width"),
        room.centerDistance,
        roomHelper.getDeflectionLimiitString(room as unknown as IRoomFormData),
        t(room.walkability),
        room.tiltProtection === TiltProtection.ANTITILT_ANGLE
          ? t("Anti-tilt angle on top side")
          : t("Stiffening planking on the top side"),
      ],
      [],
      [
        t("Position"),
        t("Article Number"), 
        t("Article Name"),
        t("Quantity"),
        t("Single Length") + " (m)",
        t("Total Length") + " (m)",
        t("Unit"),
      ],
      ...articlesData,
    ];
  
    sheet.addRows(combinedData);
  
    const headerRows = [0, 3, 6, 9];
  
    headerRows.forEach(rowIndex => {
      const row = sheet.getRow(rowIndex + 1); // +1 because ExcelJS is 1-indexed
      row.eachCell((cell) => {
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' } // White text
        };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '4472C4' } // Dark blue background
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
      });
    });
  
    // Auto-fit columns
    sheet.columns.forEach(column => {
      let maxLength = 0;
      column?.eachCell?.({ includeEmpty: true }, (cell) => {
        const cellValue = cell.value?.toString() || '';
        maxLength = Math.max(maxLength, cellValue.length);
      });
      column.width = maxLength + 2; // Add some padding
    });
  
    // Generate and save the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, `Order_${order.rooms[0].code}_${currentDate()}.xlsx`);
  };

  const handleExcelMultipleSheets = async () => {
    const workbook = new ExcelJS.Workbook();
  
    const customerSheet = workbook.addWorksheet(t('Customer info'));
    const customerData = [
      {
        "First name": order.user.firstName,
        "Last name": order.user.lastName,
        "Address": order.user.address,
        "Phone number": order.user.phone,
        "E-mail": order.user.username,
      }
    ];
    
    customerSheet.columns = [
      { header: t('First name'), key: 'First name', width: 20 },
      { header: t('Last name'), key: 'Last name', width: 20 },
      { header: t('Address'), key: 'Address', width: 30 },
      { header: t('Phone'), key: 'Phone number', width: 20 },
      { header: t('Email address'), key: 'E-mail', width: 30 }
    ];
  
    styleSheetHeaders(customerSheet);
  
    customerSheet.addRows(customerData);
  
    const projectSheet = workbook.addWorksheet(t('Project Info'));
    const projectData = [
      {
        "Project name": order.name,
        "Project code": order.rooms[0].code,
        "Date": currentDate(),
      }
    ];
  
    projectSheet.columns = [
      { header: t('Project name'), key: 'Project name', width: 30 },
      { header: t('Project code'), key: 'Project code', width: 30 },
      { header: t('Date'), key: 'Date', width: 20 }
    ];

    styleSheetHeaders(projectSheet);
  
    projectSheet.addRows(projectData);
  
    if (order.rooms && Array.isArray(order.rooms)) {
      const roomsSheet = workbook.addWorksheet(t('Room'));
      const roomsData = order.rooms.map((room, index) => ({
        "Room Name": room.name,
        "Room Length (mm)": room.length,
        "Room Width (mm)": room.width,
        "Span Direction": room.spanDirection === SpanDirection.ROOM_LENGTH
          ? t("Room length") : t("Room width"),
        "Center Distance (mm)": room.centerDistance,
        "Deflection Limit": roomHelper.getDeflectionLimiitString(
          room as unknown as IRoomFormData
        ),
        "Walkability": t(room.walkability),
        "Tilt Protection": room.tiltProtection === TiltProtection.ANTITILT_ANGLE
          ? t("Anti-tilt angle on top side")
          : t("Stiffening planking on the top side"),
      }));
  
      roomsSheet.columns = [
        { header: t('Room name'), key: 'Room Name', width: 20 },
        { header: t('Room length') + ' (mm)', key: 'Room Length (mm)', width: 20 },
        { header: t('Room width') + ' (mm)', key: 'Room Width (mm)', width: 20 },
        { header: t('Span direction'), key: 'Span Direction', width: 20 },
        { header: t('Center distance') + ' (mm)', key: 'Center Distance (mm)', width: 25 },
        { header: t('Deflection limit'), key: 'Deflection Limit', width: 20 },
        { header: t('Walkability'), key: 'Walkability', width: 15 },
        { header: t('Tilt protection'), key: 'Tilt Protection', width: 30 }
      ];
  
      styleSheetHeaders(roomsSheet);
  
      roomsSheet.addRows(roomsData);
    }
  
    const articlesData = articles[0]?.map((article: any, index: number) => ({
      "Position": index + 1,
      "Article Number": article.sku,
      "Article": article.name,
      "Quantity": article.quantity,
      "Single Length (m)": article.singleLength
        ? (article.singleLength / 1000).toLocaleString()
        : "",
      "Total Length (m)": article.totalLength
        ? (article.totalLength / 1000).toLocaleString()
        : "",
      "Unit": t(article.unit),
    })) || [];
  
    if (articlesData.length > 0) {
      const articlesSheet = workbook.addWorksheet(t('Articles'));
      
      articlesSheet.columns = [
        { header: t('Position'), key: 'Position', width: 10 },
        { header: t('Article Number'), key: 'Article Number', width: 20 },
        { header: t('Article'), key: 'Article', width: 30 },
        { header: t('Quantity'), key: 'Quantity', width: 15 },
        { header: t('Single Length') + ' (m)', key: 'Single Length (m)', width: 20 },
        { header: t('Total Length') + ' (m)', key: 'Total Length (m)', width: 20 },
        { header: t('Unit'), key: 'Unit', width: 10 }
      ];
  
      styleSheetHeaders(articlesSheet);
  
      articlesSheet.addRows(articlesData);
    }
  
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    saveAs(blob, `Order_${order.rooms[0].code}_${currentDate()}.xlsx`);
  };
  

  useEffect(() => {
    document.title = `WSB - ${order.name}`;
    if (order?.articles !== undefined) {
      let firstPageArticles = order?.articles.slice(0, 17);
      let otherPagesArticles = order?.articles.slice(
        17,
        order?.articles.length + 1
      );
      let pieces = Math.ceil(otherPagesArticles.length / 27);
      let newArticles = [firstPageArticles];
      for (let index = 0; index < pieces; index++) {
        newArticles.push(otherPagesArticles.slice(index, index * 27 + 27));
      }
      setArticles(newArticles);
    }

    setLoading(false);

    return () => {
      document.title = "WSB";
    };
  }, []);

  const calculateRoomLength = (room: IRoomOverview) => {
    var length = room.length;
    if (room.spanDirection === SpanDirection.ROOM_WIDTH) {
      length = room.width;
    }
    return length;
  };

  return {
    printRef,
    handlePrint,
    isLoading,
    currentDate,
    articles,
    calculateRoomLength,
    handleExcelMultipleSheets,
    handleExcelSingleSheet,
  };
};

export default InitPrintOrderLogic;

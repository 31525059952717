import IRoomFormData from "../../../../Interfaces/roomFormData";
import i18n from 'i18next'
import roomLength from '../../../../../assets/images/wave3/visualization/en-room-length.svg';
import roomLengthDe from '../../../../../assets/images/wave3/visualization/de-room-length-red.svg';
import roomLengthRs from '../../../../../assets/images/wave3/visualization/rs-room-length-red.svg';

import roomWidth from '../../../../../assets/images/wave3/visualization/en-room-width.svg';
import roomWidthDe from '../../../../../assets/images/wave3/visualization/de-room-width-red.svg';
import roomWidthRs from '../../../../../assets/images/wave3/visualization/rs-room-width-red.svg';


const CRStep2VisualizationLogic = (params: IRoomFormData) => {
    const getLengthImage = () => {
        if (i18n.language === "SR") {
            return roomLengthRs;
        }
        else if (i18n.language === "DE") {
            return roomLengthDe;
        }
        else {
            return roomLength;
        }
    }

    const getWidthImage = () => {
        if (i18n.language === "SR") {
            return roomWidthRs;
        }
        else if (i18n.language === "DE") {
            return roomWidthDe;
        }
        else {
            return roomWidth;
        }
    }

    return {getLengthImage, getWidthImage}
}

export default CRStep2VisualizationLogic;
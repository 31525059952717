import IRoomFormData from '../../../Interfaces/roomFormData';
import styles from './ArrowsVisualization.module.css';


import InitArrowsVisualizationLogic from './ArrowsVisualization.logic';

const ArrowsVisualizationComponent: React.FunctionComponent<IRoomFormData> = (params: IRoomFormData) => {
    const {getLengthImage, getWidthImage} = InitArrowsVisualizationLogic(params);
    return (
        <>
            <img className={styles.room__length} src={getLengthImage()} alt="room" />
            <img className={styles.room__width} src={getWidthImage()} alt="room width" />
        </>
    )
}

export default ArrowsVisualizationComponent;
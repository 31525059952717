import React from "react";
import StepFormComponent from "../StepForm/StepForm.component";
import InitSelectProductLogic from "./SelectProductLogic";
import styles from "./SelectProduct.module.css";
import { Link } from "react-router-dom";
import { t } from "i18next";
import submitIcon from "../../../assets/images/wave3/submit-icon.svg";
import leftIcon from "../../../assets/images/wave3/arrow-left.svg";
import LoadingComponent from "../Loading/Loading.component";
import ButtonShared from "../../SharedComponents/ButtonShared/ButtonShared";

const SelectProductComponent: React.FunctionComponent = () => {
  const {
    form,
    data,
    roomId,
    isLoading,
    exceededServicability,
    navigate,
    backTranslationKey,
    backUrl,
    backButtonClick,
  } = InitSelectProductLogic();

  return (
    <>
      {isLoading && <LoadingComponent />}
      {data?.suggestedProducts?.length !== undefined && !isLoading && (
        <StepFormComponent {...form} />
      )}
      {exceededServicability === false &&
        data?.suggestedProducts?.length === undefined &&
        !isLoading && (
          <div className={styles.no__product__container}>
            <div className={styles.no__info}>
              <h2 className={styles.no__product__title}>
                {t("Sorry, there are no available products for your project")}
              </h2>
              <p className={styles.no__product__tip}>
                {t(
                  "Please check your inputs and try to reduce dimensions and loads..."
                )}
              </p>
            </div>
            <div className={styles.controls}>
              <Link
                to={`/room?roomId=${roomId}`}
                style={{ textDecoration: "none" }}
              >
                <button className={styles.controls__button}>
                  <img
                    className={styles.left__icon}
                    src={leftIcon}
                    alt="edit room"
                  />
                  {t("Edit project")}
                </button>
                {(backTranslationKey !== null && data?.possibleProducts !== undefined) && (
                  <div className={styles.dashboard__button}>
                    <Link
                      className={styles.controls__left__link}
                      to={backUrl ?? ""}
                    >
                      <ButtonShared
                        onClick={backButtonClick}
                        img_src={leftIcon}
                        img_side="left"
                        type="button"
                        text={t(backTranslationKey ?? "")}
                      />
                    </Link>
                  </div>
                )}
              </Link>
              {data.possibleProducts && (
                <Link to={`/room/${roomId}`} style={{ textDecoration: "none" }}>
                  <button className={styles.controls__button}>
                    {t("Ok, go to project overview")}
                    <img
                      className={styles.right__icon}
                      src={submitIcon}
                      alt="Ok"
                    />
                  </button>
                </Link>
              )}
            </div>
            <div className={styles.dashboard__button}>
              <ButtonShared
                type="button"
                text={t("Back to dashboard")}
                img_side={"left"}
                img_src={leftIcon}
                onClick={() => {
                  navigate("/dashboard");
                }}
              />
            </div>
          </div>
        )}
      {exceededServicability && (
        <div className={styles.no__product__container}>
          <div className={styles.no__info}>
            <p>
              <strong>
                {t("Sorry, there are no products available for your project.")}
              </strong>
              <br />
              {t("Please check your entries and try to")}
              <br />
              {t("change your parameters, such as:")}
              <div className={styles.exceeded__servicability__reasons}>
                <ul>
                  <li className={styles.list__item}>{t("Span")}</li>
                  <li className={styles.list__item}>{t("Center distance")}</li>
                  <li className={styles.list__item}>{t("Deflection limit")}</li>
                  <li className={styles.list__item}>{t("Ceiling weight")}</li>
                </ul>
              </div>
              {t("If this does not work, please feel free to contact")}
              <br />
              {t(
                "the lightweight steel construction team. You can reach this under the"
              )}{" "}
              <br />
              {t("Email address")}:
              <a
                href="mailto:stahlleichtbau@protektor.de"
                className={styles.email}
              >
                {"\tstahlleichtbau@protektor.de"}
              </a>
              <br />
              <br />
              {t("Thank you!")}
            </p>
          </div>
          <div className={styles.controls}>
            <Link
              to={`/room?roomId=${roomId}`}
              style={{ textDecoration: "none" }}
            >
              <button className={styles.controls__button}>
                <img
                  className={styles.left__icon}
                  src={leftIcon}
                  alt="edit room"
                />
                {t("Edit project")}
              </button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default SelectProductComponent;

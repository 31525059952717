import React, { Fragment, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import LoadingComponent from './Wave3/Components/Loading/Loading.component';
import PrivateRouteComponent from './Wave3/Components/PrivateRoute/PrivateRoute.component';
import routes from './config/routes';
import VerificationModalComponent from './Wave3/Components/VerificationModal/VerificationModal.component';
import ErrorModal from './Wave3/Components/ErrorModal/ErrorModal.component';
import tokenHelper from './Wave3/helpers/tokenHelper';
import useUserApiService from './Wave3/ApiService/userApiService';
import { useTranslation } from 'react-i18next';
import useUserHelper from './Wave3/helpers/userHelper';
import i18next from 'i18next';
import CartNotificationModalComponent from './Wave3/Components/CartNotificationModal/CartNotificationModal.component';
const Application: React.FC<{}> = () => {
  // eslint-disable-next-line 
  useTranslation();

  
  const [isLoading, setLoading] = useState<boolean>(true);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  let navigate = useNavigate();
  const { loadUserProfile } = useUserApiService()
  const {deleteUser} = useUserHelper()

  useEffect(() => {
    if (tokenHelper.getToken() !== null) {
      loadUserProfile().then((response) => { 
        i18next.changeLanguage(response.data.language)
      }).catch(() =>{tokenHelper.removeToken(); navigate('/')}).finally(() => { setLoading(false) })
    }
    else {
      setLoading(false)
    }
    window.addEventListener("beforeunload", deleteUser)

    return () => {
      window.removeEventListener("beforeunload", deleteUser)
    }
    
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {isLoading && <div className='loading'><LoadingComponent></LoadingComponent></div>}

      <VerificationModalComponent />
      <Routes>
        {
            routes.map((route, index) => {
            return (
              <Route key={index} path={route.path} element={<PrivateRouteComponent route={route} />} />
            );
          })
          
        }
      </Routes>
      {showErrorModal && <ErrorModal text={'errorMessage'} title={'errorTitle'} close={() => { setShowErrorModal(false) }} />}
     <CartNotificationModalComponent/>
    </Fragment>
  );
}

export default Application;
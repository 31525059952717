import IConnectionAngle from "../../interfaces/connectionAngle"
import { clientInstance } from "./clientInstance"


const useConnectionAngleApiService = () => {
    const client = clientInstance()

    const getConnectionAnglesByPage = (page: number, rowsPerPage: number) => 
        client.get<any>(`/connectionAngles/get?page=${page}&size=${rowsPerPage}&sort=name,asc`)

    const getAllConnectionAngles = () => 
        client.get(`/connectionAngles/get`)

    const deleteConnectionAngleRequest = (id: number) =>
        client.delete<any>(`/connectionAngles/delete/${id}`)
    
    const getConnectionAngleById = (id: number) =>
        client.get(`/connectionAngles/get/${id}`)

    const updateConnectionAngle = (formValue: any) => 
        client.post(`/connectionAngles/update`, formValue)

    const createConnectionAngle = (formValue: IConnectionAngle) =>
        client.post(`/connectionAngles/create`, formValue)
    
    
    return {getConnectionAnglesByPage, deleteConnectionAngleRequest, getConnectionAngleById, updateConnectionAngle, createConnectionAngle, getAllConnectionAngles}
}

export default useConnectionAngleApiService
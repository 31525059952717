const Config = {
  defaults: {
    namespace: "Application",
    //apiUrl: "http://localhost:8080/api",
    apiUrl: "https://api.myedificio.de/api",
    reCaptchaSiteKey: "6Lc4N8UgAAAAAC05PyNS9QCRpPsHBJXDFVKeYHkT",
  },
};

export default Config;

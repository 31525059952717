import IRoomFormData from "../../../../Interfaces/roomFormData";
import l300Icon from '../../../../../assets/images/wave3/visualization/s3-300-length.svg';
import l300Iconb from '../../../../../assets/images/wave3/visualization/s3-300-width.svg';
import l500Icon from '../../../../../assets/images/wave3/visualization/s3-500-length.svg';
import l500Iconb from '../../../../../assets/images/wave3/visualization/s3-500-width.svg';

import styles from './CRStep4Visualization.module.css'
import { DeflectionLimit } from "../../../../../enums/deflectionLimit";
import { SpanDirection } from "../../../../../enums/spanDirection";
import ArrowsVisualizationComponent from "../../Visualization/ArrowsVisualization.component";

const CRStep4VisualizationComponent: React.FunctionComponent<IRoomFormData> = (params: IRoomFormData) => {
    return (
        <div className={styles.container}>


            <div className={`${styles.value__container} ${SpanDirection.ROOM_LENGTH === params.spanDirection ? styles.deflection__length : styles.deflection__width}`}>
                {params.deflectionLimitString !== DeflectionLimit.OTHER &&
                    <div className={`${styles.value} ${styles.focus__value}`}>{params.deflectionLimitString}</div>
                }

                {params.deflectionLimitString === DeflectionLimit.OTHER &&
                    <div className={`${styles.value} ${styles.focus__value}`}>{params.deflectionLimit} mm</div>
                }
            </div>


            {(params.deflectionLimitString === DeflectionLimit.OTHER || params.deflectionLimitString === DeflectionLimit.l500) &&
                <>
                    {params.spanDirection === SpanDirection.ROOM_LENGTH && <img className={styles.room__image} src={l500Icon} alt="" />}
                    {params.spanDirection === SpanDirection.ROOM_WIDTH && <img className={styles.room__image} src={l500Iconb} alt="" />}
                </>
            }

            {params.deflectionLimitString === DeflectionLimit.l300 &&
                <>
                    {params.spanDirection === SpanDirection.ROOM_LENGTH && <img className={styles.room__image} src={l300Icon} alt="" />}
                    {params.spanDirection === SpanDirection.ROOM_WIDTH && <img className={styles.room__image} src={l300Iconb} alt="" />}
                </>
            }




            {/* size  */}
            <ArrowsVisualizationComponent {...params}></ArrowsVisualizationComponent>

            <div className={`${styles.value__container} ${styles.length}`}>
                <div className={styles.value}>{params.length / 1000} m</div>
            </div>

            <div className={`${styles.value__container} ${styles.width}`}>
                <div className={styles.value}>{params.width / 1000} m</div>
            </div>

            {/* center sistance limit */}
            <div className={`${styles.value__container} 
                ${params.spanDirection === SpanDirection.ROOM_LENGTH ? styles.center__distance__length : styles.center__distance__width}`}>
                <div className={styles.value}>{params.centerDistance} mm</div>
            </div>

        </div>
    )
}

export default CRStep4VisualizationComponent;
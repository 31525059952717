import { t } from 'i18next';
import React from 'react'
import { DeflectionLimit } from '../../../../enums/deflectionLimit';
import IRoomFormData from '../../../Interfaces/roomFormData';
import IStep from '../../../Interfaces/Step';
import InitCRStep4Logic from './CRStep4.logic';
import styles from '../RoomForm.module.css'
import CRStep4VisualizationComponent from './Visualization/CRStep4Visualization.component';
import { SpanDirection } from '../../../../enums/spanDirection';
import LoadingComponent from '../../Loading/Loading.component';


const CRStep4Component: React.FunctionComponent<IStep<IRoomFormData>> = (params: IStep<IRoomFormData>) => {
    const { onChange, handleDeflectionLimitStringValueChange, isLoading } = InitCRStep4Logic(params);

    return (
        <div className={styles.container}>
            {isLoading && <LoadingComponent />}
            {!isLoading && <><div className={styles.data}>
                <h3 className={styles.title}>{t("Please select the desired deflection limit")}</h3>
                <label className={styles.label}>{t("Deflection limit")}</label>

                <div className={styles.radio}>
                    <input value={DeflectionLimit.l300}
                        id={DeflectionLimit.l300}
                        name="deflectionLimitStringValue" type="radio"
                        checked={params.data.deflectionLimitString === DeflectionLimit.l300}
                        onChange={handleDeflectionLimitStringValueChange} />
                    <label htmlFor={DeflectionLimit.l300}> {DeflectionLimit.l300}</label>

                </div>


                <div className={styles.radio}>
                    <input value={DeflectionLimit.l500}
                        id={DeflectionLimit.l500}
                        name="deflectionLimitStringValue" type="radio"
                        checked={params.data.deflectionLimitString === DeflectionLimit.l500}
                        onChange={handleDeflectionLimitStringValueChange} />
                <label htmlFor={DeflectionLimit.l500}> {DeflectionLimit.l500}</label>

                </div>

                <div className={styles.radio}>
                    <input value={DeflectionLimit.OTHER}
                        id={DeflectionLimit.OTHER}
                        name="deflectionLimitStringValue" type="radio"
                        checked={params.data.deflectionLimitString === DeflectionLimit.OTHER}
                        onChange={handleDeflectionLimitStringValueChange} />
                    <label htmlFor={DeflectionLimit.OTHER}> {t(DeflectionLimit.OTHER, { ns: 'enums' })}</label>
                </div>
                {params.data.deflectionLimitString === DeflectionLimit.OTHER &&
                    <div className={styles.input__value}>
                        <input
                            type='number'
                            id='deflectionLimit'
                            name='deflectionLimit'
                            value={params.data.deflectionLimit.toString()}
                            onChange={onChange}
                            min={0}
                            step={0.1}
                            className={styles.input}
                        />
                        <div className={styles.value__description}>
                            mm
                        </div>
                    </div>
                }

                <h5 className={styles.description__title}>{t("Whats that?")}</h5>
                <p className={styles.description}>{params.data.spanDirection === SpanDirection.ROOM_LENGTH ? t("What is deflection limit?", { l500Result: +(params.data.length/500).toFixed(2), l300Result: +(params.data.length/300).toFixed(2), beamLength: params.data.length})
                    : t("What is deflection limit?", { l500Result: +(params.data.width/500).toFixed(2), l300Result: +(params.data.width/300).toFixed(2), beamLength: params.data.width})}</p>
            </div>
            <div className={styles.visualization}>
                <CRStep4VisualizationComponent {...params.data} />
                <p>{`${params.data.name}`}</p>
            </div>
            </>}
        </div>
    )
}

export default CRStep4Component;
import IRoomOverview from "../../../Interfaces/roomOverview";
import ProductOverviewComponent from "./ProductOverview.component";

const AntiTiltAngleOverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <>
            <ProductOverviewComponent name="PROTEKTOR EDIFICIO Kippsicherungswinkel (30 x 30 mm)" calculationResult={undefined!} fileInfos={[{url: params.antiTiltAssetPath ?? "" }]} id={undefined!}  />
        </>
    )
}

export default AntiTiltAngleOverviewComponent;
import { useEffect } from "react"
import { useCartStore } from "../../Stores/cart.store"

const InitCartNotificationModalLogic = () => {
    const notificationType = useCartStore((state) => state.notificationType)
    const  hideModal = useCartStore((state) => state.hideModal)
    const shouldShowNotificationModal = useCartStore((state) => state.shouldShowNotificationModal)

    useEffect(() => {
        const timer = setTimeout(() => {
            hideModal()
          }, 3000);
          return () => clearTimeout(timer);
    })
    
    return {notificationType, hideModal, shouldShowNotificationModal}
}

export default InitCartNotificationModalLogic
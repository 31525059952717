import React from 'react'
import LoadingComponent from '../Loading/Loading.component';
import StepFormComponent from '../StepForm/StepForm.component';
import InitRoomFormLogic from './RoomForm.logic';


const RoomFormComponent: React.FunctionComponent = () => {
    const { form, isLoading } = InitRoomFormLogic();

    return (
        <>
        {isLoading && <LoadingComponent/>}
        {!isLoading && <StepFormComponent {...form} />}
        </>
    )
}

export default RoomFormComponent;
import { useEffect, useState } from "react";
import IPagination from "../../../interfaces/pagination";

const InitPaginationLogic = (props: IPagination) => {

    const [activePage, setActivePage] = useState(0);
    const [totalPages, setTotalPages] = useState(Math.ceil(props.count / props.rowsPerPage));
    const [beginning, setBeginning] = useState(activePage === 0 ? 1 : props.rowsPerPage * activePage + 1);
    const [end, setEnd] = useState(activePage === totalPages - 1 ? props.count : beginning + props.rowsPerPage - 1);
    
    useEffect(() => {
        setTotalPages(Math.ceil(props.count / props.rowsPerPage));
        setBeginning(activePage === 0 ? 1 : props.rowsPerPage * activePage + 1);
        setEnd(activePage === totalPages - 1 ? props.count : beginning + props.rowsPerPage - 1);
        //eslint-disable-next-line
    }, [activePage, props.count]) 

    useEffect(() => {
        if(activePage >= totalPages) setActivePage(totalPages-1)
        //eslint-disable-next-line
    }, [totalPages])
    return { activePage, totalPages, beginning, end, setActivePage }
}

export default InitPaginationLogic;
import { t } from "i18next";
import { PartitionWallLoad } from "../../../../enums/partitionWallLoad";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IRoomOverview from "../../../Interfaces/roomOverview";
import CRStep6Visualization from "../../RoomForm/Step6/Visualization/CRStep6Visualization.component";
import styles from './PermanentLoadsOverview.module.css'

const PermanentLoadsOverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <div>
            {params.partitionWallLoad !== undefined &&
                <>  <input onChange={() => { }} name={`PermanentLoadsOverviewComponent${params.id}`} checked type="radio" />
                    <label htmlFor='PermanentLoadsOverviewComponent'>
                        {params.partitionWallLoad === PartitionWallLoad.LESS_OR_EQUAL_3 &&
                            <span>{t('Partition wall load incl. plaster ≤ 3 kN/m')}</span>}
                        {params.partitionWallLoad === PartitionWallLoad.GREATHER_OR_EQUAL_3_AND_LESS_OR_EQUAL_5 &&
                            <span>{t('Partition wall load incl. plaster ≥ 3 kN/m and ≤ 5 kN/m')}</span>}
                    </label>
                </>}

            <div className={styles.overview__container}>
                <CRStep6Visualization  {...{ ...params as unknown as IRoomFormData }} />
            </div>
        </div>
    )
}

export default PermanentLoadsOverviewComponent;
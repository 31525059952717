import useUserApiService from "../ApiService/userApiService"

const useUserHelper = () => {
    const { getUserProfile } = useUserApiService()

    const storeUser = (user: any) => {

        let permissions = null
        const oldUser = localStorage.getItem("user")
        if (oldUser) {
            permissions = JSON.parse(oldUser).user.userPermissions
        }

        const storedUser = {
            user: { firstName: user.firstName, lastName: user.lastName, image: user.image, enabled: user.enabled, userPermissions: permissions ? permissions : user.userPermissions },
            expirationTime: Date.now() + (10 * 60 * 1000)
        }
        localStorage.setItem("user", JSON.stringify(storedUser))
    }

    const retrieveUser = () => {
        const user = localStorage.getItem("user")
        if (user) {
            if (Date.now() < Number(JSON.parse(user).expirationTime)) return JSON.parse(user).user

            localStorage.removeItem("user")
            getUserProfile().then((response: any) => {
                storeUser(response.data)
                return response.data
            }).catch((error: any) => {
                return null
            }
            )
        }

        return null
    }

    const deleteUser = () => {
        localStorage.removeItem("user")
    }


    const containRole = (role: string): boolean => {
        let user = retrieveUser();
        if (user) {
            for (let i = 0; i < user.userPermissions.length; i++)
                if (user.userPermissions[i].permissionTitle === role)
                    return true
        }
        return false;
    }

    const isAdmin = (): boolean => {
        return containRole("ROLE_ADMIN");
    }
    return { storeUser, retrieveUser, deleteUser, isAdmin }
}

export default useUserHelper;
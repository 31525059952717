import { t } from 'i18next';
import React from 'react'
import IRoomFormData from '../../../Interfaces/roomFormData';
import IStep from '../../../Interfaces/Step';
import InitCRStep3Logic from './CRStep3.logic';
import styles from '../RoomForm.module.css'
import CRStep3VisualizationComponent from './Visualization/CRStep3Visualization.component';
import InputShared from '../../../SharedComponents/InputShared/InputShared';
import LoadingComponent from '../../Loading/Loading.component';

const CRStep3Component: React.FunctionComponent<IStep<IRoomFormData>> = (params: IStep<IRoomFormData>) => {
    const { onChange, isLoading } = InitCRStep3Logic(params);

    return (
        <div className={styles.container}>
            {isLoading && <LoadingComponent />}
            {!isLoading && <> <div className={styles.data}>
                <h3 className={styles.title}>{t("Please enter the desired center distance between the wide span beams")}</h3>
                <p className={styles.note}><span>*</span> {t("Indicates a required field")}</p>

                <InputShared type='number' name="centerDistance" labelText='Center distance' placeholder='500' value={params.data.centerDistance.toString()} onChange={onChange}
                    min="250" max="1500" step='50' units={<>mm</>} required={true} mode="step" error={params.data.centerDistance > 0 && params.data.centerDistance < 250} errorMsg={t("Center distance minimum error")}
                />

                <h5 className={styles.description__title}>{t("Whats that?")}</h5>
                <p className={styles.description}>{t("In this field you will be asked for the center distance between the wide span beams. If you do not know what is meant by this, we suggest a center distance of 500 mm.")}</p>
            </div>
            <div className={styles.visualization}>
                <CRStep3VisualizationComponent {...params.data} />
                <p>{`${params.data.name}`}</p>
            </div>
            </>}
        </div>

    )
}

export default CRStep3Component;
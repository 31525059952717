import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import IFyk from '../../../interfaces/fyk';
import IPage from '../../../interfaces/page';
import globalStyles from '../../../styles/Global.module.css';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import InputShared from '../../../Wave3/SharedComponents/InputShared/InputShared';
import loginStyles from '../../Login/Login.module.css';
import styles from '../../Login/Login.module.css';
import useFykApiService from '../../../Wave3/ApiService/fykApiService';

const CreateEditFykPage: React.FunctionComponent<IPage> = () => {
    let { id } = useParams();
    let navigate = useNavigate();

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
    
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [formValue, setformValue] = React.useState<IFyk>({
        id: null!,
        value: 0,
        profiles: [],
    });

    const {getFykById, updateFyk, createFyk} = useFykApiService()

    useEffect(() => {
        if (id) {
            getFykById(Number(id))
                .then(responseFyks => {
                    setformValue(responseFyks.data);

                }).catch(error => {
                    navigate(`/fyks`)
                })
        }

    }, [id, navigate, getFykById])

    const handleChange = (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>) => {
        setformValue({
            ...formValue,
            [event.currentTarget.name]: event.currentTarget.value
        });
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setSubmitDisabled(true);
        if (id) {
            updateFyk(formValue).then(response => {
                    navigate(`/fyks`);
                })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 400) {
                            setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                        }
                        else if (error.response.status === 404) {
                            setErrorMessage(t("Fyk not found"));
                        }
                        else {
                            setErrorMessage(t("Internal server error. Please try again later."));
                        }
                    }
                    else
                        setErrorMessage(t("Error, service unavailable. Please try again later."));

                    setSubmitDisabled(false);
                });
        }
        else {
            createFyk(formValue).then(response => {
                    navigate(`/fyks`);
                })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 400) {
                            setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                        }
                        else {
                            setErrorMessage(t("Internal server error. Please try again later."));
                        }
                    }
                    else
                        setErrorMessage(t("Error, service unavailable. Please try again later."));

                    setSubmitDisabled(false);
                });
        }
    }

    return (
        <>
                            <NavigationComponent />

            <div className={globalStyles.layout}>
                <div className={styles.form__container}>
                    <form className={styles.form} onSubmit={handleSubmit}>
                        {errorMessage !== "" &&
                            <p className={loginStyles.error__message}>{errorMessage}</p>
                        }
                        <div className={loginStyles.form__header}>
                            <h2 className={loginStyles.form__title}>{id ? t("Edit fyk") : t("Create fyk")}</h2>
                            <p className={loginStyles.form__helper}><span style={{color: "#E42313"}}>*</span>{t("indicates a required field")}</p>
                        </div>

                        <InputShared name="value" labelText='Value' value={formValue.value.toString()} type="number" step='.01' required={true} onChange={handleChange} />

                        <div className={loginStyles.form__bottom}>
                            <Link to={`/fyks`}><button type="button" className="button-secondary-link">{t("Cancel")}</button></Link>
                            <button disabled={submitDisabled} type="submit">{t("Save")}</button>
                        </div>
                    </form>
                </div >
            </div >
        </>

    )
}

export default CreateEditFykPage;
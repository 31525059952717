import { t } from 'i18next';
import { Link } from 'react-router-dom';
import InitLoginFormLogic from './LoginForm.logic';
import styles from './LoginForm.module.css'
import googleLogo from '../../../assets/images/wave3/google-logo.svg'
import InputShared from '../../SharedComponents/InputShared/InputShared';
import ButtonShared from '../../SharedComponents/ButtonShared/ButtonShared';

const LoginFormComponent: React.FC = () => {
    const { onSubmit, onChange, disableLogin, errorMessageTranslationKey, data, googleLogin, rememberMe, setRememberMe } = InitLoginFormLogic();

    return (
        <div>
        <form onSubmit={onSubmit}>
            <h2 className={styles.title}>{t('Sign in')}</h2>
            <InputShared onChange={onChange} type="email" name="username" placeholder='name@yourdomain.com' value={data.username} required={true} labelText={t('Email address')} mode="settings"/>
            <div className={styles.password__label}>
                <label className={styles.label} htmlFor="password">{t('Password')}</label>
                <Link to={'/reset/password'}>{t("Forgot password?")}</Link>
            </div>
            <InputShared labelText='' onChange={onChange} type="password" name="password" placeholder='********' value={data.password} required={true} mode="settings"/>
            <div><input checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} id="rememberMe" type="checkbox" />
                    <label className={styles.label} htmlFor="rememberMe">{t("Remember me")}</label></div>

            {errorMessageTranslationKey !== '' && <p className={styles.error__message}>{t(errorMessageTranslationKey)}</p>}

            <ButtonShared disabled={disableLogin} type="submit" text={t("Sign in")} wide={true}/>

            <p className={styles.cta__paragraph} dangerouslySetInnerHTML={{__html: t("Not a member? <a href='/register'>Sign up</a>")}}></p>

            <div className={styles.divider}>
                <div></div>
                <span>{t("Or")}</span>
                <div></div>
            </div>
            <button className={styles.google__login} type='button' onClick={() => googleLogin()}> <img src={googleLogo} alt="google logo" /> {t("Log in with Google")}</button>
        </form>
        </div>
    )
}

export default LoginFormComponent;
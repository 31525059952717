import i18next, { t } from "i18next";
import { useEffect, useState } from "react"
import IFullUser from "../../../interfaces/fullUser"
import dataHandlerHelper from "../../helpers/dataHandler.helper";
import INotificationModal from "../../Interfaces/notificationModal";
import mailIcon from '../../../assets/images/wave3/mail.svg'
import { useNavigate } from "react-router-dom";
import tokenHelper from "../../helpers/tokenHelper";
import IModal from "../../Interfaces/modal";
import useUserApiService from "../../ApiService/userApiService";
import useUserHelper from "../../helpers/userHelper";
const InitUserSettingsFormLogic = () => {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const {deleteUser} = useUserHelper()
    const [username, setUsername] = useState<string>("")
    const [data, setData] = useState<IFullUser>({
        username: "",
        password: "",
        repeatPassword: "",
        firstName: "",
        lastName: "",
        phone: "",
        address: "",
        company: "",
        image: "",
        enabled: false,
        genderTitle: "",
        language: i18next.language,
        notificationsSubscribed:false
    });
    const [formData, setFormData] = useState(new FormData());

    const [preview, setPreview] = useState<string>();
    
    const navigate = useNavigate();
    const [presentNotificationModal, setPresentNotificationModal] = useState<boolean>(false);
    const {updateUserProfile, getUserProfile} = useUserApiService()
    const {storeUser} = useUserHelper()
    const logout = () => {
        deleteUser();
        tokenHelper.removeToken(); 
        navigate('/');
    }
    const notificationModal: INotificationModal = {
        action: () => { logout() },
        close: () => { logout() },
        actionText: t('Go to login'),
        description: "Email has changed, you have been logged out, please log in again.",
        icon: mailIcon,
        title: t("Email changed")
    }

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        if(event.currentTarget.name === 'notificationsSubscribed'){
            setData({...data, notificationsSubscribed:event.currentTarget.checked })
        }
        else{
            let newData = dataHandlerHelper.handleInputChange(event, { ...data });
            setData(newData);
        }
    }

    const onFileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e === undefined) {
            return;
        }
        let selectedFiles = e.target.files;
        if (selectedFiles === null) {
            return;
        }

        formData.set('file', selectedFiles[0]);
        setFormData(formData);
        setPreview(URL.createObjectURL(selectedFiles[0]));
        e.target.files = null;
        e.target.value = null!;
    };

    const deleteImage = () => {
        return new Promise((resolve: any, reject: any) => {
            setData({ ...data, image: '' })
            formData.delete("file");
            setPreview("");
            setShowDeleteModal(false);
            resolve();
        }
        )
    }
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const deleteModal: IModal = {
        cancelAction: () => { setShowDeleteModal(false) },
        confirmAction: () => deleteImage(),
        confirmText: t("Confirm"),
        showCancel: true,
        title: t("Delete image"),
        message: t("Are you sure about this action?"),
        image: null!
    }

    const deleteFunction = () => {
        setShowDeleteModal(true);
    }


    const onSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (isSubmitDisabled) {
            return;
        }
        
        setErrorMessage("");
        setSubmitDisabled(true);
        formData.set('userString', JSON.stringify(data));
        console.log(formData)
        updateUserProfile(formData)
            .then(response => {
                storeUser(data)
                if (username !== data?.username) {
                    setPresentNotificationModal(true);
                }
                else {
                    navigate("/dashboard");
                }
            })
            .catch(error => {
                if (error.response?.status)
                    setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                else
                    setErrorMessage(t('Error, service unavailable. Please try again later.'));
                setSubmitDisabled(false);
            });
    }

    useEffect(() => {
        getUserProfile().then((response) => {
            if (response.data != null) {
                response.data.userPermissions = [];
                setData(response.data);
                setUsername(response.data["username"]);
            }
        }).catch(()=>{
            navigate("/")
        });

        // eslint-disable-next-line
    }, []);

    return { data, errorMessage, notificationModal, isSubmitDisabled, presentNotificationModal, onSubmit, onChange, deleteFunction, onFileChangeHandler, showDeleteModal, deleteModal, preview }
}

export default InitUserSettingsFormLogic;
import React from 'react'
import { Navigate } from 'react-router-dom';
import IRoute from '../../../interfaces/route';
import ErrorPage from '../../../pages/Error/ErrorPage';
import jwt_decode from 'jwt-decode';
import { t } from 'i18next';
import tokenHelper from '../../helpers/tokenHelper';

interface IPrivateRoute {
    route: IRoute
}

const PrivateRouteComponent: React.FunctionComponent<IPrivateRoute> = props => {
    let payloads: any;

    try {
        payloads = jwt_decode(tokenHelper.getToken());
    } catch (error) {
        payloads = {
            roles: []
        }
    }


    const haveAccess = (auth?: string[]) => {
        let allowedRoles = new Set(auth)
        for (let index = 0; index < payloads.roles.length; index++) {
            if (allowedRoles.has(payloads.roles[index].authority))
                return true;
        }
        return false;
    }

    const CreateRoute = (route: IRoute) => {
        if (route.auth !== undefined && tokenHelper.getToken() === null) {
            return <Navigate to="/" replace={true} />
        }
        else if (!haveAccess(route.auth) && route?.auth !== undefined) {
            return <ErrorPage code={"401"} error={t("Sorry, you don't have permission to see this page.")} />
        }
        else if (route?.auth === undefined && tokenHelper.getToken() !== null
            && (route.allowLoggedUser === undefined || route.allowLoggedUser === false)) {
            return <Navigate to="/dashboard" replace={true} />
        }
        else {
            return <route.component  {...route.props} />
        }
    }

    return CreateRoute(props.route);
}

export default PrivateRouteComponent;
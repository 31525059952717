import { t } from "i18next"
import ICookieModal from "../../Interfaces/cookieModal"
import ButtonShared from "../../SharedComponents/ButtonShared/ButtonShared"
import InitCookieModalLogic from "./CookieModal.logic"
import styles from "./CookieModal.module.css"
import LanguageSwitchComponent from "../LanguageSwitch/LanguageSwitch.component"




const CookieModal: React.FunctionComponent<ICookieModal> = (props: ICookieModal) => {
    const { accept } = InitCookieModalLogic(props)
    return (
        <div className={styles.container}>
            <div className={styles.modal}>
                <h2 className={styles.modal__title}>{t("Our cookies help improve your experience")}</h2>
                <p className={styles.modal__text}>
                    {t("Dear user, for more than 100 years we have been working together to develop various problem solvers for modern building with profiles. To ensure that we can continue to supply the right profiles for your project in the future, we would like to know more about your visit to us. For this purpose we use guaranteed DSGVO-tested cookies which pass on your know-how to us and improve the future profile of Protektor. We are happy about your help and say thank you. The team of Protektor.")}
                </p>
                <div className={styles.button__container}>
                    <button className={styles.button__accept} onClick={() => { accept() }} >{t("Accept")}</button>
                    <a className={styles.button__link} target="_blank" href="https://myedificio.de/imprint">{t("Imprint")}</a>
                    <a className={styles.button__link} href={t('ppUrl')}>{t("Privacy Policy")}</a>
                </div>
                <div className={styles.language}>
                    <LanguageSwitchComponent />
                </div>
            </div>
        </div>
    )
}


export default CookieModal
import React from 'react'
import IStep from '../../../Interfaces/Step';
import InitSPStep1Logic from './SPStep1.logic';
import styles from '../SelectProduct.module.css'
import ProductComponent from '../Product/Product.component';
import IGridProduct from '../../../Interfaces/gridProduct';
import ISelectedProductsRoom from '../../../Interfaces/roomWithSelectedProducts';
import { ProductType } from '../../../enums/productType';
import arrowDown from '../../../../assets/images/wave3/arrow-down-select.svg'
import { t } from 'i18next';
const SPStep1Component: React.FunctionComponent<IStep<ISelectedProductsRoom>> = (params: IStep<ISelectedProductsRoom>) => {
    const { isActive, select, enableLoadMore, loadMore, productsToRender } = InitSPStep1Logic(params);

    return (
        <>
            <div className={styles.products}>
                {params.data?.suggestedProducts.map((product, index) =>
                    <div onClick={() => { select(product) }} className={`${styles.product} ${isActive(product) ? styles.active : ''}`} key={index}>
                        <ProductComponent  {...({ ...product, selected: isActive(product), type: ProductType.profile, recommended:true } as IGridProduct)} />
                    </div>
                )}


                {productsToRender.map((product, index) =>
                    <div onClick={() => { select(product) }} className={`${styles.product} ${isActive(product) ? styles.active : ''}`} key={index}>
                        <ProductComponent  {...({ ...product, selected: isActive(product), type: ProductType.profile, recommended:false } as IGridProduct)} />
                    </div>
                )}
            </div>

            {enableLoadMore &&
                <div className={styles.load__more}>
                    <button disabled={!enableLoadMore}
                        onClick={loadMore}
                        className={styles.load__more__button}>{t("Load more")} <img src={arrowDown} alt="arrow down" /></button>
                </div>
            }
        </>
    )
}

export default SPStep1Component;
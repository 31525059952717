import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import INotificationModal from "../../Interfaces/notificationModal";
import useUserApiService from "../../ApiService/userApiService";
import successIcon from "../../../assets/images/wave3/userChecked.svg"
import wrongIcon from "../../../assets/images/wave3/userTimes.svg"

const InitVerificationModalLogic = () => {
    let [searchParams] = useSearchParams();
    const [showModal, setShowModal] = useState<boolean>(false);
    const {verifyCode} = useUserApiService()
    const [message, setMessage] = useState<string>("");
    const [icon, setIcon] = useState("");

    useEffect(() => {
        if (searchParams.get("code") !== null) {
            verifyCode().then(response => {
                setMessage(`<span style="color:#96C369">${t('Verification successful.')}</span>`);
                setIcon(successIcon);
                setShowModal(true);
            })
            .catch(error => {
                setIcon(wrongIcon);
                if (error.response?.status)
                    setMessage(`<span style="color:#E42313">${t(error.response?.data.translatableCode, { ns: "codes" })}</span>`);
                else
                    setMessage(`<span style="color:#E42313">${t('Error, service unavailable. Please try again later.')}</span>`);
    
                setShowModal(true);
            });
        }

        //eslint-disable-next-line
    }, [setMessage, searchParams, setIcon, setShowModal])

    const close = ()=>{
        setShowModal(false);
        // navigate(window.location.pathname, {replace:true})
    }

    const modal: INotificationModal = {
        action: () => { close(); },
        actionText: t("Close"),
        close: () => { close(); },
        description: message,
        icon: icon,
        title: "Account Verification"
    }
    return { modal, showModal }
}

export default InitVerificationModalLogic;
import React from "react"
import InfiniteScroll from "react-infinite-scroller";
import InitAdminOrdersLogic from "./AdminOrders.logic"
import AdminOrdersItemComponent from "./OrdersItem/AdminOrdersItem.component";
import styles from './AdminOrders.module.css'
import { t } from "i18next";
import { OrderStatus } from "../../../enums/orderStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const AdminOrdersComponent: React.FunctionComponent = () => {
    const {
        scrollParentRef,
        next,
        orders,
        contentHeight,
        status,
        searchCode,
        onSearchInputChanged,
        getByStatus,
        loadMore,
        search } = InitAdminOrdersLogic();
    return (
        <>
            <form onSubmit={(e: any) => { e.preventDefault(); search(); }} className={styles.search_container}>
                <input
                    onChange={onSearchInputChanged}
                    type="search"
                    className={styles.search_input}
                    value={searchCode as any}
                    placeholder={t('Search by project code...')} />
                <button className={styles.search_button} onClick={search}>{t('Search...')}</button>
            </form>

            <div className={styles.navigation}>
                <div onClick={() => { getByStatus() }} className={`${styles.navigation_item} ${styles.ALL}`}>
                    {t("All")}  {status === '' && <FontAwesomeIcon icon={faCheck} />}
                </div>
                <div onClick={() => { getByStatus(OrderStatus.PENDING) }} className={`${styles.navigation_item} ${styles.status__DRAFT}`}>
                    {t("Pending")} {status === OrderStatus.PENDING && <FontAwesomeIcon icon={faCheck} />}
                </div>
                <div onClick={() => { getByStatus(OrderStatus.IN_PROGRESS) }} className={`${styles.navigation_item} ${styles.status__INPROGRESS2}`}>
                    {t("In Progress")} {status === OrderStatus.IN_PROGRESS && <FontAwesomeIcon icon={faCheck} />}
                </div>
                <div onClick={() => { getByStatus(OrderStatus.OFFER) }} className={`${styles.navigation_item} ${styles.status__INPROGRESS}`}>
                    {t("Offer")}  {status === OrderStatus.OFFER && <FontAwesomeIcon icon={faCheck} />}
                </div>
                <div onClick={() => { getByStatus(OrderStatus.REJECTED) }} className={`${styles.navigation_item} ${styles.status__REJECTED}`}>
                    {t("Rejected by customer")}  {status === OrderStatus.REJECTED && <FontAwesomeIcon icon={faCheck} />}
                </div>
                <div onClick={() => { getByStatus(OrderStatus.ORDER) }} className={`${styles.navigation_item} ${styles.status__ORDER}`}>
                    {t("Order confirmed")}  {status === OrderStatus.ORDER && <FontAwesomeIcon icon={faCheck} />}
                </div>
                <div onClick={() => { getByStatus(OrderStatus.EXECUTION) }} className={`${styles.navigation_item} ${styles.status__EXECUTION}`}>
                    {t("Executing order")}  {status === OrderStatus.EXECUTION && <FontAwesomeIcon icon={faCheck} />}
                </div>
                <div onClick={() => { getByStatus(OrderStatus.DONE) }} className={`${styles.navigation_item} ${styles.status__DONE}`}>
                    {t("Done")}  {status === OrderStatus.DONE && <FontAwesomeIcon icon={faCheck} />}
                </div>
            </div>
            <div style={{ overflow: "auto", height: `${(contentHeight - 70) * 0.9}px` }} ref={scrollParentRef}>
                {(orders !== null && orders.length > 0 && scrollParentRef !== null) &&
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={loadMore}
                        hasMore={next}
                        useWindow={false}
                        loader={<div className="loader" key={0}>Loading ...</div>}
                        getScrollParent={() => scrollParentRef.current as unknown as HTMLElement}>
                        {orders?.map((orderItem, index) =>
                            <AdminOrdersItemComponent key={index} {...orderItem} />
                        )}
                    </InfiniteScroll>}
            </div>
        </>
    )
}

export default AdminOrdersComponent;
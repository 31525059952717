import { t } from 'i18next';
import styles from './UserSettingsForm.module.css'

import NotificationModalComponent from '../NotificationModal/NotificationModal.component';
import InitUserSettingsFormLogic from './UserSettingsForm.logic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import uploadIcon from '../../../assets/images/wave3/upload.svg'
import ModalComponent from '../Modal/Modal.component';
import InputShared from '../../SharedComponents/InputShared/InputShared';
import ButtonShared from '../../SharedComponents/ButtonShared/ButtonShared';
import UtilsHelper from '../../../helpers/utils.helper';

const UserSettingsFormComponent: React.FC = () => {
    const { data, errorMessage, notificationModal, isSubmitDisabled, presentNotificationModal, onSubmit, onChange, deleteFunction, onFileChangeHandler, showDeleteModal, deleteModal, preview } = InitUserSettingsFormLogic();

    return (
        <>{data !== null &&
            <form className={styles.form} onSubmit={onSubmit}>
                <h2 className={styles.title}>{t('Update account')}</h2>
                <p className={styles.description}><span>*</span> {t('indicates a required field')}</p>
                <div className={styles.input__group}>
                    <div>
                        <InputShared name="username" labelText={t("Email address")} required={true} onChange={onChange} type="email" value={data.username} placeholder='name@yourdomain.com' mode="settings" />
                    </div>
                    <div>
                        <InputShared name="phone" labelText={t('Phone')} onChange={onChange} type="text" placeholder='123456' value={data.phone ? data.phone : ""} required={true} mode="settings" />
                    </div>
                </div>

                <div className={styles.input__group}>
                    <div>
                        <InputShared type="text" name="firstName" placeholder={t("First name")} value={data.firstName} required={true} labelText={t("First name")} onChange={onChange} mode="settings" />
                    </div>
                    <div>
                        <InputShared type="text" name="lastName" placeholder={t("Last name")} value={data.lastName} labelText={t("Last name")} onChange={onChange} mode="settings" required={true} />
                    </div>
                </div>
                <label className={styles.label}>{t("Gender")} <span>*</span></label>
                <div className={`${styles.input__gender__group}`}>
                    <div>
                        <label htmlFor='mr'><input type="radio" id="mr" name="genderTitle" value="mr" onChange={onChange} checked={data.genderTitle === "mr"} required /> {t("Mr.")}</label><br />
                        <label htmlFor='mrs'><input type="radio" id="mrs" name="genderTitle" value="mrs" onChange={onChange} checked={data.genderTitle === "mrs"} /> {t("Mrs.")}</label>
                    </div>
                </div>

                <InputShared name="address" labelText={t("Address")} required={true} placeholder={t("Address")} value={data.address ? data.address : ""} onChange={onChange} type="text" mode="settings" />
                <InputShared type="text" name="company" labelText={t("Company")} onChange={onChange} placeholder={t("Company")} value={data.company ? data.company : ""} mode="settings" />


                <div className={styles.notifications}>
                    <span>{t("Notifications")}: </span>
                    <div>
                        <input type="checkbox" id='notifications' name='notificationsSubscribed' checked={data.notificationsSubscribed} onChange={onChange} />
                        <label htmlFor='notifications'>{t("I want to receive email notifications")}</label>
                    </div>
                </div>


                {((data.image !== '' && data.image !== undefined) || preview) &&
                    <div className={styles.image__preview} onClick={deleteFunction}>
                        <div className={styles.delete__button__container}>
                            <div>
                                <div className={styles.delete__icon}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </div>
                            </div>
                        </div>
                        <img className={styles.image} src={preview ? preview : UtilsHelper.createFileUrl(data.image)} alt=""></img>
                    </div>
                }

                <div>
                    {((data.image !== '' && data.image !== undefined) || preview) &&
                        <label className={styles.label} htmlFor="fileUploadInput">{t("Replace profile image")}</label>
                    }
                    {((data.image === '' || data.image === undefined) && !preview) &&
                        <label className={styles.label} htmlFor="fileUploadInput">{t("Choose profile image")}</label>
                    }
                    <div className={styles.dropdown__upload}>
                        <input id="fileUploadInput" type="file" accept="image/*" name="file" onChange={onFileChangeHandler} />
                        <div className={styles.dropdown__upload__icon__container}>
                            <img src={uploadIcon} alt="upload icon" />
                        </div>
                        <span className={styles.dropdown__upload__label}>{t("Drag and drop or click here")}</span>
                    </div>

                </div>

                {errorMessage !== '' && <p className={styles.error__message}>{errorMessage}</p>}

                <ButtonShared type='submit' text={t("Save")} disabled={isSubmitDisabled} wide={true} />
            </form>}
            {presentNotificationModal && <NotificationModalComponent {...notificationModal}></NotificationModalComponent>}
            {showDeleteModal && <ModalComponent {...deleteModal}></ModalComponent>}
        </>
    )
}

export default UserSettingsFormComponent;
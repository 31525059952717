import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import IRoomWithSelectedProducts from "../../Interfaces/roomWithSelectedProducts";
import IStep from "../../Interfaces/Step";
import IStepForm from "../../Interfaces/StepForm";
import IStepUrl from "../../Interfaces/StepIndicator";
import SPStep1Component from "./Step1/SPStep1.component";
import SPStep2Component from "./Step2/SPStep2.component";
import useRoomApiService from "../../ApiService/roomApiService";
import IUpdateSelectedProducts from "../../Interfaces/updateSelectedProducts";
import useBackButtonHelper from "../../helpers/backButtonHelper";
import { DeflectionLimit } from "../../../enums/deflectionLimit";
import useUserHelper from "../../helpers/userHelper"

const InitSelectProductLogic = () => {
  let { projectId, roomId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<IRoomWithSelectedProducts>(null!);
  const { isAdmin } = useUserHelper();
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(false);
  const [hideNext, setHideNext] = useState(false);
  const [hidePrevious, setHidePrevious] = useState(false);
  const [stepTitle, setStepTitle] = useState<string>(t("Select Beam Profile"));
  const [backUrlText, setBackUrlText] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(true);
  const { updateSelectedProductsRequest } = useRoomApiService();
  const { getPossibleProducts, loadRoomOverview } = useRoomApiService();
  const [exceededServicability, setExceededServicability] =
    useState<boolean>(false);
  const [deflectionLimit, setDeflectionLimit] = useState<DeflectionLimit>(
    null!
  );
  const { peekBackUrl, popBackUrl } = useBackButtonHelper();
  const [backUrl, setBackUrl] = useState<string>();
  const [backTranslationKey, setBackTranslationKey] = useState<string>();

  const stepUrls: IStepUrl[] = [
    { before: true, function: () => navigate(`/room?roomId=${roomId}&step=1`) },
    { before: true, function: () => navigate(`/room?roomId=${roomId}&step=2`) },
    { before: true, function: () => navigate(`/room?roomId=${roomId}&step=3`) },
    { before: true, function: () => navigate(`/room?roomId=${roomId}&step=4`) },
    { before: true, function: () => navigate(`/room?roomId=${roomId}&step=5`) },
    { before: true, function: () => navigate(`/room?roomId=${roomId}&step=6`) },
    { before: true, function: () => navigate(`/room?roomId=${roomId}&step=7`) },
  ];

  useEffect(() => {
    const { backUrl, backTranslationKey } = peekBackUrl();
    setBackTranslationKey(backTranslationKey);    
    setBackUrl(backUrl);
  }, []);

  useEffect(() => {
    if (roomId !== undefined)
      loadRoomOverview(Number(roomId)).then((response) => {
        setDeflectionLimit(
          response.data.deflectionLimitString.replace("_", "/")
        );
      });
  }, []);

  useEffect(() => {
    if (
      deflectionLimit === DeflectionLimit.l300 &&
      data.possibleProducts === undefined
    ) {
      setExceededServicability(true);
    }
  }, [deflectionLimit]);

  const updateOrder = () => {
    if (data) {
      const newOrder: IUpdateSelectedProducts = {
        connectionAngle: data.selectedProducts.connectionAngle
          ? { id: data.selectedProducts.connectionAngle.id }
          : null!,
        profile: { id: data.selectedProducts.profile.id },
        wallProfile: data.selectedProducts.wallProfile
          ? { id: data.selectedProducts.wallProfile.id }
          : null!,
        room: { id: data.id },
      };
      setDisableNext(true);
      updateSelectedProductsRequest(newOrder)
        .then((response) => {
          if(isAdmin() && data.orderId){
            navigate(`/orders/${data.orderId}`);
          }
          else{
            navigate(`/room/${roomId}?mode=create`);
          }
        })
        .catch((error) => {
          // handle this error
          setDisableNext(false);
        });
    }
  };

  const stepParams: IStep<IRoomWithSelectedProducts> = {
    data: data,
    setData: setData,
    setDisableNext: setDisableNext,
    setDisablePrevious: setDisablePrevious,
    setHideNext: setHideNext,
    setHidePrevious: setHidePrevious,
    options: {
      setTitle: (title: string) => {
        setStepTitle(title);
      },
      setBackText: (text: string) => {
        setBackUrlText(text);
      },
    },
  };

  const backButtonClick = () => {
    const { backUrl } = popBackUrl();
    navigate(String(backUrl), { replace: true });
  };

  const { pushBackUrl, isOnTopOfStack } = useBackButtonHelper();
  const form: IStepForm = {
    components: [
      { component: SPStep1Component, params: stepParams },
      { component: SPStep2Component, params: stepParams },
    ],
    header: {
      title: stepTitle,
      closeAction: null!,
      hideCloseAction: true,
    },
    disableNext: disableNext,
    disablePrevious: disablePrevious,
    hideNext: hideNext,
    hidePrevious: hidePrevious,
    submit: () => {
      updateOrder();
      if (!isOnTopOfStack(`/room/${roomId}`))
        pushBackUrl(`/room/${roomId}`, "Edit project");
    },
    backText: backUrlText,
    backUrl: `/room/${roomId}`,
    hideBack: false,
    forceSubmit: false,
    stepUrls: stepUrls,
    submitText: t("Save and go to summary"),
    allowSkipTo: 2, // components.length
  };

  const findProfile = (data: IRoomWithSelectedProducts) => {
    // if we have only suggested products in list, we need to set possible poroducts to default []
    if (data?.possibleProducts === undefined) {
      data.possibleProducts = [];
    }
    if (data?.suggestedProducts === undefined) {
      data.possibleProducts = [];
    }

    let products = [...data.suggestedProducts, ...data.possibleProducts];
    for (let i = 0; i < products.length; i++) {
      if (products[i].id === data.selectedProducts?.profile.id) {
        return products[i];
      }
    }

    return null!;
  };

  useEffect(() => {
    getPossibleProducts(Number(roomId))
      .then((response) => {
        // if(response.data.projectId !== Number(projectId)) {navigate("/projects"); return}
        let responseData: IRoomWithSelectedProducts = response.data;
        if (response.data.nextStep < 8) {
          navigate(`/room?roomId=${roomId}&step=${response.data}`);
          return;
        }

        if (
          response.data.selectedProducts !== undefined &&
          response.data.selectedProducts !== null
        ) {
          responseData.selectedProducts.profile = findProfile(responseData);
        }

        setData(responseData);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.response?.status) {
          if (error.response?.status === 404) {
            // navigate('/not-found')
          } else if (error.response.status === 400) {
            // navigate(`not-found`, {replace: false})
          }
        } else {
          // setErrorMessage(t('Error, service unavailable. Please try again later.'));
        }
      })
      .then(() => {
        setLoading(false);
      });

    // eslint-disable-next-line
  }, [navigate, roomId, projectId]);

  return {
    form,
    data,
    projectId,
    roomId,
    isLoading,
    exceededServicability,
    navigate,
    backTranslationKey,
    backUrl,
    backButtonClick
  };
};

export default InitSelectProductLogic;

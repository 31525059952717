import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useRoomApiService from "../../ApiService/roomApiService";
import IRoomOverviewComponent from "../../Interfaces/roomOverviewComponent";

const InitRoomOverviewPageLogic = () => {
    const [overview, setOverview] = useState<IRoomOverviewComponent>(null!);
    const [isLoading, setLoading] = useState<boolean>(true);
    const {loadRoomOverview} = useRoomApiService()
    let { roomId } = useParams();
    let navigate = useNavigate();


    useEffect(() => {
        loadRoomOverview(Number(roomId))
            .then(res => {
                setLoading(false)
                if (res.data.nextStep < 8) navigate(`/room?roomId=${roomId}&step=${res.data.nextStep}`, { replace: true })
                else setOverview({ ...res.data, openCalc: false, hideTerms: false, hideControls: false, submitted: res.data.nextStep > 7 }) //7 number of steps
            }).catch(er => {
                setLoading(false)
                if(er.response.status === 404){
                    navigate("/not-found", {replace:true})
                }
            }
        )
        // eslint-disable-next-line
    }, [setOverview, roomId]);

    return { overview, isLoading }
}

export default InitRoomOverviewPageLogic;
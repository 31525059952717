import { t } from 'i18next';
import React from 'react'
import { TiltProtection } from '../../../../enums/tiltProtection';
import IRoomFormData from '../../../Interfaces/roomFormData';
import IStep from '../../../Interfaces/Step';
import InitCRStep7Logic from './CRStep7.logic';
import styles from '../RoomForm.module.css'
import CRStep7Visualization from './Visualization/CRStep7Visualization.component';
import { Walkability } from '../../../../enums/walkability';
import LoadingComponent from '../../Loading/Loading.component';


const CRStep7Component: React.FunctionComponent<IStep<IRoomFormData>> = (params: IStep<IRoomFormData>) => {
    const { onChange, antiTiltDisabled, isLoading} = InitCRStep7Logic(params);


    return (
        <div className={styles.container}>
            {isLoading && <LoadingComponent />}
            {!isLoading && <><div className={styles.data}>
                <h3 className={styles.title}>{t("Please select the desired top-side anti-tilt variant")}</h3>
                <input disabled={antiTiltDisabled()} value={TiltProtection.ANTITILT_ANGLE}
                    id={TiltProtection.ANTITILT_ANGLE}
                    name="tiltProtection" type="radio"
                    checked={params.data.tiltProtection === TiltProtection.ANTITILT_ANGLE}
                    onChange={onChange} />
                <label htmlFor={TiltProtection.ANTITILT_ANGLE}> {t('Anti-tilt angle on top side')} {(params.data.walkability === Walkability.LESS_200  || params.data.walkability === Walkability.OTHER) && <p className={`${styles.description} ${styles.label__description}`}>({t("Anti-tilt angles can only be used for traffic loads up to 100kg/m2")}) </p>}</label>
                <br />
                <input value={TiltProtection.STIFFENING_PLANKING}
                    id={TiltProtection.STIFFENING_PLANKING}
                    name="tiltProtection" type="radio"
                    checked={params.data.tiltProtection === TiltProtection.STIFFENING_PLANKING}
                    onChange={onChange} />
                <label htmlFor={TiltProtection.STIFFENING_PLANKING}> {t('Stiffening planking on the top side')}</label>
                {params.data.tiltProtection === TiltProtection.STIFFENING_PLANKING && <p className={styles.description}>{t("Please note that the load of planking must be specified under permanent loads.")} </p>}
                <h5 className={styles.description__title}>{t("Whats that?")}</h5>
                <p className={styles.description}>{t("Edificio wide-span beam ceilings must be secured against tipping on the top side. There are two options here. Either the wide span beams are secured against tilting by using anti-tilt angles (at a maximum center distance of 1.5 m), or stiffening board materials (e.g. OSB boards) are mounted on the upper side.")}</p>
            </div>
            <div className={styles.visualization}>
                <CRStep7Visualization {...params.data} />
                <p>{`${params.data.name}`}</p>
            </div>
            </>}
        </div>
    )
}

export default CRStep7Component;
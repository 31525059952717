import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SlideTheme } from '../../../enums/slideTheme';
import UtilsHelper from '../../../helpers/utils.helper';
import IPage from '../../../interfaces/page';
import imagesStyles from '../../../pages/Image/Images/Images.module.css';
import globalStyles from '../../../styles/Global.module.css';
import useSlideApiService from '../../../Wave3/ApiService/slideApiService';
import ModalComponent from '../../../Wave3/Components/Modal/Modal.component';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import IModal from '../../../Wave3/Interfaces/modal';
import ISlide from '../../../Wave3/Interfaces/slide';
import InputShared from '../../../Wave3/SharedComponents/InputShared/InputShared';
import loginStyles from '../../Login/Login.module.css';
import styles from '../../Login/Login.module.css';


const CreateEditSlidePage: React.FunctionComponent<IPage> = () => {
    let { id } = useParams();
    let navigate = useNavigate();

    const [preview, setPreview] = useState<string>();

    const [formData, setFormData] = useState(new FormData());

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>("");

    const [formValue, setformValue] = React.useState<ISlide>({
        id: null!,
        url: '',
        theme: SlideTheme.LIGHT,
        title: '',
        text: '',
        urlOnClick: '',
        urlOnClickText: '',
        orderNumber: 0
    });

    const [initFormValue] = useState<ISlide>({
        id: null!,
        url: '',
        theme: SlideTheme.LIGHT,
        title: '',
        text: '',
        urlOnClick: '',
        urlOnClickText: '',
        orderNumber: 0
    });

    const deleteImage = () => {
        return new Promise((resolve: any, reject: any) => {
            formValue.url = "";
            setformValue(formValue);
            setPreview("");
            formData.delete("file");
            setShowDeleteModal(false);
            resolve();
        }
        )
    }
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const deleteModal: IModal = {
        cancelAction: () => { setShowDeleteModal(false) },
        confirmAction: () => deleteImage(),
        confirmText: t("Confirm"),
        showCancel: true,
        title: t("Delete image"),
        message: t("Are you sure about this action?"),
        image: null!
    }

    const {getSlideById, updateSlide, createSlide} = useSlideApiService()

    useEffect(() => {
        if (id) {
            getSlideById(Number(id))
                .then(responseSlides => {
                    let slide = responseSlides.data;
                    Object.getOwnPropertyNames(initFormValue).forEach((property) => {
                        if (slide[property] === undefined) {
                            slide[property] = initFormValue[property as keyof typeof initFormValue];
                        }
                    })
                    setformValue(slide);
                }).catch(error => {
                    navigate(`/slides`)
                })
        }
        //eslint-disable-next-line
    }, [id, navigate, initFormValue])

    const handleChange = (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>) => {
        setformValue({
            ...formValue,
            [event.currentTarget.name]: event.currentTarget.value
        });
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setSubmitDisabled(true);
        formData.set('slideString', JSON.stringify(formValue));
        if (id) {
            updateSlide(formData)
                .then(response => {
                    navigate(`/slides`);
                })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 400) {
                            setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                        }
                        else if (error.response.status === 404) {
                            setErrorMessage(t("Slide not found"));
                        }
                        else {
                            setErrorMessage(t("Internal server error. Please try again later."));
                        }
                    }
                    else
                        setErrorMessage(t("Error, service unavailable. Please try again later."));

                    setSubmitDisabled(false);
                });
        }
        else {
            createSlide(formData)
                .then(response => {
                    navigate(`/slides`);
                })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 400) {
                            setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                        }
                        else {
                            setErrorMessage(t("Internal server error. Please try again later."));
                        }
                    }
                    else
                        setErrorMessage(t("Error, service unavailable. Please try again later."));

                    setSubmitDisabled(false);
                });
        }
    }

    const onFileChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e === undefined) {
            return;
        }
        let selectedFiles = e.target.files;
        if (selectedFiles === null) {
            return;
        }

        formData.set('file', selectedFiles[0]);
        setFormData(formData)

        setPreview(URL.createObjectURL(selectedFiles[0]));
        e.target.files = null;
        e.target.value = null!;

    };

    const deleteFunction = () => {
        setShowDeleteModal(true);
    }

    return (
        <>
                            <NavigationComponent />

            <div className={globalStyles.layout}>
                <Fragment>
                    <div className={styles.form__container}>
                        <form className={styles.form} onSubmit={handleSubmit}>
                            {errorMessage !== "" &&
                                <p className={loginStyles.error__message}>{errorMessage}</p>
                            }
                            <div className={loginStyles.form__header}>
                                <h2 className={loginStyles.form__title}>{id ? t("Edit slide") : t("Create slide")}</h2>
                                <p className={loginStyles.form__helper}><span style={{color: "#E42313"}}>*</span>{t("indicates a required field")}</p>
                            </div>

                            <InputShared name="title" labelText='Title' value={formValue.title} type="text" onChange={handleChange} />
                            <InputShared name="text" labelText='Text' value={formValue.text} type="text" onChange={handleChange} />
                            <InputShared name="urlOnClick" labelText='Link' value={formValue.urlOnClick} type="text" onChange={handleChange} />
                            <InputShared name="urlOnClickText" labelText='Link text' value={formValue.urlOnClickText} type="text" onChange={handleChange} />
                            <InputShared name="orderNumber" labelText='Order' value={formValue.orderNumber.toString()} type="number" step='1' min='0' onChange={handleChange} />


                            <div className={globalStyles.input}>
                                <label>{t("Theme")} <span aria-hidden="true">*</span></label>
                                <div>
                                    <input value={SlideTheme.LIGHT}
                                        id={SlideTheme.LIGHT}
                                        name="theme" type="radio"
                                        checked={formValue.theme === SlideTheme.LIGHT}
                                        onChange={handleChange} />
                                    <label htmlFor={SlideTheme.LIGHT}> {t('Light')}</label>
                                    <br />
                                    <input value={SlideTheme.DARK}
                                        id={SlideTheme.DARK}
                                        name="theme" type="radio"
                                        checked={formValue.theme === SlideTheme.DARK}
                                        onChange={handleChange} />
                                    <label htmlFor={SlideTheme.DARK}> {t('Dark')}</label>
                                </div>
                            </div>

                            <div className={globalStyles.input}>
                                <label>{t("Image")}</label>
                                {(formValue.url || preview) &&
                                    <div className={imagesStyles.image__container} key={0}>

                                        <button type="button" className={imagesStyles.delete__button} onClick={deleteFunction} key={`0-x`}>
                                            <FontAwesomeIcon className={imagesStyles.dropdown__icon} icon={faTrash} />
                                        </button>
                                        <img className={imagesStyles.image} src={preview ? preview : UtilsHelper.createFileUrl(formValue.url)} alt=""></img>
                                    </div>}
                                <div className={imagesStyles.dropdown__upload}>
                                    <input id="fileUploadInput" type="file" accept="image/*" name="file" onChange={onFileChangeHandler} />
                                    <label htmlFor="fileUploadInput">{t("Drag and drop or click here")}</label>
                                </div>
                            </div>

                            <div className={loginStyles.form__bottom}>
                                <Link to={`/slides`}><button type="button" className="button-secondary-link">{t("Cancel")}</button></Link>
                                <button disabled={submitDisabled} type="submit">{t("Save")}</button>
                            </div>
                        </form>
                    </div >
                    {showDeleteModal && <ModalComponent {...deleteModal}></ModalComponent>}
                </Fragment>
            </div >
        </>
    )
}

export default CreateEditSlidePage;
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ProfileType } from '../../../enums/ProfileType';
import IFyk from '../../../interfaces/fyk';
import IPage from '../../../interfaces/page';
import IProduct from '../../../interfaces/product';
import IProfileAll from '../../../interfaces/profileAll';
import globalStyles from '../../../styles/Global.module.css';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import InputShared from '../../../Wave3/SharedComponents/InputShared/InputShared';
import loginStyles from '../../Login/Login.module.css';
import styles from '../../Login/Login.module.css';
import useFykApiService from '../../../Wave3/ApiService/fykApiService';
import useConnectionAngleApiService from '../../../Wave3/ApiService/connectionAngleApiService';
import useProfileApiService from '../../../Wave3/ApiService/profileApiService';
import useWallProfileApiService from '../../../Wave3/ApiService/wallProfileApiService';

const EditProfilePage: React.FunctionComponent<IPage> = () => {
    let { id } = useParams();
    let navigate = useNavigate();

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>("");

    const [formValue, setformValue] = React.useState<IProfileAll>({
        id: null!,
        name: '',
        price: 0,
        height: 0,
        lyEffCm4: 0,
        lyFicCm4: 0,
        wyEffCm3: 0,
        strapWidth: 0,
        selectedProducts: [],
        wallProfiles: [],
        connectionAngles: [],
        fyk: null!,
        isDouble: false,
        fileInfos: [],
        profileType: ProfileType.L,
        sku:"",
        ownWeight:0
    });

    const [wallProfiles, setWallProfiles] = useState<IProduct[]>([]);
    const [checkedStateWallProfile, setCheckedStateWallProfile] = useState<boolean[]>([]);

    const [connectionAngles, setConnectionAngles] = useState<IProduct[]>([]);
    const [checkedStateConnectionAngles, setCheckedStateConnectionAngles] = useState<boolean[]>([]);

    const [fyks, setFyks] = useState<IFyk[]>([]);
    const [checkedStateFyks, setCheckedStateFyks] = useState<boolean[]>([]);

    const [isDouble, setIsDouble] = useState<boolean>(false);
    const {getAllFyks} = useFykApiService()

    const {getAllConnectionAngles} = useConnectionAngleApiService()
    const {getProfileById, updateProfile, createProfile} = useProfileApiService()
    const {getWallProfiles} = useWallProfileApiService()
    
    useEffect(() => {
        getWallProfiles()
            .then(responseWallProfiles => {
                setWallProfiles(responseWallProfiles.data.content);
                getAllConnectionAngles().then(responseConnectionAngles => {
                        setConnectionAngles(responseConnectionAngles.data.content);
                        getAllFyks().then(responseFyks => {
                                setFyks(responseFyks.data.content);
                                if (id) {
                                    getProfileById(Number(id))
                                        .then(responseProfiles => {
                                            setformValue(responseProfiles.data);
                                            setIsDouble(responseProfiles.data.isDouble);
                                            let states: boolean[] = [];
                                            responseWallProfiles.data.content?.forEach((wallProfile: IProduct) => {
                                                if (responseProfiles.data.wallProfiles?.find((e: IProduct) => e.id === wallProfile.id)) {
                                                    states.push(true);
                                                }
                                                else {
                                                    states.push(false);
                                                }
                                            });
                                            setCheckedStateWallProfile(states);
                                            states = [];
                                            responseConnectionAngles.data.content?.forEach((connectionAngle: IProduct) => {
                                                if (responseProfiles.data.connectionAngles?.find((e: IProduct) => e.id === connectionAngle.id)) {
                                                    states.push(true);
                                                }
                                                else {
                                                    states.push(false);
                                                }
                                            });
                                            setCheckedStateConnectionAngles(states);
                                            states = [];
                                            responseFyks.data.content?.forEach((fyk: IFyk) => {
                                                if (responseProfiles.data.fyk?.id === fyk.id) {
                                                    states.push(true);
                                                }
                                                else {
                                                    states.push(false);
                                                }
                                            });
                                            setCheckedStateFyks(states);
                                        }).catch(error => {
                                            navigate(`/profiles`)
                                        })
                                }
                                else {
                                    setCheckedStateWallProfile(new Array(responseWallProfiles.data.content?.length).fill(false));
                                    setCheckedStateConnectionAngles(new Array(responseConnectionAngles.data.content?.length).fill(false));
                                    let initialFyksStates = new Array(responseFyks.data.content?.length).fill(false);
                                    if (initialFyksStates.length >= 1) {
                                        initialFyksStates[0] = true;
                                    }
                                    setCheckedStateFyks(initialFyksStates);
                                }
                            }).catch(error => {
                                navigate(`/profiles`)
                            })
                    }).catch(error => {
                        navigate(`/profiles`)
                    })
            }).catch(error => {
                navigate(`/profiles`)
            })
        //eslint-disable-next-line
    }, [id, navigate])

    const handleChange = (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>) => {
        setformValue({
            ...formValue,
            [event.currentTarget.name]: event.currentTarget.value
        });
    }

    const handleChangeWallProfiles = (position: number) => {
        const updatedCheckedState = checkedStateWallProfile.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedStateWallProfile(updatedCheckedState);
    }
    const handleChangeConnectionAngles = (position: number) => {
        const updatedCheckedState = checkedStateConnectionAngles.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedStateConnectionAngles(updatedCheckedState);
    }
    const handleChangeFyks = (position: number) => {
        const updatedCheckedState = checkedStateFyks.map((item, index) =>
            index === position ? true : false
        );
        setCheckedStateFyks(updatedCheckedState);
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setSubmitDisabled(true);
        formValue.isDouble = isDouble;
        formValue.wallProfiles = [];
        formValue.connectionAngles = [];
        for (let index = 0; index < checkedStateWallProfile.length; index++) {
            if (checkedStateWallProfile[index]) {
                formValue.wallProfiles.push(wallProfiles[index]);
            }
        }
        for (let index = 0; index < checkedStateConnectionAngles.length; index++) {
            if (checkedStateConnectionAngles[index]) {
                formValue.connectionAngles.push(connectionAngles[index]);
            }
        }
        for (let index = 0; index < checkedStateFyks.length; index++) {
            if (checkedStateFyks[index]) {
                formValue.fyk = fyks[index];
            }
        }
        if (id) {
            updateProfile(formValue)
                .then(response => {
                    navigate(`/profiles`);
                })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 400) {
                            setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                        }
                        else if (error.response.status === 404) {
                            setErrorMessage(t("Profile not found"));
                        }
                        else {
                            setErrorMessage(t("Internal server error. Please try again later."));
                        }
                    }
                    else
                        setErrorMessage(t("Error, service unavailable. Please try again later."));

                    setSubmitDisabled(false);
                });
        }
        else {
            createProfile(formValue)
                .then(response => {
                    navigate(`/profiles`);
                })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 400) {
                            setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                        }
                        else {
                            setErrorMessage(t("Internal server error. Please try again later."));
                        }
                    }
                    else
                        setErrorMessage(t("Error, service unavailable. Please try again later."));

                    setSubmitDisabled(false);
                });
        }
    }

    return (
        <>
                            <NavigationComponent />

            <div className={globalStyles.layout}>
                <div className={styles.form__container}>
                    <form className={styles.form} onSubmit={handleSubmit}>
                        {errorMessage !== "" &&
                            <p className={loginStyles.error__message}>{errorMessage}</p>
                        }
                        <div className={loginStyles.form__header}>
                            <h2 className={loginStyles.form__title}>{id ? t("Edit profile") : t("Create profile")}</h2>
                            <p className={loginStyles.form__helper}><span style={{color: "#E42313"}}>*</span>{t("indicates a required field")}</p>
                        </div>
                        
                        <InputShared name="name" labelText='Name' type="text" value={formValue.name} onChange={handleChange} required={true}/>
                        <InputShared name='sku' labelText="SKU" value={formValue.sku} type="text" required={true} onChange={handleChange} />
                        <InputShared name='height' labelText="Height" value={formValue.height.toString()} type="number" step=".01" min="0" required={true} onChange={handleChange} />
                        <InputShared name="price" labelText="Price" value={formValue.price.toString()} type="number" step=".01" min="0" required={true} onChange={handleChange} />
                        <InputShared name="lyEffCm3" labelText="lyEffCm4" value={formValue.lyEffCm4.toString()} type="number" step=".01" min="0" required={true} onChange={handleChange} />
                        <InputShared name="lyFicCm3" labelText='lyFicCm4' value={formValue.lyFicCm4.toString()} type="number" step='.01' min='0' required={true} onChange={handleChange} />
                        <InputShared name="wyEffCm3" labelText='wyEffCm3' value={formValue.wyEffCm3.toString()} type="number" step=".01" min="0" required={true} onChange={handleChange} />
                        <InputShared name='strapWidth' labelText='Strap width' value={formValue.strapWidth.toString()} type="number" step=".01" min="0" required={true} onChange={handleChange} />
                        <InputShared name='ownWeight' labelText='Own weight' value={formValue.ownWeight.toString()} type="number" step=".01" min="0" required={true} onChange={handleChange} />

                        <div className={globalStyles.input}>
                            <div>
                                <input
                                    id="isDouble"
                                    name="isDouble"
                                    type="checkbox"
                                    onChange={() => setIsDouble(!isDouble)}
                                    checked={isDouble}
                                />
                                <label htmlFor="isDouble">{t("Is double")}</label>
                            </div>
                        </div>
                        <div className={globalStyles.input}>
                            <label>{t("Wall connection profile")}</label>
                            {wallProfiles !== undefined && wallProfiles.map((wallProfile, index) => {
                                return (
                                    <div key={`custom-div-${index}`}>
                                        <input
                                            type="checkbox"
                                            id={`custom-checkbox-${index}-wp`}
                                            key={`custom-checkbox-${index}-wp`}
                                            name={wallProfile.name}
                                            value={wallProfile.name}
                                            checked={checkedStateWallProfile[index] ? checkedStateWallProfile[index] : false}
                                            onChange={() => handleChangeWallProfiles(index)}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}-wp`}>{wallProfile.name}</label>
                                    </div>
                                );
                            })}
                            {(wallProfiles === undefined || wallProfiles.length === 0) &&
                                <p>{t("There is no wall profiles")}</p>
                            }
                        </div>
                        <div className={globalStyles.input}>
                            <label>{t("Connection angles")}</label>
                            {connectionAngles !== undefined && connectionAngles.map((connectionAngle, index) => {
                                return (
                                    <div key={`custom-div-${index}`}>
                                        <input
                                            type="checkbox"
                                            id={`custom-checkbox-${index}-ca`}
                                            key={`custom-checkbox-${index}-ca`}
                                            name={connectionAngle.name}
                                            value={connectionAngle.name}
                                            checked={checkedStateConnectionAngles[index] ? checkedStateConnectionAngles[index] : false}
                                            onChange={() => handleChangeConnectionAngles(index)}
                                        />
                                        <label htmlFor={`custom-checkbox-${index}-ca`}>{connectionAngle.name}</label>
                                    </div>
                                );
                            })}
                            {(connectionAngles === undefined || connectionAngles.length === 0) &&
                                <p>{t("There is no connection angles")}</p>
                            }
                        </div>
                        <div className={globalStyles.input}>
                            <label>{t("Fyk")}</label>
                            {fyks !== undefined && fyks.map((fyk, index) => {
                                return (
                                    <div key={`custom-div-${index}`}>
                                        <input
                                            type="radio"
                                            id={`custom-radio-${index}`}
                                            key={`custom-radio-${index}`}
                                            name={fyk.id.toString()}
                                            value={fyk.value}
                                            checked={checkedStateFyks[index] ? checkedStateFyks[index] : false}
                                            onChange={() => handleChangeFyks(index)}
                                        />
                                        <label htmlFor={`custom-radio-${index}`}>{fyk.value}</label>
                                    </div>
                                );
                            })}
                            {(fyks === undefined || fyks.length === 0) &&
                                <p>{t("There is no fyks")}</p>
                            }
                        </div>
                        <div className={globalStyles.input}>
                            <label>{t("Type")}  <span aria-hidden="true">*</span></label>
                            <div>
                                <input value={ProfileType.L}
                                    id={ProfileType.L}
                                    name="profileType" type="radio"
                                    checked={formValue.profileType === ProfileType.L}
                                    onChange={handleChange} />
                                <label htmlFor={ProfileType.L}> {t('L')}</label>
                                <br />
                                <input value={ProfileType.C}
                                    id={ProfileType.C}
                                    name="profileType" type="radio"
                                    checked={formValue.profileType === ProfileType.C}
                                    onChange={handleChange} />
                                <label htmlFor={ProfileType.C}> {t('C')}</label>
                                <br />
                                <input value={ProfileType.U}
                                    id={ProfileType.U}
                                    name="profileType" type="radio"
                                    checked={formValue.profileType === ProfileType.U}
                                    onChange={handleChange} />
                                <label htmlFor={ProfileType.U}> {t("U")}</label>
                            </div>
                        </div>
                        {id && <Link to={`/images/profile/${id}`}><button type="button" className="button-secondary">{t("Images")}</button></Link>}

                        <div className={loginStyles.form__bottom}>
                            <Link to={`/profiles`}><button type="button" className="button-secondary-link">{t("Cancel")}</button></Link>
                            <button disabled={submitDisabled} type="submit">{t("Save")}</button>
                        </div>
                    </form>
                </div >
            </div >
        </>
    )
}

export default EditProfilePage;
import IRoomOverview from "../../../Interfaces/roomOverview";
import ProductOverviewComponent from "./ProductOverview.component";

const SupportBracketOverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <>
            <ProductOverviewComponent name="PROTEKTOR Anschlusswinkel (für UA 50) " calculationResult={undefined!} fileInfos={[{url: params.supportBracketsAssetPath ?? ""}]} id={undefined!} />
        </>
    )
}

export default SupportBracketOverviewComponent;
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useRoomApiService from "../../ApiService/roomApiService";
import useBackButtonHelper from "../../helpers/backButtonHelper";
import IStatistics from "../../Interfaces/statistics";
import styles from "./Dashboard.module.css";

const InitDashboardLogic = () => {
    const { getStatistics } = useRoomApiService();
    const [statistics, setStatistics] = useState<IStatistics>(null!);
    const [search, setSearch] = useState<string>('');
    const {clearBackUrls, pushBackUrl} = useBackButtonHelper()
    let navigate = useNavigate();

    const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
        setSearch(event.currentTarget.value);
    }

    const handleSearch = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (search !== "")
            navigate(`/projects?s=${search}`);
    }
    useEffect(() => {
        document.body.classList.add(styles.background)
        getStatistics().then(r => { setStatistics(r.data) })

        clearBackUrls()
        pushBackUrl("/dashboard", "Back to dashboard")
        return () => {
            document.body.classList.remove(styles.background)
        }
    }, [])
    
    return { statistics, handleChange, handleSearch }
}


export default InitDashboardLogic;
import IRoomOverview from "../../../Interfaces/roomOverview";
import ProductOverviewComponent from "./ProductOverview.component";

const ProfileOverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <>
            <ProductOverviewComponent {...params.selectedProducts.profile} />
        </>
    )
}

export default ProfileOverviewComponent;
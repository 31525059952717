import i18next, { t } from "i18next";
import { useEffect } from "react";
import IProduct from "../../../../interfaces/product";
import ISelectedProductsRoom from "../../../Interfaces/roomWithSelectedProducts";
import IStep from "../../../Interfaces/Step";

const InitSPStep2Logic = (params: IStep<ISelectedProductsRoom>) => {

    const changeShowed = (productToShow: string) => {
        params.setData({ ...params.data, showedProducts: productToShow })
    }
    const getActive=  () => {
        if(params.data.showedProducts === undefined){
            if(params.data.selectedProducts.connectionAngle) return "right"
            if(params.data.selectedProducts.wallProfile)  return "left"
        }

        if(params.data.showedProducts === "angles") return "right"

        return "left"
    }

    const isWPActive = (product: IProduct) => {
        return product.id === params.data.selectedProducts?.wallProfile?.id;
    }
    const isCAActive = (product: IProduct) => {
        return product.id === params.data.selectedProducts?.connectionAngle?.id;
    }

    const selectWP = (product: IProduct) => {
        let data = { ...params.data };
        data.selectedProducts.wallProfile = product;
        data.selectedProducts.connectionAngle = null!;
        params.setData(data);
    }

    const selectCA = (product: IProduct) => {
        let data = { ...params.data };
        data.selectedProducts.connectionAngle = product;
        data.selectedProducts.wallProfile = null!;
        params.setData(data);
    }

    useEffect(()=>{
        params.options.setTitle(t('Select wall connection: choose between a continuous wall connection profile or individual connection angles'));
        // params.options.setBackText(t("Or back to project"))
    })

    useEffect(() => {
      }, [i18next.language])

    return { isWPActive, isCAActive, selectWP, selectCA, changeShowed, getActive };
}


export default InitSPStep2Logic;
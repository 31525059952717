import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import IFullUser from "../../../interfaces/fullUser";
import tokenHelper from "../../helpers/tokenHelper";
import { useSwipeable } from "react-swipeable";
import useUserApiService from "../../ApiService/userApiService";
import useUserHelper from "../../helpers/userHelper";
import i18n from "i18next";
import useRoomApiService from "../../ApiService/roomApiService";
import { useCartStore } from "../../Stores/cart.store";
import { useMainLayoutStore } from "../../Stores/mainLayout.store";

const InitNavigationLogic = () => {
  const { setNavigationHeight } = useMainLayoutStore();
  const navigate = useNavigate();
  const [show, setShow] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isAdmin, setAdmin] = useState<boolean>(false);
  const { getUserProfile } = useUserApiService();
  const { storeUser, retrieveUser, deleteUser } = useUserHelper();
  const projectsInCart = useCartStore((state) => state.projectsInCart);
  const setProjectsInCart = useCartStore((state) => state.setProjectsInCart);
  const setProjectsInTrash = useCartStore((state) => state.setProjectsInTrash);
  const { getInCartCount } = useRoomApiService();
  const navigationRef = useRef<any>(null!);

  const openSwipeNavhandler = useSwipeable({
    onSwipedLeft: () => {
      setOpen(true);
    },
  });

  const closeSwipeNavhandler = useSwipeable({
    onSwipedRight: () => {
      setOpen(false);
    },
  });

  const [user, setUser] = useState<IFullUser>(null!);

  const toggleMenu = () => {
    setOpen(!open);
  };

  const logout = () => {
    deleteUser();
    tokenHelper.removeToken();
    navigate("/");
  };

  useEffect(() => {
    if (tokenHelper.getToken() !== null) {
      let user = retrieveUser();
      if (user === null || user.enabled === false) {
        getUserProfile()
          .then((response) => {
            setUser(response.data);
            i18n.changeLanguage(response.data.language.toUpperCase());
            for (let i = 0; i < response.data.userPermissions.length; i++) {
              if (
                response.data.userPermissions[i].permissionTitle ===
                "ROLE_ADMIN"
              ) {
                setAdmin(true);
              }
            }
            storeUser(response.data);
            setShow(true);
          })
          .catch((e) => {
            setShow(false);
          });
      } else {
        setUser(user);
        setShow(true);
        for (let i = 0; i < user.userPermissions.length; i++) {
          if (user.userPermissions[i].permissionTitle === "ROLE_ADMIN") {
            setAdmin(true);
          }
        }
      }
    } else {
      setShow(false);
    }

    if (projectsInCart === 0)
      getInCartCount().then((response) => {
        setProjectsInCart(response.data.inCartCount);
        setProjectsInTrash(response.data.inTrashCount)
      });
    // eslint-disable-next-line
  }, []);

  const onResize = () => {
    setNavigationHeight(
      navigationRef.current ? navigationRef.current?.offsetHeight : 0
    );
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [show]);

  return {
    navigationRef,
    show,
    toggleMenu,
    user,
    open,
    logout,
    isAdmin,
    openSwipeNavhandler,
    closeSwipeNavhandler,
    projectsInCart,
  };
};

export default InitNavigationLogic;

import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import Application from './App';
import './translation/i18n'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';


ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="279565789227-vdgdbeas8esvaq62dfm1nahkf0k8efus.apps.googleusercontent.com" >
      <BrowserRouter>
        <Application />
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
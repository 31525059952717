import { useEffect, useRef, useState } from "react";
import useOrderApiService from "../../ApiService/orderApiService";
import IOrder from "../../Interfaces/order";
import { useMainLayoutStore } from "../../Stores/mainLayout.store";
import { useSearchParams } from "react-router-dom";
import { OrderCategory } from "../../../enums/orderCategory";

const InitOrdersComponentLogic = () => {
  const { contentHeight } = useMainLayoutStore();

  const [page, setPage] = useState(0);
  const [next, setNext] = useState(false);
  const [orders, setOrders] = useState<IOrder[]>(null!);
  const [loadingOrders, setLoadingOrders] = useState<boolean>(true);
  const scrollParentRef = useRef<HTMLDivElement>(null!);
  const { getAll } = useOrderApiService();
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderCategory, setOrderCategory] = useState<OrderCategory>(
    OrderCategory.PROCESSING
  );

  useEffect(() => {
    let category = OrderCategory.ALL;
    if (searchParams.get("status")) {
      const status = searchParams.get("status");
      if (status === OrderCategory.HISTORY) {
        category = OrderCategory.HISTORY;
        setOrderCategory(OrderCategory.HISTORY);
      } else if (status === OrderCategory.PROCESSING)
        category = OrderCategory.PROCESSING;
    }
    setLoadingOrders(true);
    getAll(page, category).then((r) => {
      if (!r.data.empty) {
        setOrders(r.data.content);
      }
      setLoadingOrders(false);
      setNext(!r.data.last);
    });
  }, []);

  const loadMore = (page: any) => {
    let category = OrderCategory.ALL;
    if (searchParams.get("status")) {
      const status = searchParams.get("status");
      if (status === OrderCategory.HISTORY) category = OrderCategory.HISTORY;
      else if (status === OrderCategory.PROCESSING)
        category = OrderCategory.PROCESSING;
    }
    setNext(false);
    getAll(page, category).then((r) => {
      setOrders([...orders, ...r.data.content]);
      setNext(!r.data.last);
    });
    setPage(page);
  };
  
  const formatDate = (date: Date) => {
    let nd = new Date(date);
    return `${nd.getDate()}.${nd.getMonth() + 1}.${nd.getFullYear()}.`;
  };

  return {
    orders,
    loadingOrders,
    next,
    scrollParentRef,
    contentHeight,
    formatDate,
    loadMore,
    orderCategory,
  };
};

export default InitOrdersComponentLogic;

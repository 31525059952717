import { t } from "i18next";
import { useEffect, useState } from "react"
import dataHandlerHelper from "../../helpers/dataHandler.helper";
import INotificationModal from "../../Interfaces/notificationModal";
import passwordIcon from '../../../assets/images/wave3/password-changed.svg'
import { useNavigate } from "react-router-dom";
import tokenHelper from "../../helpers/tokenHelper";
import IPassword from "../../../interfaces/password";
import useUserApiService from "../../ApiService/userApiService";

const InitUserUpdatePasswordFormLogic = () => {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const [external, setExternal] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const {updatePasswordForGoogleUser, getExternalUser} = useUserApiService()

    const defaultData = {
        currentPassword: "",
        newPassword: "",
        repeatPassword: ""
    }
    const [data, setData] = useState<IPassword>(defaultData);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const navigate = useNavigate();
    const [presentNotificationModal, setPresentNotificationModal] = useState<boolean>(false);
    const notificationModal: INotificationModal = {
        action: () => { setPresentNotificationModal(false); setErrorMessage("");  setData(defaultData); window.location.reload()},
        close: () => { setPresentNotificationModal(false); setErrorMessage("");  setData(defaultData); window.location.reload()},
        actionText: t('Ok'),
        description: t('Your password has been changed'),
        icon: passwordIcon,
        title: t("Password changed successfully")
    }

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        let newData = dataHandlerHelper.handleInputChange(event, { ...data });
        setData(newData);
    }


    const onSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        if (isSubmitDisabled) {
            return;
        }

        if (data.newPassword !== data.repeatPassword) {
            setErrorMessage(t('Passwords do not match.'));
            setSubmitDisabled(false);
            return null;
        }
        setSubmitDisabled(true);
        
        updatePasswordForGoogleUser(data, external).then(response => {
            setPresentNotificationModal(true);
            setSubmitDisabled(false);
            setData(defaultData)
       })
       .catch(error => {
           if (error.response?.status)
               setErrorMessage(t(error.response?.data.translatableCode, { ns:'codes' }));
           else
               setErrorMessage(t('Error, service unavailable. Please try again later.'));
           setSubmitDisabled(false);

       });
    }


    useEffect(() => {
        if (tokenHelper.getToken() !== undefined) {
            getExternalUser().then((response) => {
                setExternal(true);
            })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 404) {
                            tokenHelper.removeToken();
                            navigate('/')
                        }
                        else if (error.response.status === 401) {
                            setExternal(false);
                        }
                    }
                    else
                        setErrorMessage(t('Error, service unavailable. Please try again later.'));
                });
        }

        //eslint-disable-next-line
    }, [navigate]);

    return { data, errorMessage, external, notificationModal, isSubmitDisabled, presentNotificationModal, onSubmit, onChange, toggleShowPassword, showPassword }
}

export default InitUserUpdatePasswordFormLogic;
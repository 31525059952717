import { useEffect, useState } from 'react';
import IModal from '../../Interfaces/modal';

const InitModalLogic = (modal: IModal) => {

    const [isLoading, setLoading] = useState<boolean>(false);

    const confirm = async() => {
        setLoading(true);
        await modal.confirmAction();
        setLoading(false);
    }

    useEffect(()=>{
        document.body.style.overflowY = "hidden";
        return ()=>{
            document.body.style.overflowY = "";
        }
    })
	return { isLoading, confirm }
}

export default InitModalLogic;
//resenje za brisanje itema iz korpe(modal confirm button nije radio pre ovoga)
// const InitModalLogic = (modal: IModal) => {
//     const [isLoading, setLoading] = useState<boolean>(false);
    
//     const confirm = () => {
//         // console.log('Modal confirm props:', modal);
//         // console.log('Confirm action:', modal.confirmAction);
//         //console.log(modal.confirmAction)
        
//         setLoading(true);
//         try {
//             // if (modal.confirmAction) {
//                 modal.confirmAction();
//                 setLoading(false);
//             // } else {
//             //     console.error('confirmAction is not a function', modal.confirmAction);
//             // }
//         } catch (err) {
//             setLoading(false);
//             console.error('Confirm action error:', err);
//         }
//     }
    
//     useEffect(()=>{
//         document.body.style.overflowY = "hidden";
//         return ()=>{
//             document.body.style.overflowY = "";
//         }
//     })
    
//     return { isLoading, confirm }
// }

// export default InitModalLogic;
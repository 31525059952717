import { t } from "i18next";
import { useSearchParams } from "react-router-dom";
import IRoomFormData from "../Interfaces/roomFormData";
import IStatistics from "../Interfaces/statistics";
import { clientInstance } from "./clientInstance";
import IUpdateSelectedProducts from "../Interfaces/updateSelectedProducts";
import { useCartStore } from "../Stores/cart.store";

const useRoomApiService = () => {
  const setProjectsInCart = useCartStore((state) => state.setProjectsInCart);
  const setProjectsInTrash = useCartStore((state) => state.setProjectsInTrash);
  let [searchParams, setSearchParams] = useSearchParams();
  const client = clientInstance();

  const getProjects = (
    status: string,
    page: number = 0,
    rowsPerPage: number = 6,
    sort: string = "created",
    ascendingSort: boolean = false
  ) =>
    client.get<any>(`/room/get`, {
      params: {
        page: page,
        status: status,
        size: rowsPerPage,
        sort: `${sort},${ascendingSort ? "asc" : "desc"}`,
      },
    });

  const searchProjects = (
    page: number = 0,
    rowsPerPage: number = 6,
    sort: string = "created",
    ascendingSort: boolean = false,
    searchValue: string
  ) =>
    client.post<any>(
      `/room/search`,
      { criterion: searchValue },
      {
        params: {
          page: page,
          size: rowsPerPage,
          sort: `${sort},${ascendingSort ? "asc" : "desc"}`,
        },
      }
    );

  const deleteRoomRequest = (id: number) =>
    client.delete<any>(`/room/delete/${id}`);

  const loadRoomOverview = (id: number) => client.get(`/room/overview/${id}`);

  const getCalculationOverview = (id: number) =>
    client.get(`/room/calc-results/${id}`);

  const getPossibleProducts = (id: number) =>
    client.get(`/room/get/${id}/possible-profiles`);

  const submitRoom = (data: IRoomFormData) => client.put(`/room/update`, data);

  const getInCartCount = () => client.get("/room/cart/count");

  const addRoomToCart = (id: number) => client.post(`/room/cart/${id}`);

  const recoverProject = (id: number) => client.post(`/room/recover/${id}`);

  const removeRoomFromCart = (id: number) => client.delete(`/room/cart/${id}`);

  const createRoom = (
    data: IRoomFormData,
    setData: Function,
    setLoading: Function
  ) => {
    client
      .post(`/room/create`, data)
      .then((response) => {
        setData({ ...data, id: response.data.id });
        searchParams.set("roomId", response.data.id);
        setSearchParams(searchParams);
        return response.data.id;
      })
      .catch((error) => {
        if (error.response?.status) {
          if (error.response.status === 400) {
            console.log(
              t(error.response?.data.translatableCode, { ns: "codes" })
            );
          } else {
            console.log(t("Internal server error. Please try again later."));
          }
        } else
          console.log(t("Error, service unavailable. Please try again later."));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateRoom = (
    data: IRoomFormData,
    setLoading: Function,
    setAllowSkipTo: Function,
    setData: Function
  ) => {
    if (data.id === -1) {
      setLoading(false);
      return;
    }
    client
      .put(`/room/update`, data)
      .then((response) => {
        setAllowSkipTo(response.data.nextStep);
        setData({ ...data, constantLoads: response.data.constantLoads ?? [] });
        setLoading(false);
        getInCartCount().then((response) => {
          setProjectsInCart(response.data.inCartCount);
          setProjectsInTrash(response.data.inTrashCount);
        });
      })
      .catch((error) => {
        if (error.response?.status) {
          if (error.response.status === 400) {
            console.log(
              t(error.response?.data.translatableCode, { ns: "codes" })
            );
          } else {
            console.log(t("Internal server error. Please try again later."));
          }
        } else
          console.log(t("Error, service unavailable. Please try again later."));

        setLoading(false);
      });
  };

  const updateSelectedProductsRequest = (newOrder: IUpdateSelectedProducts) =>
    client.post(`/room/select-products`, newOrder);

  const getStatistics = () => client.get<IStatistics>(`/room/statistics`);

  return {
    createRoom,
    getCalculationOverview,
    updateRoom,
    deleteRoomRequest,
    loadRoomOverview,
    getPossibleProducts,
    submitRoom,
    updateSelectedProductsRequest,
    getStatistics,
    getProjects,
    searchProjects,
    addRoomToCart,
    removeRoomFromCart,
    getInCartCount,
    recoverProject,
  };
};

export default useRoomApiService;

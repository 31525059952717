import { useCallback, useEffect, useState } from "react";
import { PartitionWallLoad } from "../../../../enums/partitionWallLoad";
import useRoomApiService from "../../../ApiService/roomApiService";
import useBackButtonHelper from "../../../helpers/backButtonHelper";
import dataHandlerHelper from "../../../helpers/dataHandler.helper";
import IConstantLoad from "../../../Interfaces/constantLoad";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IStep from "../../../Interfaces/Step";

const InitCRStep6Logic = (params: IStep<IRoomFormData>) => {
  const [additionalLoad, setAdditionalLoad] = useState<boolean>(
    params.data.partitionWallLoad !== null &&
    params.data.partitionWallLoad !== undefined
  );
  const [cl, setCl] = useState<IConstantLoad[]>([...params.data.constantLoads]);
  const { updateRoom } = useRoomApiService();
  const { resetBackButtonToDashboardState } = useBackButtonHelper();
  const [invalidLoadIndexes, setInvalidLoadIndexes] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);


  useEffect(() => {
    if (params.setShouldSetBackButton) params.setShouldSetBackButton(false);

    addNew();
    let newData = {
      ...params.data,
      nextStep: params.data.nextStep > 6 ? params.data.nextStep : 6,
    };
    updateRoom(
      newData,
      setIsLoading,
      params.options.setAllowSkipTo,
      params.setData
    );


    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    var invalid = [];
    for (var i = 0; i < cl.length; i++) {
      if (cl[i].value !== null! && cl[i].description?.length === 0) {
        invalid.push(i)
      }
    }


    setInvalidLoadIndexes(invalid);
    params.setDisableNext(invalid.length>0, 5);
  }, [cl]);




  const addNew = useCallback(() => {
    setCl(current => [...current, { id: null!, description: "", value: null! }]);
  }, [cl, setCl]);



  const onChange = (
    event: React.FormEvent<HTMLInputElement>,
    object: IConstantLoad
  ) => {
    if (event.currentTarget.name === 'value') {
      if (Number(event.currentTarget.value) > 1000000000) event.currentTarget.value = "1000000000";
      if (Number(event.currentTarget.value) < 0) event.currentTarget.value = "0";
    }

    let clCopy = [...cl];
    let index = clCopy.indexOf(object);
    clCopy[index] = dataHandlerHelper.handleInputChange(event, clCopy[index]);
    setCl(clCopy);

    if (event.currentTarget.name === "value" && index === clCopy.length - 1) {
      addNew();
    }

    let dataCopy = { ...params.data, nextStep: 6 };
    dataCopy.constantLoads = clCopy.filter(
      (cl) => cl?.value > 0 && cl.description.length !== 0
    );
    params.options.setAllowSkipTo(6);
    params.setData(dataCopy);
    if (params.setShouldSetBackButton)
      resetBackButtonToDashboardState(params.setShouldSetBackButton);
  };

  const deleteItem = (object: IConstantLoad) => {
    let clCopy = [...cl];
    let index = clCopy.indexOf(object);
    clCopy.splice(index, 1);

    if (clCopy.length < 1 || clCopy[clCopy.length - 1]?.value !== null!) {
      clCopy.push({ id: null!, description: "", value: null! });
    }
    setCl(clCopy);

    let dataCopy = { ...params.data, nextStep: 6 };
    dataCopy.constantLoads = clCopy.filter((cl) => cl.value !== null);
    params.options.setAllowSkipTo(6);
    params.setData(dataCopy);
    if (params.setShouldSetBackButton)
      resetBackButtonToDashboardState(params.setShouldSetBackButton);
  };

  const onPartitionWallChange = (event: React.FormEvent<HTMLInputElement>) => {
    setAdditionalLoad(event.currentTarget.checked);

    params.setData({
      ...params.data,
      partitionWallLoad: !event.currentTarget.checked ? null! : PartitionWallLoad.LESS_OR_EQUAL_3,
      nextStep: 6,
    });

    if (params.setShouldSetBackButton)
      resetBackButtonToDashboardState(params.setShouldSetBackButton);
  };

  const onPartitionLoadChange = (event: React.FormEvent<HTMLInputElement>) => {
    let newData: IRoomFormData = dataHandlerHelper.handleInputChange(
      event,
      params.data
    );
    params.setData({ ...newData, nextStep: 6 });

    if (params.setShouldSetBackButton)
      resetBackButtonToDashboardState(params.setShouldSetBackButton);
  };

  return {
    onChange,
    onPartitionLoadChange,
    deleteItem,
    onPartitionWallChange,
    additionalLoad,
    cl,
    isLoading,
    invalidLoadIndexes,
  };
};

export default InitCRStep6Logic;

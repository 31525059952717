import { t } from "i18next"
import CookieModal from "../CookieModal/CookieModal.component"
import InitFooterLogic from "./Footer.logic"
import styles from "./Footer.module.css"

const FooterComponent: React.FC = () => {
    const { footerRef, showCookieModal, setShowCookieModal, cookieModal } = InitFooterLogic()

    return (
        <>
            <div className={styles.footer} id="footer" ref={footerRef}>
                <ul>
                    <li>{`© ${new Date().getFullYear()} PROTEKTOR`}</li>
                    <li><a href="https://myedificio.de/contact" target="_blank" rel="noreferrer">{t("Contact")}</a></li>
                    <li><a href="https://myedificio.de/imprint" target="_blank" rel="noreferrer">{t("Imprint")}</a></li>
                    <li><a href={t("gtUrl")} target="_blank" rel="noreferrer">{t("GTC")}</a></li>
                    <li><a href={t("dUrl")} target="_blank" rel="noreferrer">{t("Disclaimer")}</a></li>
                    <li><a href="https://myedificio.de/terms-of-use" target="_blank" rel="noreferrer">{t("Terms of use")}</a></li>
                    <li><a href="https://myedificio.de/data-protection" target="_blank" rel="noreferrer">{t("Data protection")}</a></li>
                    <li><a href={t('ppUrl')} target="_blank" rel="noreferrer">{t("Privacy Policy")}</a></li>
                </ul>
            </div>
            {showCookieModal && <CookieModal {...cookieModal} />}
        </>
    )
}

export default FooterComponent
import { useEffect, useRef, useState } from "react";
import useOrderApiService from "../../ApiService/orderApiService";
import IOrder from "../../Interfaces/order";
import { useMainLayoutStore } from "../../Stores/mainLayout.store";
import { OrderStatus } from "../../../enums/orderStatus";

const InitAdminOrdersLogic = () => {
    const { adminGetByStatus } = useOrderApiService();
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [status, setStatus] = useState<OrderStatus | ''>('');
    const [page, setPage] = useState(0);
    const [searchCode, setSearchCode] = useState<String>('');
    const [isLoading, setLoading] = useState<boolean>(true);
    const scrollParentRef = useRef<HTMLDivElement>(null!);
    const [next, setNext] = useState(false);
    const { contentHeight } = useMainLayoutStore();


    const loadMore = (newPage: any) => {
        setLoading(true);
        setNext(false)
        adminGetByStatus(newPage, status, searchCode).then(r => {
            if (!r.data.empty)
                setOrders([...orders, ...r.data.content]);
            setNext(!r.data.last)
            setLoading(false)
        })
        setPage(newPage);
    }



    useEffect(() => {
        setLoading(true);
        setNext(false)
        adminGetByStatus(page, status, searchCode).then(r => {
            if (!r.data.empty)
                setOrders([...orders, ...r.data.content]);
            setNext(!r.data.last)
            setLoading(false)
        })
    }, [])

    const getByStatus = (newStatus: OrderStatus | '' = '') => {
        if (status === newStatus || isLoading) {
            return;
        }
        setLoading(true);
        setStatus(newStatus);
        setPage(0);
        setOrders([])
        setSearchCode('');
        adminGetByStatus(0, newStatus, '').then(r => {
            if (!r.data.empty)
                setOrders(r.data.content);
            setNext(!r.data.last)
            setLoading(false);
        })
    }

    const onSearchInputChanged = (event: React.FormEvent<HTMLInputElement>) => {
        setSearchCode(event.currentTarget.value);
    }

    const search = () => {
        if (isLoading) {
            return;
        }
        setLoading(true);
        setStatus('')
        setPage(0);
        setOrders([])
        adminGetByStatus(0, '', searchCode).then(r => {
            if (!r.data.empty)
                setOrders(r.data.content);
            setNext(!r.data.last)
            setLoading(false);
        })
    }

    return {
        scrollParentRef,
        next,
        orders,
        contentHeight,
        status,
        searchCode,
        onSearchInputChanged,
        loadMore,
        getByStatus,
        search,
    }
}

export default InitAdminOrdersLogic;
import ISelect from "../../Interfaces/select";
import InitSelectLogic from "./Select.logic";
import styles from './Select.module.css'
import arrowDown from '../../../assets/images/wave3/arrow-down-select.svg'
import { t } from "i18next";

const SelectComponent: React.FunctionComponent<ISelect> = (props: ISelect) => {
    const { setOption, selectedOption } = InitSelectLogic(props);
    return (
        <div className={styles.select__container}>
            <div className={styles.select} tabIndex={0}>
                {selectedOption !== null && <div>{t(selectedOption.text)}</div>}
                {selectedOption === null && <div className={styles.placeholder}>{props.defaultText}</div>}
                <img  className={styles.select__icon} src={arrowDown} alt="arrow down" />
            </div>
            <div className={styles.options} >
                {props.options
                    .map((option, index) =>
                        <div className={styles.option} key={index} onClick={() => { setOption(option) }}>{option.text}</div>)}
            </div>
        </div>
    )
}

export default SelectComponent;
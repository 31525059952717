import { t } from 'i18next';
import React from 'react'
import { Walkability } from '../../../../enums/walkability';
import IRoomFormData from '../../../Interfaces/roomFormData';
import IStep from '../../../Interfaces/Step';
import InitCRStep5Logic from './CRStep5.logic';
import styles from '../RoomForm.module.css'
import componentStyle from './CRStep5.module.css'
import CRStep5Visualization from './Visualization/CRStep5Visualization.component';
import LoadingComponent from '../../Loading/Loading.component';

const CRStep5Component: React.FunctionComponent<IStep<IRoomFormData>> = (params: IStep<IRoomFormData>) => {
    const { onChange, isLoading } = InitCRStep5Logic(params);


    return (
        <div className={styles.container}>
            {isLoading && <LoadingComponent />}
            {!isLoading && <><div className={styles.data}>
                <h3 className={styles.title}>{t("Please enter the traffic loads")}</h3>
                <input value={Walkability.LESS_100}
                    id={Walkability.LESS_100}
                    name="walkability" type="radio"
                    checked={params.data.walkability === Walkability.LESS_100}
                    onChange={onChange} />
                <label htmlFor={Walkability.LESS_100}> {t('Walkable (< 100 kg/m2)')}</label>
                <br />
                <input value={Walkability.LESS_200}
                    id={Walkability.LESS_200}
                    name="walkability" type="radio"
                    checked={params.data.walkability === Walkability.LESS_200}
                    onChange={onChange} />
                <label htmlFor={Walkability.LESS_200}> {t('Walkable (< 200 kg/m2)')}</label>
                <br />
                <input value={Walkability.NOT_WALKABLE}
                    id={Walkability.NOT_WALKABLE}
                    name="walkability" type="radio"
                    checked={params.data.walkability === Walkability.NOT_WALKABLE}
                    onChange={onChange} />
                <label htmlFor={Walkability.NOT_WALKABLE}> {t("Not walkable")}</label>
                <br />
                <input value={Walkability.OTHER}
                    id={Walkability.OTHER}
                    name="walkability" type="radio"
                    checked={params.data.walkability === Walkability.OTHER}
                    onChange={onChange} />
                <label htmlFor={Walkability.OTHER}> {t("Other traffic loads")}</label>
                {params.data.walkability === Walkability.OTHER &&
                    <div className={componentStyle.other__load}>
                        <label className={`${styles.label} ${styles.ml10}`} htmlFor="traficLoads">{t("Please enter other trafic load")} <span aria-hidden="true">*</span></label>
                        {/* <div className={`${styles.input__value}`}> */}
                        <div className={` ${styles.input__value__traffic_description}`}>
                            <input value={params.data.traficLoadsDescription}
                                name="traficLoadsDescription"
                                id='traficLoadsDescription'
                                type="text"
                                required
                                onChange={onChange}
                                placeholder={t("Description")}
                                className={styles.input} />
                        </div>
                        {/* </div> */}

                        <div className={`${styles.input__value}`}>
                            <input value={params.data.traficLoads.toString()}
                                name="traficLoads"
                                id='traficLoads'
                                type="number"
                                step='0.01'
                                min={0}
                                required
                                onChange={onChange}
                                placeholder={t("Traffic loads value")}
                                className={styles.input} />
                            <div className={styles.value__description}>
                                <div>kg/m<sup>2</sup></div>
                            </div>
                        </div>
                    </div>
                }

                <h5 className={styles.description__title}>{t("Whats that?")}</h5>
                <p className={styles.description}>{t("Traffic load is the variable or moving load on a component, in this case a wide span beam. For example as a result of people, bearing materials, wind, snow...")}</p>
            </div>
                <div className={styles.visualization}>
                    <CRStep5Visualization {...params.data} />
                    <p>{`${params.data.name}`}</p>
                </div>
            </>}
        </div>
    )
}

export default CRStep5Component;
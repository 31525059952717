import { Link } from "react-router-dom";
import IOverviewItem from "../../../Interfaces/overviewItem";
import styles from './RoomOverviewItem.module.css'
import editIcon from '../../../../assets/images/wave3/edit-icon.svg'
import { t } from "i18next";
import RoomOverviewItemLogic from "./RoomOverviewItem.logic";


const RoomOverviewItemComponent: React.FC<IOverviewItem> = (params: IOverviewItem) => {
    const{canEdit, editUrl} = RoomOverviewItemLogic(params);
    return (
        <div className={styles.card}>
            <div className={styles.card__head}>
                {params.title !== null && <h5 className={styles.card__title}>{params.title}</h5>}
                {editUrl && canEdit && <Link className={styles.card__link} to={params.editUrl}><img src={editIcon} alt="" /> {t("Edit")}</Link>}
            </div>
            <params.visualisation {...params.data} />

            {params.tag &&<span className={styles.banner}>{params.tag}</span>}
        </div>
    )
}

export default RoomOverviewItemComponent;
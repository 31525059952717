import { useEffect, useState } from "react";
import ISelect from "../../Interfaces/select";
import ISelectOption from "../../Interfaces/selectOption";

const InitSelectLogic = (params: ISelect) => {
    const [selectedOption, setSelectedOption] = useState<ISelectOption>(params.selected)

    useEffect(() => {
        if (params.selected) {
            setSelectedOption(params.options.filter(x => x.value === params?.selected?.value)[0])
        }
    }, [params.selected])


    const setOption = (option: ISelectOption) => {
        if (selectedOption === option) return;
        params.onChange(option);
        setSelectedOption(option);
    }

    return { setOption, selectedOption }
}

export default InitSelectLogic;
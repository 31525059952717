import IFileInfo from "../../interfaces/fileInfo";
import { clientInstance } from "./clientInstance";

const useFileApiService = () => {
  const client = clientInstance();

  const uploadImages = (formData: FormData) =>
    client.post(`/files/upload/images`, formData);

  const uploadFiles = (formData: FormData) =>
    client.post(`/files/upload/media_files`, formData);

  const deleteFile = (file: IFileInfo) =>
    client.post<any>(`/files/delete`, file);


  const getImages = () => client.get<any>(`/files/get/images`);

  const getFiles = () => client.get<any>(`/files/get/files`);


  return {
    uploadFiles,
    uploadImages,
    deleteFile,
    getImages,
    getFiles
  };
};

export default useFileApiService;

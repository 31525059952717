import IRoomOverview from "../../../Interfaces/roomOverview";
import ProductOverviewComponent from "./ProductOverview.component";

const Screws2OverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <>
            <ProductOverviewComponent name="PROTEKTOR EDIFICIO Verbindungsschraube SL4 (4,8 x 16 mm)" calculationResult={undefined!} fileInfos={[{url: params.sl4AssetPath ?? "" }]} id={undefined!}  />
        </>
    )
}

export default Screws2OverviewComponent;
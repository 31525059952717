import { t } from 'i18next';
import { Link } from 'react-router-dom';
import ButtonShared from '../../SharedComponents/ButtonShared/ButtonShared';
import InputShared from '../../SharedComponents/InputShared/InputShared';
import NotificationModalComponent from '../NotificationModal/NotificationModal.component';
import InitForgotPasswordFormLogic from './ForgotPassword.logic';
import styles from './ForgotPasswordForm.module.css'

const ForgotPasswordFormComponent: React.FC = () => {
    const { data, errorMessage, notificationModal, presentNotificationModal, isSubmitDisabled, onSubmit, onChange } = InitForgotPasswordFormLogic();

    return (
        <>
            <form onSubmit={onSubmit}>
                <h2 className={styles.title}>{t('Forgot password?')}</h2>
                <p className={styles.description}><span>*</span> {t('indicates a required field')}</p>

                {/* <label className={styles.label} htmlFor="email">{t("Email address")} <span>*</span></label>
                <input onChange={onChange} className={styles.input} type="email" name="username" id="email" placeholder='name@yourdomain.com' value={data.username} required /> */}
                <InputShared mode="settings" onChange={onChange} type="email" name="username" placeholder='name@yourdomain.com' value={data.username} required={true} labelText={t("Email address")}/>
                {errorMessage !== '' && <p className={styles.error__message}>{errorMessage}</p>}

                <ButtonShared disabled={isSubmitDisabled} type="submit" text={t("Send recovery link")} wide={true} />
                
                
                
                <p className={styles.cta__paragraph}><Link to={'/'}>{t("Back to login")}</Link></p>
            </form>
            {presentNotificationModal && <NotificationModalComponent {...notificationModal}></NotificationModalComponent>}
        </>
    )
}

export default ForgotPasswordFormComponent;
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IErrorModal from "../../Interfaces/errorModal";
import ButtonShared from "../../SharedComponents/ButtonShared/ButtonShared";
import styles from "./ErrorModal.module.css";

const ErrorModal = (props: IErrorModal) => {
  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.close__button__container}>
            <button onClick={() => props.close()} className={styles.close__button}><FontAwesomeIcon icon={faTimes}></FontAwesomeIcon></button>
        </div>
        
        <h2 className={styles.title}>{props.title}</h2>
        <p className={styles.description}>{props.text}</p>
        
        <ButtonShared
        text="Ok"
        onClick={() => props.close()}
        type="button"
        wide={true}
        />
      </div>
    </div>
  );
};

export default ErrorModal;

import { t } from "i18next"
import { useEffect, useState } from "react"
import IConfiguration from "../../interfaces/configuration"
import useConfigurationApiService from "../../Wave3/ApiService/configurationApiService"
import IModal from "../../Wave3/Interfaces/modal"

const InitConfigurationLogic = () => {
    const columns = ["id", "configurationKey", "value"]
    const headers = [t("Id"), t("Configuration key"), t("Asset path")]
    const [configurationItems, setConfigurationItems] = useState<IConfiguration[]>([])
    const {deleteConfiguration, getConfigurationByPage} = useConfigurationApiService()
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [idToDelete, setIdToDelete] = useState<number>(-1)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [totalElements, setTotalElements] = useState<number>(0)
    const [pageInfo, setPageInfo] = useState({
        page: 0,
        rowsPerPage: 0
    })

    const request = (page: number = 0, rowsPerPage: number = 5) => {
        getConfigurationByPage(page, rowsPerPage).then((response) => {
            if (!response.data.empty) {
                setConfigurationItems(response.data.content);
                setTotalElements(response.data.totalElements)
                setPageInfo({page: (response.data.numberOfElements === 1) ? page -1 : page, rowsPerPage: rowsPerPage})
            }
        }).catch(error =>{
            console.log(error)
        }).finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        request();
        //eslint-disable-next-line
    }, [])

    const deleteModal: IModal = {
        cancelAction: () => { setShowDeleteModal(false) },
        confirmAction: () => deleteConfig(idToDelete),
        confirmText: t("Confirm"),
        showCancel: true,
        title: t("Delete configuration"),
        message: t("Are you sure about this action?"),
        image: null!
    }

    const openDeleteModal = (id: number) => {
        setIdToDelete(id)
        setShowDeleteModal(true)
    }

    const deleteConfig = (id: number) => {
        const keyToDelete = configurationItems.find(item => {return item.id === id})?.configurationKey

        return new Promise((resolve: any, reject: any) => {
            deleteConfiguration(keyToDelete!).then(response => {
                request(pageInfo.page, pageInfo.rowsPerPage)
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                setShowDeleteModal(false)
            })
        })
    }

    return {columns, headers, configurationItems, openDeleteModal, showDeleteModal, deleteModal, request, totalElements, isLoading}
}
export default InitConfigurationLogic
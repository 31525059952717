import React from 'react'
import styles from './Product.module.css'
import IGridProduct from '../../../Interfaces/gridProduct';
import { ProductType } from '../../../enums/productType';
import { t } from 'i18next';
import InitProductComponentLogic from './Product.logic';

const ProductComponent: React.FunctionComponent<IGridProduct> = (params: IGridProduct) => {

    const {getImage} = InitProductComponentLogic(params)

    return (
        <div className={`${styles.product} ${params.selected ? styles.selected : ''}`}>
            <div className={styles.product__header}>
                <img className={styles.image} src={getImage()} alt="product" />
                <div className={styles.product__name}>{params.name}</div>
            </div>
            {params.type === ProductType.profile &&
                <div className={styles.performance}>
                    {params.recommended && <div className={styles.banner}>{t("Recommended by Protektor")}</div>}
                    <div className={styles.utilization__label}>{t("Utilization")}</div>
                    {params.calculationResult?.serviceability !== undefined  &&
                        <div className={styles.performance__item}>
                            <span className={styles.performance__item__title}>{t("Load capacity serviceability")}</span>
                            <div className={styles.progress}>
                                <div style={{ width: `${Math.round(params.calculationResult.serviceability * 100 )}%` }} className={(Math.round(params.calculationResult.serviceability * 100 )) >= 95 ? `${styles.progress__bar} ${styles.progress__busy}` : styles.progress__bar}>
                                    {params.calculationResult.serviceability * 100  > 24 &&
                                        <div>
                                        <span className={styles.progress__number}>
                                            {Math.round(params.calculationResult.serviceability * 100 )}%
                                        </span></div>}
                                </div>
                                {params.calculationResult.serviceability * 100  <= 24 &&
                                <>
                                    <span className={styles.progress__number__black}> {Math.round(params.calculationResult.serviceability * 100 )}%</span>
                                </>}
                            </div>
                        </div>
                    }
                    {params.calculationResult?.bendingStress !== undefined  &&
                        <div className={styles.performance__item}>
                            <span className={styles.performance__item__title}>{t("Load capacity bending stress")}</span>
                            <div className={styles.progress}>
                                <div style={{ width: `${Math.round(params.calculationResult.bendingStress * 100 )}%` }} className={(Math.round(params.calculationResult.bendingStress * 100 )) >= 95 ? `${styles.progress__bar} ${styles.progress__busy}` : styles.progress__bar}>
                                    {params.calculationResult.bendingStress * 100  > 24 &&
                                        <span className={styles.progress__number}>
                                            {Math.round(params.calculationResult.bendingStress * 100 )}%
                                        </span>}
                                </div>
                                {params.calculationResult.bendingStress * 100  <= 24 &&
                                    <span className={styles.progress__number__black}> {Math.round(params.calculationResult.bendingStress * 100 )}%</span>
                                }
                            </div>
                        </div>
                    }
                </div>}

            {params.type === ProductType.connection &&
                <div className={styles.performance}>
                      <div className={styles.performance__item}>
                            <span className={styles.performance__item__title}>{t("Permissible support load")}</span>
                            <div className={styles.progress}>
                                <div style={{ width: `${Math.round(params.calculationResult.supportLoad * 100 )}%` }} className={(Math.round(params.calculationResult.supportLoad * 100 )) >= 95 ? `${styles.progress__bar} ${styles.progress__busy}` : styles.progress__bar}>
                                    {params.calculationResult.supportLoad * 100  > 24 &&
                                        <span className={styles.progress__number}>
                                            {Math.round(params.calculationResult.supportLoad * 100 )}%
                                        </span>}
                                </div>
                                {params.calculationResult.supportLoad * 100  <= 24 &&
                                    <span className={styles.progress__number__black}> {Math.round(params.calculationResult.supportLoad * 100 )}%</span>
                                }
                            </div>
                        </div>
                </div>
            }
        </div>
    )
}

export default ProductComponent;
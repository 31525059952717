import { t } from "i18next";
import React from "react";
import { DeflectionLimit } from "../../../enums/deflectionLimit";
import IRoom from "../../../interfaces/room";
import roomHelper from "../../helpers/roomHelper";
import InitPrintCalculationLogic from "./PrintCalculation.logic";
import styles from "./PrintCalculation.module.css";
import watermark from "../../../assets/images/watermark-calc.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faSpinner } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/images/logo.svg";
import logo2 from "../../../assets/images/edificio.jpg";
import { Walkability } from "../../../enums/walkability";
import { PartitionWallLoad } from "../../../enums/partitionWallLoad";
import UtilsHelper from "../../../helpers/utils.helper";

const PrintCalculationComponent: React.FunctionComponent<IRoom> = (
  params: IRoom
) => {
  const { data, loading, printRef, handlePrint } =
    InitPrintCalculationLogic(params);
  return (
    <div>
      {params.selectedProducts && (
        <button
          className={styles.print__button}
          disabled={loading}
          onClick={handlePrint}
        >
          {!loading && <FontAwesomeIcon icon={faPrint} style={{marginRight: "6px"}}/>}
          {loading && (
            <FontAwesomeIcon className={styles.spinner} icon={faSpinner} />
          )}{" "}
          {t("Pre-dimensioning")}
        </button>
      )}

      <div ref={printRef} className={styles.pdf} translate="no">
        <img
          alt="watermark"
          className={styles.pdf__watermark}
          src={watermark}
        />
        <div className={styles.pdf__header}>
          <img alt="logo1" src={logo} />
          <img alt="logo2" src={logo2} />
        </div>

        <div className={styles.page}>
          <div className={styles.first__page}>
            <h3 className={styles.section_name_first}>
              Statische Vorbemessung Weitspannträger
            </h3>

            <div className={styles.res_group}>
              <div>
                <strong>Projekt Nr.: </strong>
              </div>
              <div style={{ width: "66%" }}>{params?.code}</div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>Bauvorhaben: </strong>
              </div>
              <div style={{ width: "66%" }}>{params?.name}</div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>Ansprechpartner: </strong>
              </div>
              <div style={{ width: "66%" }}>
                {params.user?.firstName} {params.user?.lastName} <br />
                {params.user.username} <br />
                {params.user?.address} <br />
                {params.user?.phone} <br />
                {params.user?.company} <br />
              </div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>Erstellungsdatum: </strong>
                <br /> Vorbemessung
              </div>
              <div style={{ width: "66%" }}>
                {UtilsHelper.formatDate(params?.updated)}
              </div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>Berechnungsgrundlage: </strong>
              </div>
              <div style={{ width: "66%" }}>
                Die zur Zeit gültigen amtlichen technischen Bestimmungen: <br />
                DIN EN 1993-1-3 <br />
                Eurocode 3: Bemessung und Konstruktion von Stahlbauten Teil 1-3:{" "}
                <br />
                Allgemeine Regeln <br />
                Ergänzende Regeln für kaltgeformte Bauteile und Bleche; <br />
                Deutsche Fassung <br />
                EN 1993-1-3:2011 + AC:2011
              </div>
            </div>
          </div>

          <div className={styles.prevent__break}>
            <h3 className={styles.section_name}>1. Profileigenschaften:</h3>
            <div className={styles.res_group}>
              <div>
                <strong>Artikelbezeichnung:</strong>
              </div>
              <div>{params?.selectedProducts?.profile.name}</div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>fy,k:</strong>
              </div>
              <div>
                {data?.fyk} kN/cm<sup>2</sup>
              </div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>Ly,eff:</strong>
              </div>
              <div>
                {data?.lyEffCm4} cm<sup>4</sup>
              </div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>Wy,eff:</strong>
              </div>
              <div>{data?.wyEffCm3} cm3</div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>E-Modul:</strong>
              </div>
              <div>
                {data?.efixedValue} kN/cm<sup>2</sup>
              </div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>Eigengewicht WST:</strong>
              </div>
              <div>{data?.ownWeightKg} kg/m</div>
            </div>
          </div>

          <h3 className={styles.section_name}>2. Grundlagen der Bemessung:</h3>

          <div className={styles.res_group}>
            <div>
              <strong>Spannweite l:</strong> {data?.l / 100} m
            </div>
            <div>
              <strong>zul. Durchbiegung: </strong>{" "}
              {roomHelper.getDeflectionLimiitString(params)}
            </div>
          </div>

          <div className={styles.res_group}>
            <span>
              {" "}
              <strong> Achsabstand e:</strong> {data?.e * 1000} mm
            </span>
          </div>

          <div className={styles.res_group}>
            <div>
              <strong>Lasteinwirkungen:</strong>
            </div>
            <div>
              <table className={styles.table}>
                <thead>
                  <tr>
                    <td>Bezeichnung</td>
                    <td>charakteristisch</td>
                    <td>Beiwert</td>
                    <td>Design</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Eigengewicht WST</td>
                    <td>
                      {data?.ownWeightKn.toFixed(3)} kN/m<sup>2</sup>
                    </td>
                    <td>* 1.35</td>
                    <td>
                      {(data?.ownWeightKn * 1.35).toFixed(3)} kN/m<sup>2</sup>
                    </td>
                  </tr>

                  {params.traficLoads < 500 &&
                    params.partitionWallLoad ===
                      PartitionWallLoad.GREATHER_OR_EQUAL_3_AND_LESS_OR_EQUAL_5 && (
                      <tr>
                        <td>Zusätzlich Last der Trennwandzuschlag</td>
                        <td>
                          1.2 kN/m<sup>2</sup>
                        </td>
                        <td>* 1.5</td>
                        <td>
                          {(1.2 * 1.5).toFixed(3)} kN/m<sup>2</sup>
                        </td>
                      </tr>
                    )}

                  {params.traficLoads < 500 &&
                    params.partitionWallLoad ===
                      PartitionWallLoad.LESS_OR_EQUAL_3 && (
                      <tr>
                        <td>Zusätzlich Last der Trennwandzuschlag</td>
                        <td>
                          0.8 kN/m<sup>2</sup>
                        </td>
                        <td>* 1.5</td>
                        <td>
                          {(0.8 * 1.5).toFixed(3)} kN/m<sup>2</sup>
                        </td>
                      </tr>
                    )}

                  {params.walkability === Walkability.LESS_100 && (
                    <tr>
                      <td>Begehbarkeit</td>
                      <td>
                        {(100 * 0.01).toFixed(3)} kN/m<sup>2</sup>
                      </td>
                      <td>* 1.5</td>
                      <td>
                        {(100 * 0.01 * 1.5).toFixed(3)} kN/m<sup>2</sup>
                      </td>
                    </tr>
                  )}

                  {params.walkability === Walkability.LESS_200 && (
                    <tr>
                      <td>Begehbarkeit</td>
                      <td>
                        {(200 * 0.01).toFixed(3)} kN/m<sup>2</sup>
                      </td>
                      <td>* 1.5</td>
                      <td>
                        {(200 * 0.01 * 1.5).toFixed(3)} kN/m<sup>2</sup>
                      </td>
                    </tr>
                  )}

                  {params.walkability === Walkability.OTHER && (
                    <tr>
                      <td>Verkehrsbelastung</td>
                      <td>
                        {(params?.traficLoads * 0.01).toFixed(3)} kN/m
                        <sup>2</sup>
                      </td>
                      <td>* 1.5</td>
                      <td>
                        {(params.traficLoads * 0.01 * 1.5).toFixed(3)} kN/m
                        <sup>2</sup>
                      </td>
                    </tr>
                  )}

                  {params?.constantLoads?.map((load, i) => {
                    return (
                      <tr key={i}>
                        <td>{load.description}</td>
                        <td>
                          {(Number(load.value) * 0.01).toFixed(3)} kN/m
                          <sup>2</sup>
                        </td>
                        <td>* 1.5</td>
                        <td>
                          {(Number(load.value) * 0.01 * 1.5).toFixed(3)} kN/m
                          <sup>2</sup>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <td>Summe der Lasten:</td>
                    <td>
                      {data?.sumConstantLoads.toFixed(3)} kN/m<sup>2</sup>
                    </td>
                    <td></td>
                    <td>
                      {data?.sumConstantLoadsFactorised.toFixed(3)} kN/m
                      <sup>2</sup>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <p className={styles.note}>
                Beiwert für beständige Lasten γg = 1,35, für veränderliche
                Lasten γq = 1,5
              </p>
            </div>
          </div>

          <div className={styles.prevent__break}>
            <h3 className={styles.section_name}>3. Bemessungswerte:</h3>
            <div className={styles.res_group}>
              <div>
                <strong>Linienlasten:</strong>
              </div>
              <div>
                qk,ges = {data?.qk} kN/m<sup>2</sup> * {data?.e} m ={" "}
                {data?.qkGes} kN/cm
                <br />
                qd,ges = {data?.qd} kN/m<sup>2</sup> * {data?.e} m ={" "}
                {data?.qdGes} kn/cm
              </div>
            </div>
            <div className={styles.res_group}>
              <div>
                <strong>Auflagerreaktion:</strong>
              </div>
              <div>
                <div className={styles.formula}>
                  <span>Ay,Ed =</span>
                  <div>
                    <div>
                      {data?.qdGes} kN/cm * {data?.l} cm
                    </div>
                    2
                  </div>
                  <span> = {data?.ayed} kN</span>
                </div>
              </div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>Maximales Moment:</strong>
              </div>
              <div>
                <div className={styles.formula}>
                  <span>My,Ed =</span>
                  <div>
                    <div>
                      {data?.qdGes} kN/cm * ( {data?.l} cm) <sup>2</sup>
                    </div>
                    8
                  </div>
                  <span> = {data?.myed} kNcm</span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.prevent__break}>
            <h3 className={styles.section_name}>
              4. Gebrauchstauglichkeitsnachweis:
            </h3>

            <div className={styles.res_group}>
              <div>
                <strong>zul. Durchbiegung:</strong>
              </div>
              <div>
                <div className={styles.formula}>
                  <span>fzul. =</span>

                  {roomHelper.getDeflectionLimiitString(params) ===
                    DeflectionLimit.l300 && (
                    <>
                      <div>
                        <div>l</div>
                        300
                      </div>
                      =
                      <div>
                        <div>{data?.l} mm</div>
                        300
                      </div>
                    </>
                  )}
                  {roomHelper.getDeflectionLimiitString(params) ===
                    DeflectionLimit.l500 && (
                    <>
                      <div>
                        <div>l</div>
                        500
                      </div>
                      =
                      <div>
                        <div>{data?.l * 10} mm</div>
                        500
                      </div>
                    </>
                  )}
                  <span> = {data?.fzul} mm</span>
                </div>
              </div>
            </div>

            <div className={styles.res_group}>
              <div>
                <strong>vorh. Durchbiegung:</strong>
              </div>
              <div>
                <div className={styles.formula}>
                  fvorh. =
                  <div>
                    <div>
                      {data?.qkGes} kN/cm * ({data?.l} cm)<sup>4</sup>
                    </div>
                    76,8 * {data?.efixedValue} kN/cm<sup>2</sup> *{" "}
                    {data?.lyFicCm4} cm<sup>4</sup>
                  </div>
                </div>
                <div className={styles.formula}>
                  fvorh. = {data?.fvorh / 10} cm = {data?.fvorh} mm
                </div>
                <div className={styles.formula}>
                  <div>
                    <div>fvorh.</div>
                    fzul.
                  </div>
                  <span> = </span>
                  <div>
                    <div>{data?.fvorh} mm</div>
                    {data?.fzul} mm
                  </div>
                  <span> = </span>
                  <span>
                    {" "}
                    <strong>{data?.serviceability}</strong> &#60;{" "}
                    <strong>1</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.prevent__break}>
            <h3 className={styles.section_name}>
              5. Nachweis der Biegebeanspruchung nach DIN EN 1993‐1‐3:
            </h3>
            <div className={styles.res_group}>
              <div>
                <strong>Biegebeanspruchbarkeit:</strong>
              </div>
              <div>
                <div className={styles.formula}>
                  <span>My,Rd =</span>
                  <div>
                    <div>
                      {data?.fyk} kN/cm<sup>2</sup> * {data?.wyEffCm3} cm3
                    </div>
                    1.1
                  </div>
                  <span> = {data?.myrd} kNcm</span>
                </div>

                <div className={styles.formula}>
                  <div>
                    <div> My,Ed </div>
                    My,Rd
                  </div>
                  =
                  <div>
                    <div>{data?.myed} kNcm</div>
                    {data?.myrd} kNcm
                  </div>
                  <span>
                    {" "}
                    = <strong>{data?.bendingStress}</strong> &#60;{" "}
                    <strong>1</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.prevent__break}>
            <h3 className={styles.section_name}>
              6 Reultierende Linienlast aus Weitspannträgern zur
              Weiterbemessung:
            </h3>
            <div className={styles.res_group}>
              <div>
                <strong>Linienlast:</strong>
              </div>
              <div>
                <div className={styles.formula}>
                  <span>qd,WST =</span>
                  <div>
                    <div>Ay,Ed</div>e
                  </div>
                  <div>
                    <div>{data?.ayed} kN</div>
                    {data?.e} m
                  </div>
                  <span> = {data?.qdwst} kNm</span>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.last__page}>
            <h3 className={styles.section_name}>Haftungshinweise und AGBs</h3>
            <br />
            <p>
              <strong>
                Es gelten unsere aktuellen AGBs, zu finden unter{" "}
                <a
                  className={styles.red_link}
                  href="https://www.protektor.com/AGB"
                >
                  www.protektor.com/AGB
                </a>{" "}
                sowie unsere <br />
                Haftungshinweise zu technischen Angaben und Berechnungen, zu
                finden unter <br />
                <a
                  className={styles.red_link}
                  href="https://www.protektor.com/HAS"
                >
                  www.protektor.com/HAS
                </a>
                .
              </strong>
            </p>
            <br />
            <p>
              Bitte berücksichtigen Sie für die technischen Angaben in
              Broschüren, sowie Berechnungen, insbesondere <br />
              für die durch PROTEKTOR vorgenommenen Berechnungnen, die folgenden
              Haftungshinweise:
            </p>
            <br />
            <p>
              1. Unsere technischen Angaben in Broschüren erfolgen
              ausschließlich auf der Grundlage und unter <br />
              Berücksichtigung der entsprechenden Produkte von PROTEKTOR.
            </p>
            <br />
            <p>
              2. Für von Ihnen selbst vorgenommene Berechnungen auf der
              Grundlage unserer technischen Angaben in <br />
              Broschüren sind Sie ausschließlich selbst verantwortlich. Eine
              Haftung für die Richtigkeit dieser Berechnung <br />
              kann von PROTEKTOR nicht übernommen werden.
            </p>
            <br />
            <p>
              3. Eventuell von uns vorgenommene Berechnungen erfolgen
              ausschließlich unter Zugrundelegung der von <br />
              Ihnen zur Verfügung gestellten und übermittelten Information und
              Daten. PROTEKTOR ist nicht <br />
              verpflichtet, die von Ihnen zur Verfügung gestellten und
              übermittelten Informationen und Daten <br />
              hinsichtlich ihrer Richtigkeit oder Vollständigkeit zu überprüfen.
            </p>
            <br />
            <p>
              4. Wir weisen ausdrücklich darauf hin, dass PROTEKTOR nicht
              bauvorlageberechtigt ist. Statische <br />
              Berechnungen und Nachweise sind daher stets von einem Prüfstatiker
              zu überprüfen und freizugeben.
            </p>
            <br />
            <p>
              Sofern <br />
              a) eine Überprüfung und Freigabe durch einen Prüfstatiker nicht
              erfolgt ist oder <br />
              b) die Daten hinsichtlich des zu realisierenden Gewerks von den
              von Ihnen zur Verfügung gestellten und an <br />
              PROTEKTOR übermittelten Informationen und Daten (insbesondere
              betreffend Lastwerte/Angaben, etc.) <br />
              abweichen, <br />
              übernimmt PROTEKTOR keinerlei Haftung für Schäden, die durch die
              Nutzung der von uns erstellten <br />
              Berechnungen und Nachweise entstehen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintCalculationComponent;

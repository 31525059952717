const storeToken = (token: string) => {
    localStorage.setItem('access_token', token);
}

const removeToken = () => {
    localStorage.removeItem('access_token')
}

const getToken = () => {
    return localStorage.getItem('access_token') as string;
}

const tokenHelper = {
    storeToken,
    removeToken,
    getToken
}

export default tokenHelper;
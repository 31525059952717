import { t } from "i18next";
import UtilsHelper from "../../../helpers/utils.helper";
import IFileInfo from "../../../interfaces/fileInfo";
import InitFilesLogic from "./Files.logic";
import styles from './Files.module.css';

const AdminFilesComponent: React.FunctionComponent = () => {
    const { files, handleForm, handleDelete, handleFileChange, copyUrl } = InitFilesLogic();
    return (<>
        <form onSubmit={handleForm}>
            <h2>{t("Files")}</h2>
            <input required onChange={handleFileChange} multiple={true} type="file" /><br />
            <br />
            <button type="submit">{t("Submit")}</button>
        </form>
        <div className={styles.files}>
            {files.map((item: IFileInfo, index: any) => <div key={index} className={styles.file__item}>
                <a href={UtilsHelper.createFileUrl(item.url)} target="_blank" rel="noreferrer">{item.name?.replace(/^[^_]*_/, '')}</a>
                <div>
                    <button onClick={() => { copyUrl(item) }}>{t("Copy URL")}</button>
                    <button style={{marginLeft:'10px'}} onClick={() => { handleDelete(item) }}>{t("Delete")}</button>
                </div>
            </div>)}
        </div>
    </>)
}

export default AdminFilesComponent;
import IPage from "../../../interfaces/page";
import LoadingComponent from "../../Components/Loading/Loading.component";
import RoomOverviewComponent from "../../Components/RoomOverview/RoomOverview.component";
import MainLayout from "../../layouts/Main/Main.layout";
import InitRoomOverviewPageLogic from "./RoomOverviewPage.logic";


const RoomOverviewPage: React.FC<IPage> = () => {
    const { overview, isLoading } = InitRoomOverviewPageLogic();
    
    return (
        <>
            {isLoading && <LoadingComponent />}
            {overview !== null! && <MainLayout component={RoomOverviewComponent} props={overview}></MainLayout>}
        </>
    )
}

export default RoomOverviewPage;
import { useEffect } from "react";
import UtilsHelper from "../../../helpers/utils.helper";
import ISlide from "../../Interfaces/slide";
import InitSliderLogic from "./Slider.logic";
import styles from "./Slider.module.css";

const SliderComponent: React.FunctionComponent<any> = (props: {
  slides: ISlide[];
}) => {
  const { currentSlide, present, slider } = InitSliderLogic(props.slides);


  return (
    <div className={styles.slider} ref={slider}>
      {currentSlide !== null && (
        <>
          <img
            className={styles.image}
            alt="slide"
            src={UtilsHelper.createFileUrl(currentSlide.url)}
          />
          <div
            className={`${styles.overlay} ${
              currentSlide.theme === "DARK"
                ? styles.dark__overlay
                : styles.light__overlay
            }`}
          ></div>
          <div
            className={`${styles.content} ${
              currentSlide.theme === "DARK" ? styles.dark : styles.light
            }`}
          >
            <h2 className={styles.title}>{currentSlide.title}</h2>
            <p className={styles.text}>{currentSlide.text}</p>
            <div className={styles.navigation}>
              {props.slides.map((object, index) => {
                if (currentSlide === object) {
                  return <div key={index} className={styles.active}></div>;
                }
                return (
                  <div
                    key={index}
                    onClick={() => {
                      present(index);
                    }}
                    className={styles.navigation__item}
                  ></div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SliderComponent;

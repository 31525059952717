import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useRoomApiService from "../../ApiService/roomApiService";
import useBackButtonHelper from "../../helpers/backButtonHelper";
import IProjectItem from "../../Interfaces/projectItem";
import ISelect from "../../Interfaces/select";
import { useCartStore } from "../../Stores/cart.store";
import { RoomStatus } from "../../../enums/roomStatus";

const InitProjectsLogic = () => {
  const { getProjects, searchProjects } = useRoomApiService();

  const [searchParams, setSearchParams] = useSearchParams();
  const [title, setTitle] = useState<string>("");
  const [data, setData] = useState<IProjectItem[]>([]);
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState<number>(6);
  const [sortBy, setSortBy] = useState<string>("created");
  const [sortAscending, setSortAscending] = useState<boolean>(false);
  const [tempSearchValue, setTempSearchValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [disableLoadMore, setDisableLoadMore] = useState<boolean>(true);
  const [empty, setEmpty] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(true);
  const { pushBackUrl, isOnTopOfStack, clearBackUrls } = useBackButtonHelper();
  const [currentProjectStatus, setCurrentProjectStatus] = useState<RoomStatus>(
    null!
  );
  const [isCheckout, setCheckout] = useState<boolean>(true);
  const projectsInCart = useCartStore((state) => state.projectsInCart);

  const deleteItemAtIndex = (id: number) => {
    const newData = data.filter((project) => project.id !== id);
    setData(newData);
  };

  useEffect(() => {
    clearBackUrls();
    const searchParam = searchParams.get("searchValue");
    if (searchParam) {
      pushBackUrl(`/projects?searchValue=${searchParam}`, "Back to projects");
      setPerPage(6);
      setSearchValue(searchParam);
    }
    const status = searchParams.get("status");
    if (status && isOnTopOfStack(status)) return;
    switch (status) {
      case "ready":
        pushBackUrl(`/projects?status=${status}`, "Go to ready projects");
        break;
      case "unfinished":
        pushBackUrl(`/projects?status=${status}`, "Go to unfinished projects");
        break;
      case "cart":
        pushBackUrl(`/projects?status=${status}`, "Back to your cart");
        break;
      case "trash":
        pushBackUrl(`/projects?status=${status}`, "Back to trash");
        break;
    }
  }, []);

  useEffect(() => {
    setCheckout(projectsInCart > 0 && searchParams.get("status") === "cart");
  }, [projectsInCart]);

  useEffect(() => {
    setDisableLoadMore(true);
    if (searchParams.get("status") === "cart") {
      setTitle("Cart");
      setTempSearchValue("");
      getProjects(RoomStatus.INCART, 0, perPage, sortBy, sortAscending).then(
        (response) => {
          handleResponse(response);
        }
      );
    } else if (searchParams.get("status") === "ready") {
      setTitle("Ready projects");
      getProjects(RoomStatus.READY, 0, perPage, sortBy, sortAscending).then(
        (response) => {
          handleResponse(response);
        }
      );
    } else if (searchParams.get("status") === "unfinished") {
      setTitle("Unfinished projects");
      getProjects(RoomStatus.DRAFT, 0, perPage, sortBy, sortAscending).then(
        (response) => {
          handleResponse(response);
        }
      );
    } else if (searchParams.get("status") === "trash") {
      setTitle("Trash");
      getProjects(RoomStatus.INTRASH, 0, perPage, sortBy, sortAscending).then(
        (response) => {
          handleResponse(response);
        }
      );
    } else if (searchParams.get("s") !== null) {
      setTitle("Search");
      setTempSearchValue(searchParams.get("s")!);
      searchProjects(
        0,
        perPage,
        sortBy,
        sortAscending,
        searchParams.get("s")!
      ).then((response) => {
        handleResponse(response);
      });
    } else {
      searchProjects(0, perPage, sortBy, sortAscending, searchValue).then(
        (response) => {
          handleResponse(response);
        }
      );
      setTitle("Projects");
    }
  }, [sortBy, sortAscending, perPage, searchValue, searchParams]);

  const handleResponse = (resp: any) => {
    setDisableLoadMore(resp.data.last);
    setData(resp.data.content);
    setEmpty(resp.data.empty);
    const currentProjectStatus = searchParams.get("status");
    setCheckout(!resp.data.empty && currentProjectStatus === "cart");
    if (currentProjectStatus === "unfinished")
      setCurrentProjectStatus(RoomStatus.DRAFT ?? null!);
    setLoading(false);
  };

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const select: ISelect = {
    defaultText: t("Sort by"),
    onChange: (newValue: any) => {
      setSortBy(newValue.value);
    },
    options: [
      { text: t("Code"), value: "code" },
      { text: t("Creation date"), value: "created" },
      { text: t("Status"), value: "status" },
      { text: t("Name"), value: "name" },
    ],
    selected: null!,
  };

  const handleSearch = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (tempSearchValue.length > 0) {
      setPerPage(6);
      searchParams.delete("status");
      setSearchParams(searchParams);
      setSearchValue(tempSearchValue);
      clearBackUrls();
      pushBackUrl(
        `/projects?searchValue=${tempSearchValue}`,
        "Back to projects"
      );
    }
  };

  const handleSearchInput = (event: React.FormEvent<HTMLInputElement>) => {
    setTempSearchValue(event.currentTarget.value);
    searchParams.delete("searchValue");
    if (event.currentTarget.value === "") {
      setSearchValue(event.currentTarget.value);
    }
  };

  const loadMore = () => {
    setDisableLoadMore(true);
    setPerPage(perPage + 6);
  };

  const updateItemStatus = (item: IProjectItem) => {
    let newData = [...data];
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === item.id) {
        data[i].status = item.status;
      }
    }

    setData(newData);
  };

  return {
    showFilter,
    sortBy,
    sortAscending,
    select,
    tempSearchValue,
    title,
    data,
    disableLoadMore,
    empty,
    isCheckout,
    isLoading,
    toggleFilter,
    setSortAscending,
    handleSearch,
    handleSearchInput,
    loadMore,
    updateItemStatus,
    deleteItemAtIndex,
    navigate,
    currentProjectStatus,
  };
};

export default InitProjectsLogic;

import i18next, { t } from "i18next";
import { useEffect, useState } from "react";
import IPossibleProduct from "../../../../interfaces/possibleProduct";
import IProduct from "../../../../interfaces/product";
import ISelectedProductsProfile from "../../../Interfaces/orderProfile";
import ISelectedProductsRoom from "../../../Interfaces/roomWithSelectedProducts";
import IStep from "../../../Interfaces/Step";


const InitSPStep1Logic = (params: IStep<ISelectedProductsRoom>) => {
    const [enableLoadMore, setEnableLoadMore] = useState<boolean>(false)
    const [productsToRender, setProductsToRender] = useState<IPossibleProduct[]>([])
    const [page, setPage] = useState<number>(1)
    const isActive = (product: IProduct) => {
        return product.id === params.data.selectedProducts.profile?.id;
    }

    const loadMore = () => {
        setPage(page + 1)
    }

    useEffect(() => {
        setProductsToRender(params.data.possibleProducts.slice(0, 4*page))
        setEnableLoadMore(params.data.possibleProducts.length > 4*page)

        // eslint-disable-next-line 
    }, [page])

    const select = (product: ISelectedProductsProfile) => {
        let data = { ...params.data };
        data.selectedProducts.profile = product;
        if (product?.wallProfiles !== null && product.wallProfiles?.length > 0)
        {
            data.selectedProducts.wallProfile = product.wallProfiles[0]
            data.selectedProducts.connectionAngle = null!
        }
        else
        {
            data.selectedProducts.connectionAngle = product.connectionAngles[0]
            data.selectedProducts.wallProfile = null!
        }
        data.selectedProducts.profile.connectionAngles?.sort((a, b) => a.id + b.id);
        data.selectedProducts.profile.wallProfiles?.sort((a, b) => a.id + b.id);
        params.setData(data);
    }

    useEffect(() => {
        params.options.setTitle(t('Select Beam Profile') );
    })
    
    useEffect(() => {
        // params.options.setBackText(t("Or back to project"));
        params.data.showedProducts = undefined 

        setEnableLoadMore(params.data.possibleProducts.length > 4)
        let index = 0
        if(params.data.selectedProducts.profile) index = params.data.possibleProducts.findIndex(product => product.id === params.data.selectedProducts.profile.id)
        // if(index === -1) index = 0   //ako se odkomentarise prikaze prvi red svakako
        setProductsToRender(params.data.possibleProducts.slice(0, 4*(Math.ceil((index+1)/4))))
        setPage(Math.ceil((index+1)/4))

        // eslint-disable-next-line 
    },[])


    return { isActive, select, enableLoadMore, loadMore, productsToRender };
}

export default InitSPStep1Logic;
import IRoomFormData from "../../../../Interfaces/roomFormData";
import styles from './CRStep3Visualization.module.css'
import { SpanDirection } from "../../../../../enums/spanDirection";

import s2Length from '../../../../../assets/images/wave3/visualization/s2-length.svg';
import s2Width from '../../../../../assets/images/wave3/visualization/s2-width.svg';
import ArrowsVisualizationComponent from "../../Visualization/ArrowsVisualization.component";

const CRStep3VisualizationComponent: React.FunctionComponent<IRoomFormData> = (params: IRoomFormData) => {
    return (
        <div className={styles.container}>
            {params.spanDirection === SpanDirection.ROOM_LENGTH && <img className={styles.room__image} src={s2Length} alt="" />}
            {params.spanDirection === SpanDirection.ROOM_WIDTH && <img className={styles.room__image} src={s2Width} alt="" />}

            <div className={`${styles.value__container} 
                ${params.spanDirection === SpanDirection.ROOM_LENGTH ? styles.valuea : styles.valueb}`}>
                <div className={`${styles.value} ${styles.focus__value}`}>{params.centerDistance > 0 ? parseInt(params.centerDistance.toString())/1 : 0} mm</div>
            </div>

            <div className={`${styles.value__container} ${styles.length}`}>
                <div className={styles.value}>{params.length / 1000} m</div>
            </div>

            <div className={`${styles.value__container} ${styles.width}`}>
                <div className={styles.value}>{params.width / 1000} m</div>
            </div>
 
            <ArrowsVisualizationComponent {...params}></ArrowsVisualizationComponent>
        </div>
    )
}

export default CRStep3VisualizationComponent;
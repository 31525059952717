import InitProjectsLogic from "./Projects.logic";
import arrowUp from "../../../assets/images/wave3/arrow-up-select.svg";
import arrowLeft from "../../../assets/images/wave3/arrow-left.svg";
import arrowDown from "../../../assets/images/wave3/arrow-down-select.svg";
import styles from "./Projects.module.css";
import SelectComponent from "../Select/Select.component";
import { t } from "i18next";
import ButtonShared from "../../SharedComponents/ButtonShared/ButtonShared";
import IProjectItem from "../../Interfaces/projectItem";
import { Link } from "react-router-dom";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import LoadingComponent from "../Loading/Loading.component";
import ProjectsGridComponent from "./ProjectsGrid/ProjectsGrid.component";

const ProjectsComponent: React.FC = () => {
  const {
    showFilter,
    sortBy,
    sortAscending,
    select,
    tempSearchValue,
    title,
    disableLoadMore,
    data,
    isCheckout,
    isLoading,
    toggleFilter,
    setSortAscending,
    handleSearch,
    handleSearchInput,
    loadMore,
    updateItemStatus,
    deleteItemAtIndex,
    currentProjectStatus,
  } = InitProjectsLogic();

  return (
    <>
      {!isLoading && (
        <>
          <div className={styles.projects__menu}>
            <h2 className={styles.title}>{t(title)}</h2>

            <button
              id="filterButton"
              onClick={() => {
                toggleFilter();
              }}
              className={styles.filter__button}
            >
              {t("Filter")} <> </>
              {showFilter && <img src={arrowUp} alt="arrow up" />}
              {!showFilter && <img src={arrowDown} alt="arrow down" />}
            </button>

            <div id="filter" className={styles.projects__menu__filter}>
              <SelectComponent {...select} />

              {sortBy !== null && (
                <div onClick={() => setSortAscending(!sortAscending)}>
                  {sortAscending && (
                    <span className={styles.sort__order}>
                      {t("ASC")}{" "}
                      <img
                        className={styles.sort__order__icon}
                        src={arrowDown}
                        alt="arrow down"
                      />
                    </span>
                  )}
                  {!sortAscending && (
                    <span className={styles.sort__order}>
                      {t("DESC")}{" "}
                      <img
                        className={styles.sort__order__icon}
                        src={arrowUp}
                        alt="arrow down"
                      />
                    </span>
                  )}
                </div>
              )}

              <form onSubmit={handleSearch} className={styles.search}>
                <input
                  type="text"
                  value={tempSearchValue}
                  name="searchValue"
                  onChange={handleSearchInput}
                  placeholder={t("Search...")}
                />
                <ButtonShared text={t("Search")} type="submit" />
              </form>
            </div>
          </div>

          {showFilter && (
            <div className={styles.mobile__filter}>
              <SelectComponent {...select} />
              {sortBy !== null && (
                <div onClick={() => setSortAscending(!sortAscending)}>
                  {sortAscending && (
                    <span className={styles.sort__order}>
                      {t("ASC")}{" "}
                      <img
                        className={styles.sort__order__icon}
                        src={arrowDown}
                        alt="arrow down"
                      />
                    </span>
                  )}
                  {!sortAscending && (
                    <span className={styles.sort__order}>
                      {t("DESC")}{" "}
                      <img
                        className={styles.sort__order__icon}
                        src={arrowUp}
                        alt="arrow down"
                      />
                    </span>
                  )}
                </div>
              )}

              <form onSubmit={handleSearch} className={styles.search}>
                <input
                  type="text"
                  value={tempSearchValue}
                  name="searchValue"
                  onChange={handleSearchInput}
                  placeholder={t("Search...")}
                />
                <button type="submit" className={styles.button}>
                  {t("Search")}
                </button>
              </form>
            </div>
          )}

          {/* {empty && <h1>{t("No projects found")}</h1>} */}
          <div className={styles.projects}>
            <ProjectsGridComponent
              projects={data}
              status={currentProjectStatus}
              updateItem={(item: IProjectItem) => {
                updateItemStatus(item);
              }}
              deleteItemAtIndex={deleteItemAtIndex}
            ></ProjectsGridComponent>
          </div>

          {!disableLoadMore && (
            <div className={styles.load__more}>
              <button
                disabled={disableLoadMore}
                onClick={loadMore}
                className={styles.load__more__button}
              >
                {t("Load more")} <img src={arrowDown} alt="arrow down" />
              </button>
            </div>
          )}

          <div className={styles.bottom__buttons}>
            <Link className={styles.checkout__link} to={"/dashboard"}>
              <ButtonShared
                type="button"
                img_src={arrowLeft}
                img_side={"left"}
                text={t("Back to dashboard")}
              />
            </Link>
          </div>
        </>
      )}
      {isLoading && <LoadingComponent></LoadingComponent>}
    </>
  );
};

export default ProjectsComponent;

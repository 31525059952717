import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { SpanDirection } from "../../../../enums/spanDirection";
import dataHandlerHelper from "../../../helpers/dataHandler.helper";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IStep from "../../../Interfaces/Step";
import useRoomApiService from "../../../ApiService/roomApiService";
import useBackButtonHelper from "../../../helpers/backButtonHelper";
const InitCRStep2Logic = (params: IStep<IRoomFormData>) => {
  const { createRoom, updateRoom } = useRoomApiService();
  const [showLengthDirectionError, setShowLengthDirectionError] =
    useState<boolean>(false);
  const [showWidthDirectionError, setShowWidthDirectionError] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { resetBackButtonToDashboardState } = useBackButtonHelper();
  useEffect(() => {
    // if (searchParams.get("mode") === "edit") {
    //   params.options?.setBackText(t("Or back to project overview"));
    // } else if (searchParams.get("mode") === "redirection") {
    //   params.options?.setBackText(t("Or back to Back"));
    // } else {
    //   params.options?.setBackText(t("Back to dashboard"));
    // }

    if (params.data.id === -1) {
      let newData = { ...params.data, nextStep: 2 };
      createRoom(newData, params.setData, setIsLoading);
    } else {
      let newData = {
        ...params.data,
        nextStep: params.data.nextStep > 2 ? params.data.nextStep : 2,
      };
      updateRoom(
        newData,
        setIsLoading,
        params.options.setAllowSkipTo,
        params.setData
      );
    }
    disableNext(params.data);
    if (params.setShouldSetBackButton) params.setShouldSetBackButton(false);

    // eslint-disable-next-line
  }, []);

  const setSpanDirection = (spanDirection: SpanDirection) => {
    let newData = { ...params.data, spanDirection: spanDirection, nextStep: 2 };
    params.options.setAllowSkipTo(2);
    params.setData({ ...newData });
    disableNext(newData);
    if (params.setShouldSetBackButton) resetBackButtonToDashboardState(params.setShouldSetBackButton)
  };

  const disableNext = (data: any) => {
    let length = data.length;
    if (data.spanDirection === SpanDirection.ROOM_WIDTH) length = data.width;

    params.setDisableNext(
      data.width < 500 || data.length < 500 || length > 10000,
      1
    );
  };

  useEffect(() => {
    switch (params.data.spanDirection) {
      case SpanDirection.ROOM_LENGTH:
        setShowWidthDirectionError(false);
        setShowLengthDirectionError(params.data.length > 10000);
        break;
      case SpanDirection.ROOM_WIDTH:
        setShowLengthDirectionError(false);
        setShowWidthDirectionError(params.data.width > 10000);
        break;
    }
  }, [params.data.length, params.data.width, params.data.spanDirection]);

  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (Number(event.currentTarget.value) > 1000000000)
      event.currentTarget.value = "1000000000";
    if (
      event.currentTarget.value.length > 3 &&
      Number(event.currentTarget.value) < 500
    )
      event.currentTarget.value = "500";
    if (Number(event.currentTarget.value) < 0) event.currentTarget.value = "0";

    let newData: IRoomFormData = dataHandlerHelper.handleInputChange(
      event,
      params.data
    );

    params.options.setAllowSkipTo(2);
    params.setData({ ...newData, nextStep: 2 });
    disableNext(newData);
    if (params.setShouldSetBackButton) resetBackButtonToDashboardState(params.setShouldSetBackButton)
  };

  const getLengthErrors = () => {
    return {
      maxMsg: t("Project dimensions greater error", { direction: t("length") }),
      minMsg: t("Project dimensions less error", { direction: t("length") }),
    };
  };

  const getWidthErrors = () => {
    return {
      maxMsg: t("Project dimensions greater error", { direction: t("width") }),
      minMsg: t("Project dimensions less error", { direction: t("width") }),
    };
  };

  return {
    onChange,
    showLengthDirectionError,
    showWidthDirectionError,
    setSpanDirection,
    getLengthErrors,
    getWidthErrors,
    isLoading,
  };
};

export default InitCRStep2Logic;

import { t } from "i18next";
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import IResetPassword from "../../../interfaces/resetPassword";
import dataHandlerHelper from "../../helpers/dataHandler.helper";
import INotificationModal from "../../Interfaces/notificationModal";
import passwordIcon from '../../../assets/images/wave3/password-changed.svg'
import useUserApiService from "../../ApiService/userApiService";

const InitResetPasswordFormLogic = () => {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const {resetPassword} = useUserApiService()

    let [searchParams] = useSearchParams();
    const [data, setData] = useState<IResetPassword>(
        {
            code: searchParams.get("restCode")!,
            email: searchParams.get("email")!,
            newPassword: "",
            repeatPassword: ""
        }
    );
    const toggleShowPassword = () =>{
        setShowPassword(!showPassword)
    }
    const navigate = useNavigate();
    const [presentNotificationModal, setPresentNotificationModal] = useState<boolean>(false);
    const notificationModal: INotificationModal = {
        action: () => { setPresentNotificationModal(false); navigate('/') },
        close: () => { setPresentNotificationModal(false); navigate('/') },
        actionText: t('Go to login'),
        description: t('Your password has been changed, please login to your account'),
        icon: passwordIcon,
        title: t("Password changed successfully")
    }

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        let newData = dataHandlerHelper.handleInputChange(event, { ...data });
        setData(newData);
    }


    const onSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setErrorMessage("");
        if (data.newPassword !== data.repeatPassword) {
            setData({ ...data, newPassword: "", repeatPassword: "" });
            return setErrorMessage(t("Passwords do not match, please enter matching passwords..."));
        }
        setSubmitDisabled(true);
        resetPassword(data).then(response => {
            setPresentNotificationModal(true);
            setSubmitDisabled(false);
        })
        .catch(error => {
            if (error.response?.status)
                setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
            else
                setErrorMessage(t('Error, service unavailable. Please try again later.'));
            setSubmitDisabled(false);
        });
    }


    return { data, errorMessage, isSubmitDisabled, notificationModal, presentNotificationModal, onSubmit, onChange, toggleShowPassword, showPassword }
}

export default InitResetPasswordFormLogic;
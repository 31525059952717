import IPage from "../../../../interfaces/page"
import AdminFilesComponent from "../../../Admin/Files/Files.component"
import MainLayout from "../../../layouts/Main/Main.layout"



const AdminFilesPage: React.FC<IPage> = () => {
    return <MainLayout component={AdminFilesComponent} />
}

export default AdminFilesPage
import IFyk from "../../interfaces/fyk"
import { clientInstance } from "./clientInstance"


const useFykApiService = () => {
    const client = clientInstance()


    const getAllFyks = () => 
        client.get(`/fyks/get`)

    const getFyksByPage = (page: number, rowsPerPage: number) =>
        client.get<any>(`/fyks/get?page=${page}&size=${rowsPerPage}&sort=value,asc`)

    const getFykById = (id: number) =>
        client.get(`/fyks/get/${id}`)

    const updateFyk = (formValue: IFyk) => 
        client.post(`/fyks/update`, formValue)
    
    const createFyk = (formValue: IFyk) => 
        client.post(`/fyks/create`, formValue)

    const deleteFykRequest = (id: number) =>
        client.delete(`/fyks/delete/${id}`)
    
    
    return {getAllFyks, getFykById, updateFyk, createFyk, deleteFykRequest, getFyksByPage}
}

export default useFykApiService
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ProfileType } from '../../../enums/ProfileType';
import IPage from '../../../interfaces/page';
import IWallProfile from '../../../interfaces/wallProfile';
import globalStyles from '../../../styles/Global.module.css';
import useWallProfileApiService from '../../../Wave3/ApiService/wallProfileApiService';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import InputShared from '../../../Wave3/SharedComponents/InputShared/InputShared';
import loginStyles from '../../Login/Login.module.css';
import styles from '../../Login/Login.module.css';


const CreateEditWallProfilePage: React.FunctionComponent<IPage> = () => {
    let { id } = useParams();
    let navigate = useNavigate();

    const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

    const [errorMessage, setErrorMessage] = useState<string>("");

    const [formValue, setformValue] = React.useState<IWallProfile>({
        id: null!,
        name: '',
        price: 0,
        depth: 0,
        fyk: 0,
        materialThickness: 0,
        selectedProducts: [],
        profiles: [],
        profileType: ProfileType.L,
        length: 0,
        sku:''
    });
    
    const {getWallProfileById, updateWallProfile, createWallProfile} = useWallProfileApiService()

    useEffect(() => {
        if (id) {
            getWallProfileById(Number(id))
                .then(responseWallProfiles => {
                    setformValue(responseWallProfiles.data);

                }).catch(error => {
                    navigate(`/wall-profiles`)
                })
        }
        //eslint-disable-next-line
    }, [id, navigate])

    const handleChange = (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLSelectElement>) => {
        setformValue({
            ...formValue,
            [event.currentTarget.name]: event.currentTarget.value
        });
    }

    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setSubmitDisabled(true);
        if (id) {
            updateWallProfile(formValue)
                .then(response => {
                    navigate(`/wall-profiles`);
                })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 400) {
                            setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                        }
                        else if (error.response.status === 404) {
                            setErrorMessage(t("Wall profile not found"));
                        }
                        else {
                            setErrorMessage(t("Internal server error. Please try again later."));
                        }
                    }
                    else
                        setErrorMessage(t("Error, service unavailable. Please try again later."));

                    setSubmitDisabled(false);
                });
        }
        else {
            createWallProfile(formValue)
                .then(response => {
                    navigate(`/wall-profiles`);
                })
                .catch(error => {
                    if (error.response?.status) {
                        if (error.response.status === 400) {
                            setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                        }
                        else {
                            setErrorMessage(t("Internal server error. Please try again later."));
                        }
                    }
                    else
                        setErrorMessage(t("Error, service unavailable. Please try again later."));

                    setSubmitDisabled(false);
                });
        }
    }

    return (
        <>
                            <NavigationComponent />

            <div className={globalStyles.layout}>
                <div className={styles.form__container}>
                    <form className={styles.form} onSubmit={handleSubmit}>
                        {errorMessage !== "" &&
                            <p className={loginStyles.error__message}>{errorMessage}</p>
                        }
                        <div className={loginStyles.form__header}>
                            <h2 className={loginStyles.form__title}>{id ? t("Edit wall profile") : t("Create wall profile")}</h2>
                            <p className={loginStyles.form__helper}><span style={{color: "#E42313"}}>*</span>{t("indicates a required field")}</p>
                        </div>

                        <InputShared name="name" labelText='Name' value={formValue.name} type="text" required={true} onChange={handleChange} />
                        <InputShared name='price' labelText='Price' value={formValue.price.toString()} type="number" step=".01" min="0" required={true} onChange={handleChange} />
                        <InputShared name='sku' labelText='SKU' value={formValue.sku} type="text" required={true} onChange={handleChange} />
                        <InputShared name="depth" labelText='Depth' value={formValue.depth.toString()} type="number" step='.01' min='0' required={true} onChange={handleChange} />
                        <InputShared name="fyk" labelText='fyk' value={formValue.fyk.toString()} type="number" step='.01' min='0' required={true} onChange={handleChange} />
                        <InputShared name="materialThickness" labelText='Material thickness' value={formValue.materialThickness.toString()} type="number" step='.01' min='0' required={true} onChange={handleChange} />
                        <InputShared name="length" labelText='Length' value={formValue.length.toString()} type="number" step='.01' min='0' required={true} onChange={handleChange} />

                        <div className={globalStyles.input}>
                            <label>{t("Type")}  <span aria-hidden="true">*</span></label>
                            <div>
                                <input value={ProfileType.L}
                                    id={ProfileType.L}
                                    name="profileType" type="radio"
                                    checked={formValue.profileType === ProfileType.L}
                                    onChange={handleChange} />
                                <label htmlFor={ProfileType.L}> {t('L')}</label>
                                <br />
                                <input value={ProfileType.C}
                                    id={ProfileType.C}
                                    name="profileType" type="radio"
                                    checked={formValue.profileType === ProfileType.C}
                                    onChange={handleChange} />
                                <label htmlFor={ProfileType.C}> {t('C')}</label>
                                <br />
                                <input value={ProfileType.U}
                                    id={ProfileType.U}
                                    name="profileType" type="radio"
                                    checked={formValue.profileType === ProfileType.U}
                                    onChange={handleChange} />
                                <label htmlFor={ProfileType.U}> {t("U")}</label>
                            </div>
                        </div>
                        {id && <Link to={`/images/wall-profile/${id}`}><button type="button" className="button-secondary">{t("Images")}</button></Link>}

                        <div className={loginStyles.form__bottom}>
                            <Link to={`/wall-profiles`}><button type="button" className="button-secondary-link">{t("Cancel")}</button></Link>
                            <button disabled={submitDisabled} type="submit">{t("Save")}</button>
                        </div>
                    </form>
                </div >
            </div >
        </>
    )
}

export default CreateEditWallProfilePage;
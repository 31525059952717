import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import IRoom from "../../../interfaces/room";
import useRoomApiService from "../../ApiService/roomApiService";
import imageHelper from "../../helpers/imageHelper";
import logo from '../../../assets/images/logo.svg'
import logo2 from '../../../assets/images/edificio.jpg'
import watermark from '../../../assets/images/watermark-calc.png'

const InitPrintCalculationLogic = (params: IRoom) => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] =useState<boolean>(true);
    const { getCalculationOverview } = useRoomApiService();

    const printRef = useRef<any>(null!);
    const handlePrint = useReactToPrint({
        onBeforeGetContent: () => {
            setLoading(true);
            document.title = `MyEdificio_${params.code}_${params.name}`;
            imageHelper.preloadImage(watermark);
            imageHelper.preloadImage(logo);
            imageHelper.preloadImage(logo2);
        },
        onAfterPrint: ()=>{
            document.title = "MyEdificio";
            setLoading(false);
        },
        content: () => printRef.current,
        copyStyles: true,
    });

    useEffect(() => {
        getCalculationOverview(params.id).then(res => {
            setData(res.data);
            setLoading(false);
        }).catch(err => {
            console.log(err)
        })
    }, [setData, setLoading])

    return { data,loading, printRef, handlePrint }
}

export default InitPrintCalculationLogic;
import styles from "./PasswordChecker.module.css";
import InitPasswordCheckerLogic from "./passwordChecker.logic";
const PasswordChecker = (props: { password: string }) => {
  const {className, passed, passwordStatus, passwordClass, showInfo, getErrorMessage} = InitPasswordCheckerLogic(props.password)


  return (
    <>
      <div className={styles.password__check__container}>
        <div
          className={
            passed > 0
              ? `${styles.strength__bar} ${className}`
              : `${styles.strength__bar} ${styles.empty__strength}`
          }
        >
        </div>
        <div
          className={
            passed > 1
              ? `${styles.strength__bar} ${className}`
              : `${styles.strength__bar} ${styles.empty__strength}`
          }
        >
        </div>
        <div
          className={
            passed > 2
              ? `${styles.strength__bar} ${className}`
              : `${styles.strength__bar} ${styles.empty__strength}`
          }
        >
        </div>
        <div
          className={
            passed > 3 && props.password.length >= 10
              ? `${styles.strength__bar} ${className}`
              : `${styles.strength__bar} ${styles.empty__strength}`
          }
        >
        </div>
      </div>
      <div className={`${styles.password__status} ${passwordClass}`}>
        {passwordStatus}{" "}
      </div>
      <div className={passwordClass} style={{fontSize: "12px", marginTop: "6px"}}>

        {showInfo && getErrorMessage()}
          </div>
      <br></br>
    </>
  );
};



export default PasswordChecker;

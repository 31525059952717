import { t } from "i18next";
import { TiltProtection } from "../../../../enums/tiltProtection";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IRoomOverview from "../../../Interfaces/roomOverview";
import CRStep7Visualization from "../../RoomForm/Step7/Visualization/CRStep7Visualization.component";
import styles from './AntiTiltOverview.module.css'

const AntiTiltOverviewComponent: React.FC<IRoomOverview> = (params: IRoomOverview) => {
    return (
        <div>

            <input onChange={() => { }} name={`AntiTiltOverviewComponent${params.id}`} checked type="radio" />
            <label htmlFor='AntiTiltOverviewComponent'>
                {params.tiltProtection === TiltProtection.ANTITILT_ANGLE &&
                    <span>{t('Anti-tilt angle on top side')}</span>}
                {params.tiltProtection === TiltProtection.STIFFENING_PLANKING &&
                    <span>{t('Stiffening planking on the top side')}</span>}
            </label>


            <div className={styles.overview__container}>
                <CRStep7Visualization  {...{ ...params as unknown as IRoomFormData }} />
            </div>
        </div>
    )
}

export default AntiTiltOverviewComponent;
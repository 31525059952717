import { t } from "i18next";
import { useState } from "react";
import useRoomApiService from "../../../ApiService/roomApiService";
import IModal from "../../../Interfaces/modal";
import IProjectItemComponent from "../../../Interfaces/projectItemComponent";
import { useCartStore } from "../../../Stores/cart.store";
import styles from "./ProjectItem.module.css";
import { RoomStatus } from "../../../../enums/roomStatus";
import { useNavigate } from "react-router-dom";
import { OrderStatus } from "../../../../enums/orderStatus";

const InitProjectItemLogic = (props: IProjectItemComponent) => {
  const { recoverProject, removeRoomFromCart } = useRoomApiService();
  const removeProjectFromCart = useCartStore((state) => state.removeFromCart);
  const removeProjectFromCartWithoutNotification = useCartStore(
    (state) => state.removeProjectFromCartWithoutNotification
  );
  const navigate = useNavigate();
  const { deleteRoomRequest } = useRoomApiService();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const deleteModal: IModal = {
    cancelAction: () => {
      setShowDeleteModal(false);
    },
    confirmAction: async() => {
      if (props.item.status === RoomStatus.INTRASH)
        await deleteRoomRequest(props.item.id as number).then(() => {
          setShowDeleteModal(false);
          props.deleteItem!(props.item.id);
        }).catch(error => alert(error));
      else
        await removeRoomFromCart(props.item.id as number).then(() => {
          if (props.item.status === RoomStatus.INCART)
            removeProjectFromCartWithoutNotification();

          props.deleteItem!(props.item.id);
          setShowDeleteModal(false);
        }).catch(error => alert(error));
    },
    
    confirmText: t("Confirm"),
    showCancel: true,
    title:
      props.item.status === RoomStatus.INTRASH
        ? t("Delete project")
        : t("Move project to trash"),
    message: t("Are you sure about this action?"),
    image: null!,
  };
  
  const getStatusClass = () => {
    if (props.item.status === RoomStatus.DRAFT) {
      return styles.status__draft;
    } else if (props.item.status === RoomStatus.READY) {
      return styles.status__ready;
    } else if (props.item.status === RoomStatus.INCART) {
      return styles.status__ready;
    } else if (props.item.status === RoomStatus.INTRASH) {
      return styles.status__trash;
    } else {
      return styles.status__submitted;
    }
  };
  const onClickRecoverProject = (e: React.MouseEvent) => {
    e.preventDefault();
    setTimeout(() => {
      recoverProject(Number(props.item.id))
        .then((r) => {
          let newItem = { ...props.item, status: RoomStatus.DRAFT };
          props.updateItem!(newItem);
        })
        .catch((e) => {
          console.log(e);
        });
    }, 100);
  };

  const removeFromCart = (e: React.MouseEvent) => {
    e.preventDefault();
    setTimeout(() => {
      removeRoomFromCart(Number(props.item.id)).then((r) => {
        removeProjectFromCartWithoutNotification();
        let newItem = { ...props.item, status: RoomStatus.INTRASH };
        props.updateItem!(newItem);
      });
    }, 100);
  };

  const goToCheckout = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(`/checkout/${props.item.id}`);
  };

  const getItemUrl = () => {
    if (props.item.nextStep < 8) {
      return `/room?step=${props.item.nextStep}&roomId=${props.item.id}`;
    } else if (props.item.nextStep < 10) {
      return `/room/${props.item.id}/product?step=1`;
    } else {
      return `/room/${props.item.id}`;
    }
  };
  return {
    getStatusClass,
    onClickRecoverProject,
    removeFromCart,
    getItemUrl,
    deleteRoomRequest,
    setShowDeleteModal,
    showDeleteModal,
    deleteModal,
    goToCheckout,
  };
};

export default InitProjectItemLogic;

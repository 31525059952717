import { useEffect } from "react";
import INotificationModal from "../../Interfaces/notificationModal";

const InitNotificationModalLogic = (props: INotificationModal) => {
    useEffect(() => {
        document.body.style.overflowY = "hidden";
    })

    const close = () => {
        document.body.style.overflowY = "auto";
        props.close();
    }

    const action = () => {
        document.body.style.overflowY = "auto";
        props.action();
        props.close();
    }
    return {close, action};
}
export default InitNotificationModalLogic;
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IUser from "../../../interfaces/user";
import dataHandlerHelper from "../../helpers/dataHandler.helper";
import tokenHelper from "../../helpers/tokenHelper";
import { useGoogleLogin } from "@react-oauth/google";
import useUserApiService from "../../ApiService/userApiService";

const InitLoginFormLogic = () => {

    const [isLoading, setLoading] = useState<boolean>(true);
    const [disableLogin, setDisableLogin] = useState<boolean>(false);
    const [errorMessageTranslationKey, setErrorMessageTranslationKey] = useState<string>("");
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [data, setData] = useState<IUser>({ username: "", password: "" })
    const navigate = useNavigate();
    const {formLogin, loadUserProfile, successfulGoogleLogin} = useUserApiService()

    const googleLogin = useGoogleLogin({
        onSuccess: (e) => successfulGoogleLogin(e, rememberMe, setDisableLogin, setErrorMessageTranslationKey),
        onError: () => setErrorMessageTranslationKey('gsdf'),
    });
    
    const onSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setErrorMessageTranslationKey("");
        setDisableLogin(true);
        formLogin(rememberMe, data).then(
            response => {
                tokenHelper.storeToken(response.data.token)                
                // if(response.data.isUserNew === true) navigate("/room")
                // else 
                navigate('/dashboard');
            }).catch(error => {
                setDisableLogin(false);
                if (error.response?.status)
                    setErrorMessageTranslationKey("Incorrect email address and / or password.");
                else
                    setErrorMessageTranslationKey("Error, service unavailable. Please try again later.");
            })
    }

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        let newData = dataHandlerHelper.handleInputChange(event, { ...data });
        setData(newData);
    }

    useEffect(() => {
        if (isLoading && tokenHelper.getToken() !== null) {
            loadUserProfile().then(response => {
                setLoading(false)
                if (response.data != null) {
                    navigate("/dashboard", { replace: true });
                }
            }).catch(e => {
                tokenHelper.removeToken()
                setLoading(false);
            })
        }
        else {
            setLoading(false)
        }
        //eslint-disable-next-line
    }, [setLoading, isLoading, navigate])

    return { onSubmit, onChange, disableLogin, errorMessageTranslationKey, data, googleLogin, rememberMe, setRememberMe }
}

export default InitLoginFormLogic;
import { t } from 'i18next';
import styles from './UserUpdatePasswordForm.module.css'

import NotificationModalComponent from '../NotificationModal/NotificationModal.component';
import InitUserUpdatePasswordFormLogic from './UserUpdatePasswordForm.logic';
import InputShared from '../../SharedComponents/InputShared/InputShared';
import ButtonShared from '../../SharedComponents/ButtonShared/ButtonShared';

const UserUpdatePasswordFormComponent: React.FC = () => {
    const { data, external, errorMessage, notificationModal, isSubmitDisabled, presentNotificationModal, onSubmit, onChange,toggleShowPassword, showPassword } = InitUserUpdatePasswordFormLogic();

    return (
        <>{data !== null &&
            <form className={styles.form} onSubmit={onSubmit}>
                <h2 className={styles.title}>{t('Update password')}</h2>
                <p className={styles.description}><span>*</span> {t('indicates a required field')}</p>


                {!external && 
                <>
                    <label className={styles.label} htmlFor="address">{t("Current password")} <span>*</span></label>
                    <input onChange={onChange} className={styles.input} type="password" name="currentPassword" id="currentPassword"
                        placeholder='*******' value={data.currentPassword} required /></>}
                <InputShared name="newPassword" labelText={t("New password")} required={true} onChange={onChange} type="password" placeholder='*******' value={data.newPassword} 
                    mode="step" pass_strong={true} showHide={true} showPasswordGroup={showPassword} toggleGroupShow={toggleShowPassword} />
                <InputShared type="password" name="repeatPassword" placeholder='*******' value={data.repeatPassword} required={true} onChange={onChange} labelText={t("Repeat new password")} 
                mode="settings" showPasswordGroup={showPassword} toggleGroupShow={toggleShowPassword} />

                {errorMessage !== '' && <p className={styles.error__message}>{errorMessage}</p>}

                <ButtonShared disabled={isSubmitDisabled} type="submit" text={t("Save")} wide={true}/>
            </form>}
            {presentNotificationModal && <NotificationModalComponent {...notificationModal}></NotificationModalComponent>}
        </>
    )
}

export default UserUpdatePasswordFormComponent;
import { useState } from "react";
import useUserApiService from "../../ApiService/userApiService";
import IUserWithDate from "../../../interfaces/userWithDate";

const InitRegisteredUserComponentLogic = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<IUserWithDate[]>(null!);
  const { getUsersDateInterval } = useUserApiService();

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
  };

  const formatDateForAPI = (date: Date | null): string => {
    if (!date) return "";
    return date.toISOString().split("T")[0];
  };

  const fetchUsers = async () => {
    if (!startDate || !endDate) return;
    setIsLoading(true);
    try {
      const apiStartDate = formatDateForAPI(startDate);
      const apiEndDate = formatDateForAPI(endDate);
      const response = await getUsersDateInterval(apiStartDate, apiEndDate);
      if (!response.data.empty) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    fetchUsers,
    isLoading,
    users,
  };
};

export default InitRegisteredUserComponentLogic;

import { t } from "i18next";
import React from 'react';
import { availableLanguages } from "../../../translation/i18n";
import InitLanguageSwitchLogic from "./LanguageSwitch.logic";
import styles from './LanguageSwitch.module.css';


const LanguageSwitchComponent: React.FunctionComponent = () => {
    const { handleChange, language } = InitLanguageSwitchLogic();

    return (
        <div className={styles.container}>
            <select aria-label={t("Select language")}
                value={language}
                className={styles.switch__item} onChange={handleChange}>
                {availableLanguages.map((language) => (
                    <option key={language}>{language}</option>
                ))}
            </select>
        </div>
    )
}

export default LanguageSwitchComponent;
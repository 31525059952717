import { useEffect, useRef, useState } from "react";
import ISlide from "../../Interfaces/slide";

const InitSliderLogic = (props: ISlide[]) => {

    const [currentSlide, setCurrentSlide] = useState<ISlide>(null!);
    const slider = useRef<HTMLImageElement>(null!);
    let timer: any;

    const present = (index: number) => {
        slider.current.style.opacity = "0";
        clearTimeout(timer);
        timer = setTimeout(() => {
            setCurrentSlide(null!)
            setCurrentSlide(props[index]);
            slider.current.style.opacity = "1";
        }, 210);
    }

    useEffect(() => {
        if (props !== null && props.length > 0) {
            setCurrentSlide(props[0]);
        }

    }, [setCurrentSlide, props])


    return { currentSlide, present, slider }
}

export default InitSliderLogic
import i18next, { t } from "i18next";
import mainLogo from "../../../assets/images/logo.svg";
import secondaryLogo from "../../../assets/images/edificio.jpg";
import watermark from "../../../assets/images/watermark.png";
import noImage from "../../../assets/images/wave3/noimage.svg";

import IOrder from "../../Interfaces/order";
import RoomOverviewItemComponent from "../RoomOverview/RoomOverviewItem/RoomOverviewItem.component";
import InitPrintOrderLogic from "./PrintOrder.logic";
import styles from "./PrintOrder.module.css";
import { SpanDirection } from "../../../enums/spanDirection";
import roomHelper from "../../helpers/roomHelper";
import { DeflectionLimit } from "../../../enums/deflectionLimit";
import IRoomFormData from "../../Interfaces/roomFormData";
import { Walkability } from "../../../enums/walkability";
import { TiltProtection } from "../../../enums/tiltProtection";
import AntiTiltAngleOverviewComponent from "../RoomOverview/ProductOverview/AntiTiltAngle.component";
import Screws2OverviewComponent from "../RoomOverview/ProductOverview/Screws2Overview.component";
import Screws1OverviewComponent from "../RoomOverview/ProductOverview/Screws1Overview.component";
import UtilsHelper from "../../../helpers/utils.helper";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const PrintOrderComponent: React.FunctionComponent<IOrder> = (
  params: IOrder
) => {
  const {handleExcelMultipleSheets, handleExcelSingleSheet } =
    InitPrintOrderLogic(params);

  return (
    <>
      <button className={styles.print__button} onClick={handleExcelMultipleSheets}>
          <FontAwesomeIcon icon={faPrint} style={{ marginRight: "6px" }} />
          {t("Export to Multi Sheet Excel")}
      </button>
      <div style={{ height: "10px" }}></div>
      <button className={styles.print__button} onClick={handleExcelSingleSheet}>
          <FontAwesomeIcon icon={faPrint} style={{ marginRight: "6px" }} />
          {t("Export to Single Sheet Excel")}
      </button>
    </>
  );
};

export default PrintOrderComponent;

import React from 'react'
import StepControlsComponent from './StepControls/StepControls.component';
import StepHeaderComponent from './StepHeader/StepHeader.component';
import InitStepFormLogic from './StepForm.logic';
import IStepForm from '../../Interfaces/StepForm';

const StepFormComponent: React.FunctionComponent<IStepForm> = (params: IStepForm) => {
    const { controls, handlers } = InitStepFormLogic(params);
    return (
        <>
            <><div {...handlers}>
                <StepHeaderComponent {...params.header} />
                {params.components.map((step, index) => {
                    if (index === controls.currentStep - 1) { return (<div key={index}><step.component  {...step.params} /></div>) }
                    else return null
                })}
            </div>
                <StepControlsComponent {...controls} />
                </>
        </>
    )
}

export default StepFormComponent;
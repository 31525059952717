import Config from "../../config/config"
import IConfiguration from "../../interfaces/configuration"
import { clientInstance } from "./clientInstance"


const useConfigurationApiService = () => {
    const client = clientInstance()

    const getAllConfiguration = () => 
        client.get<IConfiguration[]>(`${Config.defaults.apiUrl}/configuration/getAll`)

    const updateConfiguration = (configuration: IConfiguration) => 
        client.put(`${Config.defaults.apiUrl}/configuration/update`, configuration)

    const createConfiguration = (configuration: IConfiguration) =>
        client.post(`${Config.defaults.apiUrl}/configuration/create`, configuration)

    const deleteConfiguration = (configurationKey: string) => 
        client.delete(`${Config.defaults.apiUrl}/configuration/delete/${configurationKey}`) 

    const getConfigurationByKey = (key: string) =>
        client.get<IConfiguration>(`${Config.defaults.apiUrl}/configuration/get/${key}`)

    const getConfigurationByPage = (page: number, rowsPerPage: number) =>
    client.get<any>(`${Config.defaults.apiUrl}/configuration/getAll?page=${page}&size=${rowsPerPage}&sort=id,asc`)

    return {getAllConfiguration, updateConfiguration, createConfiguration, deleteConfiguration, getConfigurationByKey, getConfigurationByPage}
}

export default useConfigurationApiService
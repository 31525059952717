import { SpanDirection } from "../../enums/spanDirection";
import IRoomFormData from "../Interfaces/roomFormData";

const handleInputChange = (event: React.FormEvent<HTMLInputElement>, data: any) => {
    return { ...data, [event.currentTarget.name]: event.currentTarget.type === 'checkbox' ? event.currentTarget.checked : event.currentTarget.value }
}

const calculateDeflectionLimit = (params: IRoomFormData, dl: number) => {
    let width = params?.width
    let length = params.spanDirection === SpanDirection.ROOM_LENGTH ? params.length : width;
    return Number(length) / dl;
}

const dataHandlerHelper = {
    handleInputChange,
    calculateDeflectionLimit
}

export default dataHandlerHelper;
import useUserApiService from "../../ApiService/userApiService";
import i18n  from "i18next";
import { useEffect, useState } from "react";
import { availableLanguages } from "../../../translation/i18n";
import useUserHelper from "../../helpers/userHelper";

const InitLanguageSwitchLogic = () => {
    const { changeUserLanguage } = useUserApiService();
    const [language, setLanguage] = useState<string>();
    const { retrieveUser } = useUserHelper();

    useEffect(() => {
        if(!availableLanguages.includes(i18n.language)){
            i18n.changeLanguage(i18n.options.fallbackLng?.toString())
        }
        setLanguage(i18n.language);
    }, [])

    const handleChange = (e: React.FormEvent<HTMLSelectElement>) => {
        e.preventDefault();
        let newValue =e.currentTarget.value;
        if (availableLanguages.includes(newValue)) {
            if (retrieveUser() !== null) {
                changeUserLanguage({ language: newValue}).then(r => {
                    i18n.changeLanguage(newValue);
                });
            }
            else{
                i18n.changeLanguage(newValue);
            }
            setLanguage(newValue)
        }        
    }

    return { handleChange, language }
}
export default InitLanguageSwitchLogic;
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dataHandlerHelper from "../../../helpers/dataHandler.helper";
import IRoomFormData from "../../../Interfaces/roomFormData";
import IStep from "../../../Interfaces/Step";
import useRoomApiService from "../../../ApiService/roomApiService";
import useBackButtonHelper from "../../../helpers/backButtonHelper";
const InitCRStep1Logic = (params: IStep<IRoomFormData>) => {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const {updateRoom} = useRoomApiService()
    useEffect(() => {
        disableNext();
        if (searchParams.get('mode') === 'edit') {
            params.options?.setBackText(t("Or back to project overview"))
        } else if (searchParams.get('mode') === 'redirection') {
            params.options?.setHideBack(true);
        }
        else {
            params.options?.setHideBack(false);
        }
    });
    
    useEffect(() => {
        if (params.data.id !== -1 && params.data.name !== "") {
            setIsLoading(true)
            updateRoom(params.data, setIsLoading, params.options.setAllowSkipTo, params.setData);
        }
        if(params.setShouldSetBackButton) params.setShouldSetBackButton(false)
        // eslint-disable-next-line 
      }, []);

      
    const disableNext = () => {
        params.setDisableNext(params.data.name.length < 1, 0);
    }

    const {resetBackButtonToDashboardState} = useBackButtonHelper()
    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        let newData = dataHandlerHelper.handleInputChange(event, { ...params.data });
        params.options.setAllowSkipTo(1)
        params.setData({...newData, nextStep: 1});
        disableNext();
        if(params.setShouldSetBackButton) resetBackButtonToDashboardState(params.setShouldSetBackButton)
        // enable alert on tab exit/close!
    }
    return { onChange, isLoading };
}


export default InitCRStep1Logic;
import { t } from 'i18next';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import LoadingComponent from '../../../Wave3/Components/Loading/Loading.component';
import ModalComponent from '../../../Wave3/Components/Modal/Modal.component';
import Pagination from '../../../Wave3/Components/Table/Pagination.component';
import Table from '../../../Wave3/Components/Table/Table.component';
import IFyk from '../../../interfaces/fyk';
import IModal from '../../../Wave3/Interfaces/modal';
import IPage from '../../../interfaces/page';
import globalStyles from '../../../styles/Global.module.css';
import NavigationComponent from '../../../Wave3/Components/Navigation/Navigation.component';
import useFykApiService from '../../../Wave3/ApiService/fykApiService';

const FyksPage: React.FunctionComponent<IPage> = props => {
    const [fyks, setFyks] = useState<IFyk[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);


    const [count, setCount] = useState<number>(0);
    
    const [idForDelete, setIdForDelete] = useState<number>(null!);

    const columns = ["value"];
    const headers = [t("Value")];

    const {deleteFykRequest, getFyksByPage} = useFykApiService()

    const request = useCallback((page: number = 0, rowsPerPage: number = 5) => {
        getFyksByPage(page, rowsPerPage).then((response) => {
            if (!response.data.empty) {
                setFyks(response.data.content);
                setCount(response.data.totalElements);
            }
            setLoading(false);
        });

        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        request();
    }, [request])

    const deleteFyk = (id: number) => {
        return new Promise((resolve: any, reject: any) => {
            deleteFykRequest(id).then(
                (response) => {
                    resolve();
                    window.location.reload();
                },
                (error) => {
                    if (error.response?.status)
                        reject(t(error.response?.data.translatableCode, { ns: "codes" }));
                    else
                        reject(t("Error, service unavailable. Please try again later."));
                }
            )
        })
    }

    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const deleteModal: IModal = {
        cancelAction: () => { setShowDeleteModal(false) },
        confirmAction: () => deleteFyk(idForDelete),
        confirmText: t("Confirm"),
        showCancel: true,
        title: t("Delete fyk"),
        message: t("Are you sure about this action?"),
        image: null!
    }

    const deleteFunction = (id: number) => {
        setShowDeleteModal(true);
        setIdForDelete(id);
    }

    const editPath = "/fyks/edit/";
    const newPath = "/fyks/create/";

    return (
        <>
                            <NavigationComponent />

            <div className={globalStyles.layout}>
                <Fragment>
                    {!isLoading && fyks?.length < 1 &&
                        <div>
                            <div>
                                <p>{t('There is no fyks')}</p>
                            </div>
                        </div>
                    }
                    {isLoading && <LoadingComponent></LoadingComponent>}
                    {!isLoading && fyks?.length > 0 &&
                        <div className={globalStyles.layout}>
                            <Table rows={fyks} columns={columns} headers={headers} new={newPath} edit={editPath} delete={deleteFunction} />
                            <Pagination
                                count={count}
                                rowsPerPage={5}
                                request={request}
                            />
                        </div>
                    }

                    {showDeleteModal && <ModalComponent {...deleteModal}></ModalComponent>}
                </Fragment>
            </div></>
    )
}

export default FyksPage;
import ILayout from "../../Interfaces/layoutComponent";
import styles from './Width800.module.css'
import NavigationComponent from "../../Components/Navigation/Navigation.component";

const Width800Layout: React.FC<ILayout> = (params: ILayout) => {
    return (
        <>
            <NavigationComponent />
            <div className={styles.layout}>
                <div className={styles.content}>
                    <params.component {...{ ...params.props }} />
                </div>
            </div>
        </>
    )
}


export default Width800Layout;

import { useSearchParams } from "react-router-dom";

const useBackButtonHelper = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const pushBackUrl = (url: string, backTranslationKey: string) => {
    let backUrlStack = JSON.parse(
      sessionStorage.getItem("back_url_stack") ?? "[]"
    ) as Array<string>;
    let backTranslationKeyStack = JSON.parse(
      sessionStorage.getItem("back_translation_key_stack") ?? "[]"
    ) as Array<string>;
    backUrlStack.push(url);
    backTranslationKeyStack.push(backTranslationKey);
    sessionStorage.setItem("back_url_stack", JSON.stringify(backUrlStack));
    sessionStorage.setItem(
      "back_translation_key_stack",
      JSON.stringify(backTranslationKeyStack)
    );
  };

  const popBackUrl = () => {
    let backUrlStack = JSON.parse(
      sessionStorage.getItem("back_url_stack") ?? "[]"
    ) as Array<string>;
    let backTranslationKeyStack = JSON.parse(
      sessionStorage.getItem("back_translation_key_stack") ?? "[]"
    ) as Array<string>;
    const backUrl = backUrlStack.pop();
    const backTranslationKey = backTranslationKeyStack.pop();
    sessionStorage.setItem("back_url_stack", JSON.stringify(backUrlStack));
    sessionStorage.setItem(
      "back_translation_key_stack",
      JSON.stringify(backTranslationKeyStack)
    );
    return { backUrl, backTranslationKey };
  };

  const peekBackUrl = () => {
    let backUrlStack = JSON.parse(
      sessionStorage.getItem("back_url_stack") ?? "[]"
    ) as Array<string>;
    let backTranslationKeyStack = JSON.parse(
      sessionStorage.getItem("back_translation_key_stack") ?? "[]"
    ) as Array<string>;
    const backUrl = backUrlStack.slice(-1)[0];
    const backTranslationKey = backTranslationKeyStack.slice(-1)[0];

    return { backUrl, backTranslationKey };
  };

  const clearBackUrls = () => {
    sessionStorage.removeItem("back_url_stack");
    sessionStorage.removeItem("back_translation_key_stack");
  };

  const isOnTopOfStack = (url: string) => {
    const overviewRegex = new RegExp(url);
    const { backUrl } = peekBackUrl();
    return overviewRegex.test(backUrl);
  };

  const resetBackButtonToDashboardState = (
    setShouldSetBackButton: Function
  ) => {
    const { backUrl } = peekBackUrl();
    if (new RegExp("/room/[0-9]+").test(backUrl)) {
      clearBackUrls();
      pushBackUrl("/dashboard", "Back to dashboard");
      setShouldSetBackButton(true);
    }
  };

  return {
    pushBackUrl,
    popBackUrl,
    peekBackUrl,
    clearBackUrls,
    isOnTopOfStack,
    resetBackButtonToDashboardState,
  };
};

export default useBackButtonHelper;

import { Link } from "react-router-dom";
import AntiTiltOverviewComponent from "./AntiTiltOverview/AntiTiltOverview.component";
import CalcOverviewComponent from "./CalcOverview/CalcOverview.component";
import CenterDistanceOverviewComponent from "./CenterDistanceOverview/CenterDistanceOverview.component";
import DeflectionLimitOverviewComponent from "./DeflectionLimitOverview/DeflectionLimitOverview.component";
import GeometryOverviewComponent from "./GeometryOverview/GeometryOverview.component";
import NameOverviewComponent from "./NameOverview/NameOverview.component";
import PermanentLoadsOverviewComponent from "./PermanentLoadsOverview/PermanentLoadsOverview.component";
import ProfileOverviewComponent from "./ProductOverview/ProfileOverview.component";
import styles from "./RoomOverview.module.css";
import RoomOverviewItemComponent from "./RoomOverviewItem/RoomOverviewItem.component";
import TraficLoadOverviewComponent from "./TraficLoadOverview/TraficLoadOverview.component";
import leftIcon from "../../../assets/images/wave3/arrow-left.svg";
import { t } from "i18next";
import ConnectionOverviewComponent from "./ProductOverview/ConnectionOverview.component";
import Screws1OverviewComponent from "./ProductOverview/Screws1Overview.component";
import IRoomOverviewComponent from "../../Interfaces/roomOverviewComponent";
import { TiltProtection } from "../../../enums/tiltProtection";
import AntiTiltAngleOverviewComponent from "./ProductOverview/AntiTiltAngle.component";
import SupportBracketOverviewComponent from "./ProductOverview/SupportBracket.component";
import Screws2OverviewComponent from "./ProductOverview/Screws2Overview.component";
import Screws3OverviewComponent from "./ProductOverview/Screws3Overview.component";
import InitRoomOverviewLogic from "./RoomOverview.logic";
import ButtonShared from "../../SharedComponents/ButtonShared/ButtonShared";
import { faCartPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { RoomStatus } from "../../../enums/roomStatus";

const RoomOverviewComponent: React.FC<IRoomOverviewComponent> = (
  params: IRoomOverviewComponent
) => {
  const {
    roomId,
    searchParams,
    projectStatus,
    addToCart,
    removeFromCart,
    backButtonOnClick,
    backTranslationKey,
    navigate,
  } = InitRoomOverviewLogic(params);

  return (
    <>
      {!params.hideTitle && (
        <h2 className={styles.title}>{t("Project summary")}</h2>
      )}
      {roomId && (
        <div className={styles.description}>
          <p>
            {t(
              "Here you will find the right products for your previously defined requirements."
            )}
          </p>
          <p>
            {t(
              "At the bottom of page you can look at the relevant calculations and save the project, which means that it will then be permanently stored in your projects."
            )}
          </p>
        </div>
      )}
      <div className={styles.grid}>
        <div className={styles.grid__item}>
          <RoomOverviewItemComponent
            {...{
              title: t("Project name"),
              data: params,
              editUrl: `/room?roomId=${roomId}&step=1&mode=edit`,
              visualisation: NameOverviewComponent,
            }}
          />
        </div>

        <div className={styles.grid__item}>
          <RoomOverviewItemComponent
            {...{
              title: t("Project code"),
              data: params,
              editUrl: null!,
              visualisation: () => {
                return <>{params.code}</>;
              },
            }}
          />
        </div>

        {params.selectedProducts?.profile === undefined && (
          <div className={styles.grid__item}>
            <RoomOverviewItemComponent
              {...{
                title: t("This project doesn't have selected products"),
                data: params,
                editUrl: null!,
                visualisation: () => {
                  return (
                    <>
                      {t(
                        "Sorry, there are no products that match your project requirements..."
                      )}
                    </>
                  );
                },
              }}
            />
          </div>
        )}
      </div>

      {params.selectedProducts?.profile !== undefined && (
        <>
          <div className={styles.grid}>
            <div className={styles.grid__item}>
              <RoomOverviewItemComponent
                {...{
                  title: t("Selected wide span beam"),
                  data: params,
                  editUrl: `/room/${roomId}/product`,
                  visualisation: ProfileOverviewComponent,
                }}
              />
            </div>
            <div className={styles.grid__item}>
              <RoomOverviewItemComponent
                {...{
                  title: t("Selected wall connection"),
                  data: params,
                  editUrl: `/room/${roomId}/product?step=2`,
                  visualisation: ConnectionOverviewComponent,
                }}
              />
            </div>
          </div>

          <div className={styles.grid}>
            {params?.selectedProducts.screwsQuantity > 0 && (
              <div className={styles.grid__item}>
                <RoomOverviewItemComponent
                  {...{
                    title: `SuperTeks ${t("screws")}`,
                    data: params,
                    editUrl: null!,
                    visualisation: Screws1OverviewComponent,
                    tag: t("Included in order"),
                  }}
                />
              </div>
            )}

            {params.connectionProductType === "CA" && (
              <div className={styles.grid__item}>
                <RoomOverviewItemComponent
                  {...{
                    title: `Montageset ${t("screws")}`,
                    data: params,
                    editUrl: null!,
                    visualisation: Screws2OverviewComponent,
                    tag: t("Included in order"),
                  }}
                />
              </div>
            )}

            {params.tiltProtection === TiltProtection.ANTITILT_ANGLE && (
              <div className={styles.grid__item}>
                <RoomOverviewItemComponent
                  {...{
                    title: t("Anti tilt angle"),
                    data: params,
                    editUrl: null!,
                    visualisation: AntiTiltAngleOverviewComponent,
                    tag: t("Included in order"),
                  }}
                />
              </div>
            )}

            {params.selectedProducts.profile.width > 97 && params.tiltProtection === TiltProtection.ANTITILT_ANGLE && (
              <div className={styles.grid__item}>
                <RoomOverviewItemComponent
                  {...{
                    title: t("Connection bracket"),
                    data: params,
                    editUrl: null!,
                    visualisation: SupportBracketOverviewComponent,
                    tag: t("Included in order"),
                  }}
                />
              </div>
            )}
            {params.tiltProtection === TiltProtection.STIFFENING_PLANKING && (
              <div className={styles.grid__item}>
                <RoomOverviewItemComponent
                  {...{
                    title: t("SL4 Screws"),
                    data: params,
                    editUrl: null!,
                    visualisation: Screws3OverviewComponent,
                    tag: t("Included in order"),
                  }}
                />
              </div>
            )}
          </div>
        </>
      )}

      <div className={styles.grid}>
        <div className={styles.grid__item}>
          <RoomOverviewItemComponent
            {...{
              title: t("The project geometry"),
              data: params,
              editUrl: `/room?roomId=${roomId}&step=2&mode=edit`,
              visualisation: GeometryOverviewComponent,
            }}
          />
        </div>
        <div className={styles.grid__item}>
          <RoomOverviewItemComponent
            {...{
              title: t("Direction and distance between the wide span beams"),
              data: params,
              editUrl: `/room?roomId=${roomId}&step=3&mode=edit`,
              visualisation: CenterDistanceOverviewComponent,
            }}
          />
        </div>
      </div>

      <div className={styles.grid}>
        <div className={styles.grid__item}>
          <RoomOverviewItemComponent
            {...{
              title: t("Deflection limit"),
              data: params,
              editUrl: `/room?roomId=${roomId}&step=4&mode=edit`,
              visualisation: DeflectionLimitOverviewComponent,
            }}
          />
        </div>
        <div className={styles.grid__item}>
          <RoomOverviewItemComponent
            {...{
              title: t("The traffic loads"),
              data: params,
              editUrl: `/room?roomId=${roomId}&step=5&mode=edit`,
              visualisation: TraficLoadOverviewComponent,
            }}
          />
        </div>
      </div>

      <div className={styles.grid}>
        <div className={styles.grid__item}>
          <RoomOverviewItemComponent
            {...{
              title: t("Permanent loads"),
              data: params,
              editUrl: `/room?roomId=${roomId}&step=6&mode=edit`,
              visualisation: PermanentLoadsOverviewComponent,
            }}
          />
        </div>
        <div className={styles.grid__item}>
          <RoomOverviewItemComponent
            {...{
              title: t("The desired top-side anti-tilt variant"),
              data: params,
              editUrl: `/room?roomId=${roomId}&step=7`,
              visualisation: AntiTiltOverviewComponent,
            }}
          />
        </div>
      </div>

      {(params.selectedProducts?.profile !== undefined && params.status !== RoomStatus.DRAFT && !params.hideCalc) && (
        <div className={styles.accordion}>
          <CalcOverviewComponent {...params} />
        </div>
      )}

      {!params.hideControls && (
        <>
          <div className={styles.controls}>
            {searchParams.get("mode") === "create" && (
              <Link
                to={`/room?roomId=${roomId}`}
                style={{ textDecoration: "none" }}
              >
                <ButtonShared
                  type="button"
                  text={t("Edit project")}
                  img_src={leftIcon}
                  img_side="left"
                  onClick={backButtonOnClick}
                />
              </Link>
            )}

            {searchParams.get("mode") === null &&
              backTranslationKey !== "Back to dashboard" && (
                <ButtonShared
                  onClick={backButtonOnClick}
                  type="button"
                  text={t(String(backTranslationKey ?? "Back to projects"))}
                  img_src={leftIcon}
                  img_side="left"
                />
              )}

            {backTranslationKey === "Back to dashboard" && (
              <div className={styles.controls__back__link__container}>
                <div>
                  <ButtonShared
                    type="button"
                    text={t("Back to dashboard")}
                    img_side={"left"}
                    img_src={leftIcon}
                    onClick={() => navigate("/dashboard")}
                  />
                </div>
              </div>
            )}

            <div className={styles.controls__right}>
              {(projectStatus !== "ORDERED" && projectStatus !== "INCART") && (
                <ButtonShared
                  type="button"
                  img_size={"large"}
                  text={t("Add to cart")}
                  onClick={addToCart}
                  img_fa={faCartPlus}
                />
              )}

              {projectStatus === "INCART" && (
                <ButtonShared
                  type="button"
                  img_size={"large"}
                  text={t("Remove from cart")}
                  onClick={removeFromCart}
                  img_fa={faXmark}
                />
              )}
            </div>
          </div>
          {backTranslationKey !== "Back to dashboard" && (
            <div className={styles.controls__back__link__container}>
              <div>
                <ButtonShared
                  type="button"
                  text={t("Back to dashboard")}
                  img_side={"left"}
                  img_src={leftIcon}
                  onClick={() => navigate("/dashboard")}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RoomOverviewComponent;

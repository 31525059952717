import i18n from 'i18next'
import IRoomFormData from "../../../Interfaces/roomFormData";

import widthIcon from '../../../../assets/images/wave3/visualization/width.svg';
import lengthIcon from '../../../../assets/images/wave3/visualization/length.svg';

import widthIconSR from '../../../../assets/images/wave3/visualization/rs-width-wht.svg';
import lengthIconSR from '../../../../assets/images/wave3/visualization/rs-length-wht.svg';

import widthIconDE from '../../../../assets/images/wave3/visualization/de-width-wht.svg';
import lengthIconDE from '../../../../assets/images/wave3/visualization/de-length-wht.svg';


const InitArrowsVisualizationLogic = (params: IRoomFormData) => {
    const getWidthImage = () => {
        if (i18n.language === "SR") {
            return widthIconSR;
        }
        else if (i18n.language === "DE") {
            return widthIconDE;
        }
        else {
            return widthIcon;
        }
    }

    const getLengthImage = () => {
        if (i18n.language === "SR") {
            return lengthIconSR;
        }
        else if (i18n.language === "DE") {
            return lengthIconDE;
        }
        else {
            return lengthIcon;
        }
    }
    return { getWidthImage, getLengthImage };
}


export default InitArrowsVisualizationLogic;
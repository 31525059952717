import React from 'react'
import IErrorPage from '../../interfaces/errorPage';
import InitErrorPageLogic from './ErrorPage.logic';


const ErrorPage: React.FunctionComponent<IErrorPage> = props => {
    const {status, message} = InitErrorPageLogic()

    return (
        <div>
            <h1>{`Error ${status ?? props.code}`}</h1>
            <h2>{`${message ?? props.error} `}</h2>
        </div>
    );
}

export default ErrorPage;
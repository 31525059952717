import { t } from "i18next";
import { useState } from "react"
import IUser from "../../../interfaces/user";
import dataHandlerHelper from "../../helpers/dataHandler.helper";
import mailIcon from '../../../assets/images/wave3/mail.svg'
import { useNavigate } from "react-router-dom";
import useUserApiService from "../../ApiService/userApiService";
import INotificationModal from "../../Interfaces/notificationModal";

const InitForgotPasswordFormLogic = () => {
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
    const {sendPasswordRecoveryLink} = useUserApiService()
    const defaultData: IUser = {
        password: "",
        username: "",
    }
    const [data, setData] = useState<IUser>(defaultData);

    const onChange = (event: React.FormEvent<HTMLInputElement>) => {
        let newData = dataHandlerHelper.handleInputChange(event, { ...data });
        setData(newData);
    }
    
    const navigate = useNavigate();
    const [presentNotificationModal, setPresentNotificationModal] = useState<boolean>(false);
    const notificationModal: INotificationModal = {
        action: () => { setPresentNotificationModal(false); navigate('/') },
        close: () => { setPresentNotificationModal(false); setData(defaultData) },
        actionText: t('Back to login'),
        description: `${t("Password reset email has been sent to")} [${data.username}]. <br> ${t("Please follow further instructions and click the link to recover your account")}.`,
        icon: mailIcon,
        title: t("Email has been sent")
    }

    const onSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault();
        setErrorMessage("");
        setSubmitDisabled(true);
        sendPasswordRecoveryLink(data).then(response => {
            setPresentNotificationModal(true);
            setSubmitDisabled(false);
        })
        .catch(error => {
            if (error.response?.status) {
                if (error.response.status === 404) {
                    setErrorMessage(t(error.response?.data.translatableCode, { ns: "codes" }));
                }
                else {
                    setErrorMessage(t('Internal server error. Please try again later.'));
                }
            }
            else
                setErrorMessage(t('Error, service unavailable. Please try again later.'));

            setSubmitDisabled(false);
        });
    }


    return { data, errorMessage, notificationModal, presentNotificationModal, isSubmitDisabled, onSubmit, onChange }
}

export default InitForgotPasswordFormLogic;
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./translations/en.json";
// import sr from "./translations/sr.json";
import de from "./translations/de.json";

const resources = {
  "EN": en,
  // "SR": sr,
  "DE": de
}

export const availableLanguages = Object.keys(resources)

const lang = localStorage.getItem('i18nextLng')

i18n.use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    debug:false,
    lng: lang ?? "DE",
    defaultNS: "common",
    fallbackLng: "DE",
  });
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFileApiService from "../../ApiService/fileApiService";
import useOrderApiService from "../../ApiService/orderApiService";
import useBackButtonHelper from "../../helpers/backButtonHelper";
import useUserHelper from "../../helpers/userHelper";
import IOrder from "../../Interfaces/order";
import ISelectOption from "../../Interfaces/selectOption";
import IFileInfo from "../../../interfaces/fileInfo";
import IModal from "../../Interfaces/modal";
import { OrderCategory } from "../../../enums/orderCategory";
import { OrderStatus } from "../../../enums/orderStatus";

const InitOrderLogic = () => {
  const { isAdmin } = useUserHelper();
  let { id } = useParams();
  const { deleteFile } = useFileApiService();
  const { getById, updateStatus, clientOfferAction, uploadFile } = useOrderApiService();
  const [order, setOrder] = useState<IOrder>(null!);
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);
  const [statusChanged, setStatusChanged] = useState<boolean>(false);
  const [statusMsg, setStatusMsg] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<ISelectOption>(null!);
  const { clearBackUrls, pushBackUrl } = useBackButtonHelper();
  const fileUploadRef = useRef<HTMLInputElement | null>(null!);
  const [statusSelectionChanged, setStatusSelectionChanged] =
    useState<boolean>(false);
  const [fileForDelete, setFileForDelete] = useState<IFileInfo>(null!);
  const [shouldShowChoiceModal, setShouldShowChoiceModal] =
    useState<boolean>(false);
  const [orderCategory, setOrderCategory] = useState<OrderCategory>(
    OrderCategory.ALL
  );



  const [showDeletePdfModal, setShowDeletePdfModal] = useState<boolean>(false);
  const deleteModal: IModal = {
    cancelAction: () => {
      setShowDeletePdfModal(false);
    },
    confirmAction: () => {
      return new Promise((resolve: any, reject: any) => {
        deleteFile(fileForDelete).then((r) => {
          getOrder();
          setShowDeletePdfModal(false);
          resolve();
        }).catch(e => {
          alert("Error during delete file action, please reload page and try it again.")
          if (e.response?.status)
            reject(t(e.response?.data.translatableCode, { ns: "codes" }));
          else
            reject(t("Error, service unavailable. Please try again later."));
        });
      })
    },
    confirmText: t("Confirm"),
    showCancel: true,
    title: t("Delete file"),
    message: t("Are you sure about this action?"),
    image: null!,
  };


  const [choiceActionModalData, setChoiceActionModalData] = useState<{
    confirmText: string;
    title: string;
    chosenStatus: OrderStatus.ORDER | OrderStatus.REJECTED;
  }>(null!);
  const choiceActionModal: IModal = {
    cancelAction: () => {
      setShouldShowChoiceModal(false);
    },
    confirmAction: () => {
      return new Promise((resolve: any, reject: any) => {
        clientOfferAction(
          Number(order.id),
          choiceActionModalData.chosenStatus
        )
          .then((r) => {
            setOrder({ ...order, status: choiceActionModalData.chosenStatus });
            setStatusMsg(null!);
            setShouldShowChoiceModal(false);
            resolve();
          })
          .catch((e) => {
            if (e.response?.status)
              reject(t(e.response?.data.translatableCode, { ns: "codes" }));
            else
              reject(t("Error, service unavailable. Please try again later."));
          });
      })
    },
    confirmText: choiceActionModalData?.confirmText,
    showCancel: true,
    title: choiceActionModalData?.title,
    message: t("Are you sure about this action?"),
    image: null!,
  };

  const [selectOptions, setSelectOptions] = useState<ISelectOption[]>(null!)


  const clientSelectOptions: ISelectOption[] = [
    {
      text: t("Reject"),
      value: OrderStatus.REJECTED,
    },
    {
      text: t("Accept"),
      value: OrderStatus.ORDER,
    },
  ];

  const getDefaultTextForSelectOption = () => {
    if (!isAdmin && order.status === OrderStatus.OFFER) return t("Take action");
    if (order.status === OrderStatus.OFFER)
      return t("Offer");
    if (order.status === OrderStatus.REJECTED) return t("Rejected by customer");
    if (order.status === OrderStatus.DONE) return t("Done");
    if (order.status === OrderStatus.ORDER) return t("Order confirmed");
    if (order.status === OrderStatus.EXECUTION) return t("Executing order");
    if (order.status === OrderStatus.IN_PROGRESS) return t("In progress");

    return t("Pending");
  };

  let navigate = useNavigate();

  useEffect(() => {
    getOrder();
    clearBackUrls();
    pushBackUrl(`/orders/${id}`, "Back to order");
    //eslint-disable-next-line
  }, []);

  const getOrder = () => {
    getById(Number(id))
      .then((r) => {
        const nextStep = r.data.rooms[0].nextStep
        if(nextStep <= 7 && isAdmin()){
          alert(isAdmin())
          navigate(`/room?roomId=${r.data.rooms[0].id}&step=${nextStep}`)
        }
        setOrder(r.data);
        setSelectOptions([
          {
            text: t("In progress"),
            value: OrderStatus.IN_PROGRESS,
          },
          {
            text: t("Pending"),
            value: OrderStatus.PENDING,
          },
          r.data.materialDealer === '-' ? {
            text: t("Offer"),
            value: OrderStatus.OFFER,
          } : null!,
          {
            text: t("Rejected by customer"),
            value: OrderStatus.REJECTED,
          },
          {
            text: t("Order confirmed"),
            value: OrderStatus.ORDER,
          },
          {
            text: t("Executing order"),
            value: OrderStatus.EXECUTION,
          },
          {
            text: t("Done"),
            value: OrderStatus.DONE,
          },
        ].filter(e => e !== null))

        setIsUserAdmin(isAdmin());
        setSelectedOption(
          selectOptions.filter((x) => x.value === r.data.status)[0]
        );
        if ([OrderStatus.DONE, OrderStatus.REJECTED].includes(r.data.status))
          setOrderCategory(OrderCategory.HISTORY);
        else setOrderCategory(OrderCategory.PROCESSING);
      })
      .catch((error) => {
        if (error.status === 404) {
          navigate("/not-found", { replace: true });
        }
      });
  };

  const handleStatusChange = (option: ISelectOption) => {
    setOrder({ ...order, status: option.value });
    setStatusSelectionChanged(true);
    setStatusChanged(false);
  };

  const submitNewStatus = () => {
    setStatusChanged(false);
    updateStatus(order.id, order.status)
      .then((r) => {
        setStatusChanged(true);
        setStatusMsg(null!);
      })
      .catch((r) => {
        setStatusChanged(false);
        setStatusMsg(t("Error..."));
      });
  };

  const takeClientOfferAction = (
    chosenStatus: OrderStatus.REJECTED | OrderStatus.ORDER
  ) => {
    setStatusChanged(true);
    clientOfferAction(
      Number(order.id),
      chosenStatus as OrderStatus.REJECTED | OrderStatus.ORDER
    )
      .then((r) => {
        setOrder({ ...order, status: chosenStatus });
        setStatusMsg(null!);
        setShouldShowChoiceModal(false);
      })
      .catch((r) => {
        setStatusChanged(false);
        setStatusMsg(t("Error..."));
      });
  };

  const createActionConfirmModal = (
    chosenStatus: OrderStatus.REJECTED | OrderStatus.ORDER
  ) => {
    setChoiceActionModalData({
      ...choiceActionModalData,
      chosenStatus: chosenStatus,
      confirmText:
        chosenStatus === OrderStatus.REJECTED ? t("Reject") : t("Accept"),
      title:
        chosenStatus === OrderStatus.REJECTED
          ? t("Reject offer")
          : t("Accept offer"),
    });
    setShouldShowChoiceModal(true);
  };

  const uploadOrderPdf = (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file === null) {
      return;
    }

    let data = new FormData();
    data.append(`files`, file);
    uploadFile(data, Number(id))
      .then((r) => {
        var newOrder = order;
        if (newOrder.fileInfos && newOrder.fileInfos !== null) {
          newOrder.fileInfos?.push(r.data)
        }
        else {
          newOrder.fileInfos = [r.data]
        }
        setOrder({ ...newOrder })
      })
      .catch((e) => {
        alert("error - file not uploaded, please contact developers");
      });
  };

  const openFileUploadWizard = () => {
    if (fileUploadRef.current) fileUploadRef.current.click();
  };

  const deleteOfferFile = (file: IFileInfo) => {
    setFileForDelete(file);
    setShowDeletePdfModal(true);
  };

  return {
    order,
    isUserAdmin,
    statusChanged,
    statusMsg,
    selectOptions,
    selectedOption,
    showDeletePdfModal,
    deleteModal,
    fileUploadRef,
    getDefaultTextForSelectOption,
    handleStatusChange,
    submitNewStatus,
    uploadOrderPdf,
    openFileUploadWizard,
    deleteOfferFile,
    orderCategory,
    clientSelectOptions,
    statusSelectionChanged,
    choiceActionModal,
    shouldShowChoiceModal,
    createActionConfirmModal,
  };
};

export default InitOrderLogic;
